import {
    AdvoxSlider as AdvoxStrigoB2BAdvoxSlider,
    NextArrow,
    PrevArrow,
} from 'AdvoxStrigoB2BComponent/AdvoxSlider/AdvoxSlider.component';

import './AdvoxSlider.mergo.style';

export { NextArrow, PrevArrow };

/** @namespace AdvoxMergob2b/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends AdvoxStrigoB2BAdvoxSlider {
    // TODO implement logic
}

export default AdvoxSlider;
