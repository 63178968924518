import { OrderQuery as AdvoxBasicOrderQuery } from 'AdvoxBasicQuery/Order.query';
import { Field } from 'Util/Query';
/** @namespace AdvoxStrigob2b/Query/Order/Query/OrderQuery */
export class OrderQuery extends AdvoxBasicOrderQuery {
    getContractorOrderListQuery(options) {
        const { filter, currentPage, pageSize, sort } = options;

        return new Field('getOrderListForCustomerStructure')
            .addArgument('filter', 'OrderFilterInput', filter)
            .addArgument('sort', 'OrderSortInput', sort)
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addFieldList(this._getContractorOrderListFields(options));
    }

    _getContractorOrderListFields(options) {
        return [this._getContractorOrderItemsField(options), this._getContractorOrderPageInfoFields()];
    }

    _getContractorOrderItemsField(options) {
        return new Field('items').addFieldList(this._getContractorOrderItemsFields(options));
    }

    _getContractorOrderPageInfoFields() {
        return new Field('page_info').addFieldList(['current_page', 'page_size', 'total_pages']);
    }

    _getContractorOrderItemsFields(options) {
        const { isOrderList = false } = options;

        const fields = [
            'id',
            'increment_id',
            'created_at',
            'order_number',
            'status',
            'invoice_numbers',
            'ci_numbers',
            'payment_link',
            this._getContractorOrderItemsTotalField(),
        ];

        if (!isOrderList) {
            fields.push(
                'shipping_method',
                this._getContractorOrderItemsInvoicesDataField(),
                this._getContractorOrderItemsInvoiceField(),
                this._getContractorOrderItemsShipmentField(),
                this._getContractorOrderItemsShippingAddressField(),
                this._getContractorOrderItemsBillingAddressField(),
                this._getContractorOrderItemsProductsField(),
                this._getContractorOrderItemsPaymentMethodsField()
            );
        }

        return fields;
    }

    _getContractorOrderItemsInvoiceField() {
        return new Field('invoices').addFieldList(['number']);
    }

    _getContractorOrderItemsInvoicesDataField() {
        return new Field('documents').addField(new Field('items').addFieldList(['file', 'type', 'number']));
    }

    _getContractorOrderItemsShipmentField() {
        return new Field('shipments').addFieldList(['number']);
    }

    _getContractorOrderItemsPaymentMethodsField() {
        return new Field('payment_methods').addFieldList(['name']);
    }

    _getContractorOrderItemsShippingAddressField() {
        return new Field('shipping_address').addFieldList(this._getContractorOrderAddressFields());
    }

    _getContractorOrderItemsBillingAddressField() {
        return new Field('billing_address').addFieldList(this._getContractorOrderAddressFields());
    }

    _getContractorOrderAddressFields() {
        return [
            'city',
            'company',
            'country_code',
            'fax',
            'postcode',
            'prefix',
            'region',
            'region_id',
            'street',
            'suffix',
            'telephone',
            'vat_id',
        ];
    }

    _getContractorOrderItemsProductsField() {
        return new Field('items').addFieldList(this._getContractorOrderItemsProductsFields());
    }

    _getContractorOrderItemsProductsFields() {
        return [
            'id',
            'quantity_ordered',
            'product_name',
            'product_sku',
            'image_url',
            this._getContractorOrderItemsProductSalePriceField(),
        ];
    }

    _getContractorOrderItemsProductSalePriceField() {
        return new Field('product_sale_price').addFieldList(this._getMoneyFields());
    }

    _getContractorOrderItemsTotalField() {
        return new Field('total').addFieldList([
            this._getContractorOrderItemsGrandTotalField(),
            this._getContractorOrderItemsSubTotalField(),
            this._getContractorOrderItemsTotalShippingField()
        ]);
    }

    _getContractorOrderItemsGrandTotalField() {
        return new Field('grand_total').addFieldList(this._getMoneyFields());
    }

    _getContractorOrderItemsSubTotalField() {
        return new Field('subtotal').addFieldList(this._getMoneyFields());
    }

    _getContractorOrderItemsTotalShippingField() {
        return new Field('total_shipping').addFieldList(this._getMoneyFields());
    }


    _getMoneyFields() {
        return ['currency', 'value'];
    }

    getReorderItemsMutation(orderNumber) {
        const mutation = new Field('reorderItems');
        mutation.addArgument('orderNumber', 'String!', orderNumber);
        mutation.addField(this._getReorderItemsMutationField());

        return mutation;
    }

    _getReorderItemsMutationField() {
        return new Field('cart').addFieldList(['id']);
    }

    _getContractorOrderStatuses() {
        return new Field('getOrderStatuses').addFieldList(this._getContractorOrderStatusesFields());
    }

    _getContractorOrderStatusesFields() {
        return ['label', 'value'];
    }
}

export default new OrderQuery();
