import { Field as AdvoxBasicField } from 'AdvoxBasicComponent/Field/Field.component';
import FieldInput from 'Component/FieldInput';
import AddIcon from 'SourceComponent/AddIcon';
import MinusIcon from 'SourceComponent/MinusIcon';

import { OperationType } from './Field.config';

import './Field.extend.style';

/** @namespace AdvoxStrigob2b/Component/Field/Component/Field */
export class Field extends AdvoxBasicField {
    renderLabelForFile(id, filename = '', multiple = false) {
        const { hiddenFilename, onRemoveAttechment } = this.props;

        if (hiddenFilename) {
            return null;
        }

        if (multiple && filename) {
            const splitNames = filename.split('//');
            splitNames.shift();

            return (
                <label htmlFor={id}>
                    {splitNames.map((name) => (
                        <p>{name}</p>
                    ))}
                    <button block="Field" elem="ResetButton" type="reset" onClick={onRemoveAttechment}>
                        {__('Remove attachment')}
                    </button>
                </label>
            );
        }

        if (filename) {
            return (
                <label htmlFor={id}>
                    <p>{filename}</p>
                </label>
            );
        }
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            handleCalculateValue,
            numberWithoutButtons,
            noMinMax,
            bulkPackaging,
        } = this.props;

        if (numberWithoutButtons) {
            return (
                <FieldInput
                    {...this.props}
                    block="Input"
                    mods={{ type: 'number_withoutButtons' }}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={onKeyEnterDown}
                    aria-label={__('Value')}
                    max={noMinMax ? Infinity : max}
                />
            );
        }

        return (
            <>
                <FieldInput
                    {...this.props}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e) => handleChange(Number(e.target.value))}
                    onKeyDown={onKeyEnterDown}
                    step={bulkPackaging > 1 ? bulkPackaging : null}
                    aria-label={__('Value')}
                />
                <button
                    disabled={+value === max}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleChange(handleCalculateValue(OperationType.ADD))}
                    aria-label={__('Add')}
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                    disabled={+value <= min}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleChange(handleCalculateValue(OperationType.SUBTRACT))}
                    aria-label={__('Subtract')}
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }

    renderFile() {
        const { filename, id, onChange, fileExtensions, multiple } = this.props;

        return (
            <>
                <FieldInput {...this.props} type="file" onChange={onChange} />
                {this.renderLabelForFile(id, filename, multiple)}
                {fileExtensions && (
                    <p>
                        {__('Compatible file extensions to upload: ')}
                        <b>{fileExtensions}</b>
                    </p>
                )}
            </>
        );
    }
}

export default Field;
