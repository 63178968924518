import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/ExpandableContent/ExpandableContent.container';

import ExpandableContent from './ExpandableContent.component';

/** @namespace AdvoxBasic/Component/ExpandableContent/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace AdvoxBasic/Component/ExpandableContent/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContent);
