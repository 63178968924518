import Consents from 'Component/Consents';
import { CONSENTS_NEWSLETTER, CONSENTS_REGISTER } from 'Component/Consents/Consents.config';
import Field from 'Component/Field';
import Form from 'Component/Form';
import { MyAccountCreateAccount as SourceMyAccountCreateAccount } from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import history from 'Util/History';

import './MyAccountCreateAccount.override.style';

/** @namespace AdvoxBasic/Component/MyAccountCreateAccount/Component/MyAccountCreateAccount */
export class MyAccountCreateAccount extends SourceMyAccountCreateAccount {
    renderCreateAccountForm() {
        const { onCreateAccountAttempt, onCreateAccountSuccess } = this.props;

        return (
            <Form
                key="create-account"
                onSubmit={onCreateAccountAttempt}
                onSubmitSuccess={onCreateAccountSuccess}
                onSubmitError={onCreateAccountAttempt}
            >
                {this.renderCreateAccountPersonalInfoFields()}
                {this.renderNewsletterConsents()}
                {this.renderCreateAccountSignUpInfoFields()}
                {this.renderRegisterContents()}
                {this.renderSubmitButton()}
            </Form>
        );
    }

    renderCreateAccountPersonalInfoFields() {
        const {
            location: { state: { firstName = '', lastName = '' } = {} },
        } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                    type="text"
                    label={__('First Name')}
                    id="firstname"
                    name="firstname"
                    placeholder={__('Your first name')}
                    value={firstName}
                    autoComplete="given-name"
                    validation={['notEmpty']}
                />
                <Field
                    type="text"
                    label={__('Last Name')}
                    id="lastname"
                    name="lastname"
                    placeholder={__('Your last name')}
                    value={lastName}
                    autoComplete="family-name"
                    validation={['notEmpty']}
                />
            </fieldset>
        );
    }

    renderNewsletterConsents() {
        const { newsletterActive, consentsNewsletterValidate, consentsNewsletterRef } = this.props;

        if (!newsletterActive) {
            return null;
        }

        return (
            <Consents
                type={CONSENTS_NEWSLETTER}
                currentPlace="account-create"
                validateConsents={consentsNewsletterValidate}
                ref={consentsNewsletterRef}
                showMoreLess
            />
        );
    }

    renderRegisterContents() {
        const { consentsRegisterRef, consentsRegisterValidate } = this.props;

        return (
            <Consents
                type={CONSENTS_REGISTER}
                validateConsents={consentsRegisterValidate}
                ref={consentsRegisterRef}
                showMoreLess
            />
        );
    }

    renderCreateAccountSignUpInfoFields() {
        const {
            location: { state: { email = '' } = {} },
        } = history;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                    type="email"
                    label={__('Your email address')}
                    id="email"
                    name="email"
                    placeholder={__('Your email address')}
                    value={email}
                    autoComplete="email"
                    validation={['notEmpty', 'email']}
                />
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                        type="password"
                        label={__('Password')}
                        id="password"
                        name="password"
                        placeholder={__('Enter your password')}
                        autoComplete="new-password"
                        validation={['notEmpty', 'password']}
                    />
                    <Field
                        type="password"
                        label={__('Confirm password')}
                        id="confirm_password"
                        name="confirm_password"
                        placeholder={__('Retype your password')}
                        autoComplete="new-password"
                        validation={['notEmpty', 'password', 'password_match']}
                    />
                </div>
            </fieldset>
        );
    }
}

export default MyAccountCreateAccount;
