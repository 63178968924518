import PropTypes from 'prop-types';

import { FieldInputContainer as AdvoxBasicFieldInputContainer } from 'AdvoxBasicComponent/FieldInput/FieldInput.container';

/** @namespace AdvoxStrigob2b/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends AdvoxBasicFieldInputContainer {
    static propTypes = {
        ...AdvoxBasicFieldInputContainer.propTypes,
        multiple: PropTypes.bool,
    };

    static defaultProps = {
        ...AdvoxBasicFieldInputContainer.defaultProps,
        multiple: false,
    };

    containerProps() {
        const { multiple } = this.props;

        return {
            ...super.containerProps(),
            multiple,
        };
    }
}

export default FieldInputContainer;
