import { MyAccountTabListItem as AdvoxBasicMyAccountTabListItem } from 'AdvoxBasicComponent/MyAccountTabListItem/MyAccountTabListItem.component';
import Icon from 'Component/Icon';

import './MyAccountTabListItem.extend.style';

/** @namespace AdvoxStrigob2b/Component/MyAccountTabListItem/Component/MyAccountTabListItem */
export class MyAccountTabListItem extends AdvoxBasicMyAccountTabListItem {
    render() {
        const {
            tabEntry: [, { name, iconName }],
            isActive,
        } = this.props;

        return (
            <li block="MyAccountTabListItem" mods={{ isActive }}>
                {iconName && <Icon name={iconName} />}
                <button block="MyAccountTabListItem" elem="Button" onClick={this.changeActiveTab} role="link">
                    {name}
                </button>
            </li>
        );
    }
}

export default MyAccountTabListItem;
