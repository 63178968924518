import { ProductReviewRating as AdvoxBasicProductReviewRating } from 'AdvoxBasicComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductReviewRating/Component/ProductReviewRating */
export class ProductReviewRating extends AdvoxBasicProductReviewRating {
    // TODO implement logic
}

export default ProductReviewRating;
