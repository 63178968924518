import { AddToCart as AdvoxBasicAddToCart } from 'AdvoxBasicComponent/AddToCart/AddToCart.component';

import './AddToCart.extend.style';

/** @namespace AdvoxStrigob2b/Component/AddToCart/Component/AddToCart */
export class AddToCart extends AdvoxBasicAddToCart {
    // TODO implement logic
}

export default AddToCart;
