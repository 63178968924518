import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchItemContainer as AdvoxBasicSearchItemContainer,
} from 'AdvoxBasicComponent/SearchItem/SearchItem.container';
import { arePricesHidden } from 'Util/Price';

export { mapDispatchToProps };

/** @namespace AdvoxStrigob2b/Component/SearchItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    arePricesHidden: arePricesHidden(state),
});

/** @namespace AdvoxStrigob2b/Component/SearchItem/Container/SearchItemContainer */
export class SearchItemContainer extends AdvoxBasicSearchItemContainer {
    containerProps() {
        const { arePricesHidden } = this.props;

        return {
            ...super.containerProps(),
            arePricesHidden,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
