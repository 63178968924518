import Slick from 'react-slick';

import { AdvoxSlider as AdvoxBasicAdvoxSlider } from 'AdvoxBasicComponent/AdvoxSlider/AdvoxSlider.component';
import Icon from 'Component/Icon';

import './AdvoxSlider.extend.style';

/** @namespace AdvoxStrigob2b/Component/AdvoxSlider/Component/NextArrow */
export const NextArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="small_arrow_right" />
    </div>
);

/** @namespace AdvoxStrigob2b/Component/AdvoxSlider/Component/PrevArrow */
export const PrevArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style }} onClick={onClick} aria-hidden>
        <Icon name="small_arrow_left" />
    </div>
);

/** @namespace AdvoxStrigob2b/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends AdvoxBasicAdvoxSlider {
    render() {
        // eslint-disable next-line
        const { settings, variant, heading, headingSide, children } = this.props;

        settings.nextArrow = <NextArrow />;
        settings.prevArrow = <PrevArrow />;

        // prevent infinite issue when we don't have enough slides
        // (slick would copy and collapse them to next row)
        if (Object.values(children).length < settings.slidesToShow) {
            settings.infinite = false;
        }

        return (
            <div block="AdvoxSlider" mods={{ variant }}>
                {heading && (
                    <h3 block="AdvoxSlider" elem="Heading" mods={{ headingSide }}>
                        {heading}
                    </h3>
                )}
                <Slick {...settings}>{children}</Slick>
            </div>
        );
    }
}

export default AdvoxSlider;
