import Check from 'permissions';

import { ProductPrice as AdvoxBasicProductPrice } from 'AdvoxBasicComponent/ProductPrice/ProductPrice.component';
import { formatPrice, roundPrice } from 'Util/Price';
import { PRICE_LABEL_MAP } from 'Util/Price/Price.config';
import { B2B_THEMES } from 'Util/Theme';

import './ProductPrice.override.style';

/** @namespace AdvoxStrigob2b/Component/ProductPrice/Component/ProductPrice */
export class ProductPrice extends AdvoxBasicProductPrice {
    renderOldSubPrice() {
        const { discountPercentage, isSchemaRequired, variantsCount, price_tiers, label, priceCurrency } = this.props;
        const {
            price: {
                minimum_price: { regular_price_excl_tax: { value: regularPriceExclTaxValue = 0 } = {} } = {},
            } = {},
        } = this.props;
        const roundedRegularPriceExcTax = formatPrice(roundPrice(regularPriceExclTaxValue), priceCurrency);

        if (discountPercentage === 0 || roundedRegularPriceExcTax === 0 || price_tiers.length || label) {
            return null;
        }

        return (
            <del
                block="ProductPrice"
                elem="HighPrice"
                aria-label={__('Old product price')}
                itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
            >
                {roundedRegularPriceExcTax}
            </del>
        );
    }

    // netto price
    renderSubPrice() {
        const { formattedSubPrice } = this.props;

        if (!formattedSubPrice) {
            return null;
        }

        return (
            <span aria-label={__('Current product price excl. tax')} block="ProductPrice" elem="SubPrice">
                <Check themes={B2B_THEMES}>
                    <span block="ProductPrice" elem="PriceTypeLabel">
                        {__('your excl. tax: ')}
                    </span>
                </Check>
                <span>{formattedSubPrice}</span>
            </span>
        );
    }

    // brutto price
    renderCurrentPrice() {
        const { discountPercentage, formattedFinalPrice, label } = this.props;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        const isTierPriceLabel = label === PRICE_LABEL_MAP.TIER_PRICES;

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <Check themes={B2B_THEMES}>
                    {!isTierPriceLabel && (
                        <span block="ProductPrice" elem="PriceTypeLabel">
                            {__('your incl. tax: ')}
                        </span>
                    )}
                </Check>
                <span itemProp={itemProp} content={content} block="ProductPrice" elem="PriceValue">
                    {formattedFinalPrice}
                </span>
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: { minimum_price: { final_price, regular_price } = {} } = {},
            discountPercentage,
            price_tiers,
            formattedFinalPrice,
            mix,
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <div
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0 && price_tiers.length === 0 }}
                mix={mix}
                aria-label={`Product price: ${formattedFinalPrice}`}
            >
                <div>
                    {this.renderSubPrice()}
                    {this.renderOldSubPrice()}
                </div>
                <div>
                    {this.renderCurrentPrice()}
                    {this.renderOldPrice()}
                </div>
                {this.renderSchema()}
            </div>
        );
    }
}

export default ProductPrice;
