import Check from 'permissions';

import { MyAccountTabList as AdvoxBasicMyAccountTabList } from 'AdvoxBasicComponent/MyAccountTabList/MyAccountTabList.component';
import ExpandableContent from 'Component/ExpandableContent';
import Icon from 'Component/Icon';
import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import { CREATE_RETURN, VIEW_RETURN } from 'Type/Account';

import './MyAccountTabList.extend.style';

/** @namespace AdvoxStrigob2b/Component/MyAccountTabList/Component/MyAccountTabList */
export class MyAccountTabList extends AdvoxBasicMyAccountTabList {
    removeNestedRoutesFromTabList(item) {
        if (item) {
            return (
                item[0] !== VIEW_RETURN &&
                item[0] !== CREATE_RETURN &&
                item[0] !== 'create-contractor' &&
                item[0] !== 'edit-contractor' &&
                item[0] !== 'edit-shopping-list' &&
                item[0] !== 'create-shopping-list'
            );
        }

        return null;
    }

    renderTabListItem = (tabEntry) => {
        const { activeTab, tabMap } = this.props;
        const [key] = tabEntry;
        const { permissions = [], themes = [] } = tabMap[key];

        return (
            <Check permissions={permissions} themes={themes} key={`permission_${key}`}>
                <MyAccountTabListItem
                    key={key}
                    isActive={activeTab === key}
                    changeActiveTab={this.onTabClick}
                    tabEntry={tabEntry}
                />
            </Check>
        );
    };

    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li key="logout" block="MyAccountTabListItem">
                <Icon name="logout" />
                <button block="MyAccountTabListItem" elem="Button" onClick={handleLogout} role="link">
                    {__('Logout')}
                </button>
            </li>
        );
    }

    render() {
        const { tabMap, activeTab } = this.props;
        const { isContentExpanded } = this.state;
        const { name, iconName } = tabMap[activeTab];
        const heading = (
            <>
                <Icon name={iconName} /> {name}
            </>
        );
        const tabs = [
            ...Object.entries(tabMap).filter(this.removeNestedRoutesFromTabList).map(this.renderTabListItem),
            this.renderLogoutTab(),
        ];

        return (
            <ExpandableContent
                heading={heading}
                isContentExpanded={isContentExpanded}
                onClick={this.toggleExpandableContent}
                mix={{ block: 'MyAccountTabList' }}
                mods={{ isWithoutBorder: true }}
                isArrow
            >
                <ul>{tabs}</ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabList;
