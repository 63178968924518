import { connect } from 'react-redux';

import { CREATE_RETURN_URL, MY_ACCOUNT_URL, RETURNS_URL, VIEW_RETURN_URL } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as SourceMyAccountContainer,
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    CREATE_RETURN,
    DASHBOARD,
    MY_DOWNLOADABLE,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION,
    RETURNS,
    VIEW_RETURN,
} from 'Type/Account';
import { withReducers } from 'Util/DynamicReducer';

export { mapDispatchToProps, mapStateToProps };
export * from 'SourceRoute/MyAccount/MyAccount.container';

/** @namespace AdvoxBasic/Route/MyAccount/Container/MyAccountContainer */
export class MyAccountContainer extends SourceMyAccountContainer {
    static tabMap = {
        ...this.tabMap,
        [MY_ORDERS]: {
            url: '/my-orders',
            name: __('Orders'),
        },
        [RETURNS]: {
            url: `/${RETURNS_URL}`,
            name: __('Returns'),
        },
        [CREATE_RETURN]: {
            url: `/${CREATE_RETURN_URL}`,
            name: __('Create RMA'),
        },
        [VIEW_RETURN]: {
            url: `/${VIEW_RETURN_URL}/:id`,
            name: __('Return'),
        },
    };

    static isTabEnabled(props, tabName) {
        const { isWishlistEnabled, newsletterActive } = props;

        switch (tabName) {
            case MY_WISHLIST:
                return isWishlistEnabled;
            case NEWSLETTER_SUBSCRIPTION:
                return newsletterActive;
            case MY_DOWNLOADABLE:
                return false;
            default:
                return true;
        }
    }

    // needed to override these methods to override tabMap obj
    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    tabsFilterEnabled() {
        return Object.fromEntries(
            Object.entries(MyAccountContainer.tabMap).filter(([k]) => MyAccountContainer.isTabEnabled(this.props, k))
        );
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const { activeTab } = this.state;
        const { url, name } = MyAccountContainer.tabMap[activeTab];

        updateBreadcrumbs([
            { url: `${MY_ACCOUNT_URL}${url}`, name },
            { name: __('My Account'), url: `${MY_ACCOUNT_URL}/${DASHBOARD}` },
        ]);
    }
}

export default withReducers({
    OrderReducer,
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer));
