import { PureComponent } from 'react';

import RmaList from './RmaList.component';

/** @namespace AdvoxBasic/Component/RmaList/Container/RmaListContainer */
export class RmaListContainer extends PureComponent {
    containerFunctions = {
        getRmaStatusInfo: this.getRmaStatusInfo.bind(this),
    };

    containerProps = () => {
        const { customerRma, isLoading } = this.props;

        return { customerRma, isLoading };
    };

    getRmaStatusInfo(id) {
        const statuses = {
            1: __('Pending'),
            2: __('Processing'),
            3: __('Rejected'),
            4: __('Completed'),
            5: __('Canceled'),
        };

        return statuses[id] ? statuses[id] : null;
    }

    render() {
        return <RmaList {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default RmaListContainer;
