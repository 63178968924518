import { Suspense } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountContainer as AdvoxBasicMyAccountContainer,
} from 'AdvoxBasicRoute/MyAccount/MyAccount.container';
import Loader from 'Component/Loader/Loader.component';
import { CREATE_RETURN_URL, MY_ACCOUNT_URL, RETURNS_URL, VIEW_RETURN_URL } from 'Route/MyAccount/MyAccount.config';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    ADDRESS_BOOK,
    ASSIGNED_TO_ME_ORDER_REQUESTS,
    COMPANY_STRUCTURE,
    CONTRACTORS_LIST,
    CREATE_CONTRACTOR,
    CREATE_RETURN,
    CREATE_SHOPPING_LIST,
    DASHBOARD,
    EDIT_CONTRACTOR,
    EDIT_SHOPPING_LIST,
    MY_ORDERS,
    NEWSLETTER_SUBSCRIPTION,
    permissions,
    PLACED_ORDER_REQUESTS,
    RETURNS,
    SHOPPING_LISTS,
    VIEW_RETURN,
    WHOLESALERS,
} from 'Type/Account';
import { withReducers } from 'Util/DynamicReducer';
import { B2B_THEMES } from 'Util/Theme';
import { appendWithStoreCode } from 'Util/Url';

import MyAccount from './MyAccount.component';

export { mapDispatchToProps };

/** @namespace AdvoxStrigob2b/Route/MyAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
});

/** @namespace AdvoxStrigob2b/Route/MyAccount/Container/MyAccountContainer */
export class MyAccountContainer extends AdvoxBasicMyAccountContainer {
    static tabMap = {
        [DASHBOARD]: {
            url: '/dashboard',
            name: __('My account'),
            iconName: 'dashboard',
        },
        [ADDRESS_BOOK]: {
            url: '/address-book',
            name: __('Account data'),
            iconName: 'edit',
        },
        [COMPANY_STRUCTURE]: {
            url: '/company-structure',
            name: __('Company structure'),
            themes: B2B_THEMES,
            permissions: [permissions.SHOW_STRUCTURES],
            iconName: 'building',
        },
        [SHOPPING_LISTS]: {
            url: '/shopping-lists',
            name: __('Shopping lists'),
            iconName: 'shopping_list',
            themes: B2B_THEMES,
        },
        [EDIT_SHOPPING_LIST]: {
            url: `/edit-shopping-list/:id`,
            name: __('Edit shopping lists'),
            permissions: permissions.SHOW_USER_SHOPPING_LIST_ITEMS,
            themes: B2B_THEMES,
        },
        [PLACED_ORDER_REQUESTS]: {
            url: '/placed-order-requests',
            name: __('Placed order requests'),
            iconName: 'order_request',
            themes: B2B_THEMES,
        },
        [ASSIGNED_TO_ME_ORDER_REQUESTS]: {
            url: '/assigned-to-me-order-requests',
            name: __('Assigned to me order requests'),
            iconName: 'order_request',
            themes: B2B_THEMES,
        },
        [MY_ORDERS]: {
            url: '/my-orders',
            name: __('Orders'),
            permissions: permissions.SHOW_STRUCTURE_ORDERS,
            iconName: 'orders',
            themes: B2B_THEMES,
        },
        [NEWSLETTER_SUBSCRIPTION]: {
            url: '/newsletter-subscription',
            name: __('Newsletter Subscription'),
            iconName: 'envelop',
        },
        [CONTRACTORS_LIST]: {
            url: '/contractors',
            name: __('Users'),
            iconName: 'suitcase',
            themes: B2B_THEMES,
            permissions: permissions.SHOW_USERS,
        },
        [CREATE_CONTRACTOR]: {
            url: `/create-contractor`,
            name: __('Create new user account'),
            themes: B2B_THEMES,
        },
        [CREATE_SHOPPING_LIST]: {
            url: `/create-shopping-list`,
            name: __('Add a new shopping list'),
            permissions: permissions.SHOW_USER_SHOPPING_LIST,
            themes: B2B_THEMES,
        },
        [EDIT_CONTRACTOR]: {
            url: `/edit-contractor/:id`,
            name: __('Edit user account'),
            themes: B2B_THEMES,
        },
        [RETURNS]: {
            url: `/${RETURNS_URL}`,
            name: __('Returns'),
            iconName: 'back',
        },
        [CREATE_RETURN]: {
            url: `/${CREATE_RETURN_URL}`,
            name: __('Create RMA'),
        },
        [VIEW_RETURN]: {
            url: `/${VIEW_RETURN_URL}/:id`,
            name: __('Return'),
        },
        [WHOLESALERS]: {
            url: '/my-wholesalers',
            name: __('My wholesalers'),
            iconName: 'wholesalers',
            themes: B2B_THEMES,
        },
    };

    tabsFilterEnabled() {
        return Object.fromEntries(
            Object.entries(MyAccountContainer.tabMap).filter(([k]) => MyAccountContainer.isTabEnabled(this.props, k))
        );
    }

    // needed to override these methods to override tabMap obj
    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const { activeTab } = this.state;
        const { url, name, label } = MyAccountContainer.tabMap[activeTab];

        updateBreadcrumbs([
            { url: `${MY_ACCOUNT_URL}${url}`, name: label ?? name },
            { name: __('My Account'), url: `${MY_ACCOUNT_URL}/${DASHBOARD}` },
        ]);
    }

    changeActiveTab(activeTab) {
        const { history } = this.props;
        const {
            [activeTab]: { url },
        } = this.tabsFilterEnabled(MyAccountContainer.tabMap);

        history.push(appendWithStoreCode(`${MY_ACCOUNT_URL}${url}`));
        this.changeHeaderState(activeTab);
    }

    containerProps() {
        const { customerPermissions } = this.props;

        return {
            ...super.containerProps(),
            customerPermissions,
        };
    }

    render() {
        return (
            <Suspense fallback={<Loader />}>
                <MyAccount
                    {...this.containerProps()}
                    {...this.containerFunctions}
                    tabMap={this.tabsFilterEnabled(MyAccountContainer.tabMap)}
                />
            </Suspense>
        );
    }
}

export default withReducers({
    OrderReducer,
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer));
