import { connect } from 'react-redux';

import { CREATE_RETURN_URL, MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import history from 'Util/History';
import DataContainer from 'Util/Request/DataContainer';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountReturns from './MyAccountReturns.component';

export const RmaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Rma/Rma.dispatcher'
);

/** @namespace AdvoxBasic/Component/MyAccountReturns/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customerRma: state.RmaReducer.rmaData,
    rmaDataLoading: state.RmaReducer.rmaDataLoading,
});

/** @namespace AdvoxBasic/Component/MyAccountReturns/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getRmaData: () => RmaDispatcher.then(({ default: dispatcher }) => dispatcher.getRmaData(dispatch)),
});

/** @namespace AdvoxBasic/Component/MyAccountReturns/Container/MyAccountReturnsContainer */
export class MyAccountReturnsContainer extends DataContainer {
    state = {
        isLoading: false,
    };

    containerProps = () => {
        const { customerRma = [] } = this.props;
        const { isLoading } = this.state;

        return { customerRma, isLoading };
    };

    containerFunctions = {
        goToCreateRMAView: this.goToCreateRMAView.bind(this),
    };

    componentDidMount() {
        this.getRma();
    }

    componentDidUpdate(prevProps) {
        const { rmaDataLoading } = this.props;

        if (prevProps.rmaDataLoading !== rmaDataLoading) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isLoading: false,
            });
        }
    }

    getRma() {
        this.setState({
            isLoading: true,
        });

        const { getRmaData } = this.props;
        getRmaData();
    }

    goToCreateRMAView() {
        history.push(appendWithStoreCode(`${MY_ACCOUNT_URL}/${CREATE_RETURN_URL}`));
    }

    render() {
        return <MyAccountReturns {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountReturnsContainer);
