import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ShowMoreText from 'react-show-more-text';

/** @namespace AdvoxBasic/Component/ShowMoreLessText/Component/ShowMoreLessText */
export class ShowMoreLessText extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        lines: PropTypes.number,
        expanded: PropTypes.bool,
        contentClass: PropTypes.string,
        anchorClass: PropTypes.string,
        moreText: PropTypes.string,
        lessText: PropTypes.string,
        isExpanded: PropTypes.bool
    };

    static defaultProps = {
        lines: 2,
        expanded: false,
        moreText: __('expand'),
        lessText: __('collapse'),
        contentClass: '',
        anchorClass: '',
    };

    render() {
        const { children, lines, expanded, contentClass, anchorClass, moreText, lessText, isExpanded, toggleExpanded  } = this.props;
        return (
            <ShowMoreText
                lines={lines}
                more={moreText}
                less={lessText}
                expanded={expanded}
                className={`ShowMoreLessText ${contentClass}`}
                onClick={toggleExpanded}
                anchorClass={
                    `ShowMoreLessText-ToggleLink ${isExpanded ? 'ShowMoreLessText-ToggleLink_isExpanded' : ''} ${anchorClass}`
                }
            >
                {children}
            </ShowMoreText>
        );
    }
}

export default ShowMoreLessText;
