import { ProductCompareButton as AdvoxStrigoB2BProductCompareButton } from 'AdvoxStrigoB2BComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.mergo.style';

/** @namespace AdvoxMergob2b/Component/ProductCompareButton/Component/ProductCompareButton */
export class ProductCompareButton extends AdvoxStrigoB2BProductCompareButton {
    // TODO implement logic
}

export default ProductCompareButton;
