import { ProductAttributeValue as AdvoxStrigoB2BProductAttributeValue } from 'AdvoxStrigoB2BComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.mergo.style';

/** @namespace AdvoxMergob2b/Component/ProductAttributeValue/Component/ProductAttributeValue */
export class ProductAttributeValue extends AdvoxStrigoB2BProductAttributeValue {
    // TODO implement logic
}

export default ProductAttributeValue;
