import { IconContainer as AdvoxBasicIconContainer } from 'AdvoxBasicComponent/Icon/Icon.container';

/** @namespace AdvoxStrigob2b/Component/Icon/Container/IconContainer */
export class IconContainer extends AdvoxBasicIconContainer {
    static defaultProps = {
        fill: '#004B93',
        width: '25',
        height: '25',
        cursor: 'pointer',
    };
}

export default IconContainer;
