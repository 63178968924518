import { withRouter } from 'react-router-dom';

import { ProductsSlider as AdvoxBasicProductsSlider } from 'AdvoxBasicComponent/ProductsSlider/ProductsSlider.component';

import './ProductsSlider.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductsSlider/Component/ProductsSlider */
export class ProductsSlider extends AdvoxBasicProductsSlider {
    // TODO implement logic
}

export default withRouter(ProductsSlider);
