import PropTypes from 'prop-types';
import { createRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccountContainer,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

export { mapStateToProps, mapDispatchToProps };
export const MyAccountCreateAccount = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
        './MyAccountCreateAccount.component'
    )
);

/** @namespace AdvoxBasic/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccountContainer {
    static propTypes = {
        ...super.propTypes,
        onCreateAccountSuccess: PropTypes.func,
        vatNumberValidation: PropTypes.array,
    };

    state = {
        consentsNewsletterValidate: false,
        consentsRegisterValidate: false,
    };

    consentsNewsletterRef = createRef();

    consentsRegisterRef = createRef();

    containerFunctions = {
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
    };

    validNewsletterConsents(allRequiredConsetsSelected) {
        this.setState((prevState) => ({
            ...prevState,
            consentsNewsletterValidate: !allRequiredConsetsSelected,
        }));
    }

    validRegisterConsents(allRequiredConsetsSelected) {
        this.setState((prevState) => ({
            ...prevState,
            consentsRegisterValidate: !allRequiredConsetsSelected,
        }));
    }

    containerProps() {
        const { consentsRegisterValidate, consentsNewsletterValidate } = this.state;

        return {
            ...super.containerProps(),
            consentsRegisterValidate,
            consentsNewsletterValidate,
            consentsNewsletterRef: this.consentsNewsletterRef,
            consentsRegisterRef: this.consentsRegisterRef,
        };
    }

    async onCreateAccountSuccess(fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile,
            newsletterActive,
        } = this.props;

        const { password, email, firstname, lastname, taxvat } = fields;

        const consentsNewsletter = newsletterActive ? this.consentsNewsletterRef.current.state : {};
        const consentsRegister = this.consentsRegisterRef.current.state;

        const {
            selectedConsentIds: selectedNewsletterConsentIds = [],
            areAllRequiredContentsAccepted: areAllRequiredContentsNewsletterAccepted = true,
        } = consentsNewsletter || {};
        const {
            selectedConsentIds: selectedRegisterConsentIds,
            areAllRequiredContentsAccepted: areAllRequiredContentsRegisterAccepted = true,
        } = consentsRegister || {};

        const consentIds = [...selectedNewsletterConsentIds, ...selectedRegisterConsentIds];

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed: (selectedNewsletterConsentIds ?? []).length > 0,
                taxvat,
            },
            password,
            consentIds,
        };

        this.validNewsletterConsents(areAllRequiredContentsNewsletterAccepted);
        this.validRegisterConsents(areAllRequiredContentsRegisterAccepted);

        if (isLoading || !areAllRequiredContentsNewsletterAccepted || !areAllRequiredContentsRegisterAccepted) {
            return;
        }

        try {
            const code = await createAccount(customerData);

            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __(
                            'The email confirmation link has been sent to your email. Please confirm your account to proceed.'
                        )
                    );
                }
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return <Loader />;
        }

        return (
            <Suspense fallback={<Loader />}>
                <MyAccountCreateAccount {...this.containerProps()} {...this.containerFunctions} />
            </Suspense>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
