import Field from 'Component/Field';
import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

/** @namespace AdvoxBasic/Component/FieldForm/Component/FieldForm */
export class FieldForm extends SourceFieldForm {
    renderField = (fieldEntry) => {
        const {
            key = null,
            isSubmitted,
            id = null,
            label = null,
            name = null,
            onChange = null,
            placeholder = null,
            type = null,
            validateSeparately,
            validation = [],
            value = null,
            selectOptions = [],
            checked = null,
            ariaLabel = null,
            inputMask = '',
            maskRegExp = '',
            numberWithoutButtons = false,
            noMinMax = false,
            onBlur = null,
        } = this.getDefaultValues(fieldEntry);

        return (
            <Field
                id={id}
                key={key}
                label={label}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                validateSeparately={validateSeparately}
                validation={validation}
                value={value}
                isSubmitted={isSubmitted}
                selectOptions={selectOptions}
                checked={checked}
                ariaLabel={ariaLabel}
                inputMask={inputMask}
                maskRegExp={maskRegExp}
                numberWithoutButtons={numberWithoutButtons}
                noMinMax={noMinMax}
                onBlur={onBlur}
            />
        );
    };
}

export default FieldForm;
