import { SwipeableDrawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icon from 'Component/Icon';

import './SwipeableTemporaryDrawer.style.scss';

/** @namespace AdvoxBasic/Component/SwipeableTemporaryDrawer/Component/SwipeableTemporaryDrawerComponent */
export class SwipeableTemporaryDrawerComponent extends PureComponent {
    static propTypes = {
        ...this.propTypes,
        isOpen: PropTypes.bool,
        variant: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
        handleToggleDrawer: PropTypes.func,
        title: PropTypes.string,
        swipeAreaWidth: PropTypes.number,
        titleIconName: PropTypes.string,
    };

    static defaultProps = {
        ...this.defaultProps,
        isOpen: false,
        variant: 'left',
        handleToggleDrawer: () => {},
        titleIconName: '',
    };

    renderSwipeableTemporaryDrawerTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return title;
    }

    render() {
        const { isOpen, variant, handleToggleDrawer, children, swipeAreaWidth, name, titleIconName } = this.props;

        return (
            <SwipeableDrawer
                anchor={variant}
                open={isOpen}
                onClose={() => handleToggleDrawer(false)}
                onOpen={() => handleToggleDrawer(true)}
                swipeAreaWidth={swipeAreaWidth}
                SlideProps={{
                    id: 'SwipeableDrawer',
                }}
            >
                <div block="SwipeableTemporaryDrawer" elem="Drawer" mods={{ variant, [name]: !!name }}>
                    <div block="SwipeableTemporaryDrawer" elem="Header">
                        <div
                            block="SwipeableTemporaryDrawer"
                            elem="CloseIcon"
                            onClick={() => handleToggleDrawer(false)}
                        />
                        <div block="SwipeableTemporaryDrawer" elem="Title">
                            {titleIconName && <Icon name={titleIconName} />}
                            {this.renderSwipeableTemporaryDrawerTitle()}
                        </div>
                    </div>
                    <div block="SwipeableTemporaryDrawer" elem="Content">
                        {children}
                    </div>
                </div>
            </SwipeableDrawer>
        );
    }
}

export default SwipeableTemporaryDrawerComponent;
