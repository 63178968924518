import parser from 'html-react-parser';
import { PureComponent } from 'react';

import './ShowMoreLessContent.style.scss';

/** @namespace AdvoxBasic/Component/ShowMoreLessContent/Component/ShowMoreLessContent */
export class ShowMoreLessContent extends PureComponent {
    renderButton() {
        const { isExpanded, handleClick } = this.props;

        const buttonTitle = isExpanded ? __('collapse') : __('expand');

        return (
            <button block="ShowMoreLessContent" elem="Button" onClick={handleClick}>
                {buttonTitle}
            </button>
        );
    }

    render() {
        const { content, isExpanded, contentLength } = this.props;

        if (!content.length) {
            return null;
        }

        const isExtendable = content.length > contentLength;

        const currentContent = isExtendable && !isExpanded ? `${content.slice(0, contentLength).trim()}...` : content;

        return (
            <div block="ShowMoreLessContent" elem="Wrapper">
                <span block="ShowMoreLessContent" elem="Content">
                    {parser(currentContent)}
                    {isExtendable && this.renderButton()}
                </span>
            </div>
        );
    }
}

export default ShowMoreLessContent;
