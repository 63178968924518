import Check from 'permissions';

import { ProductCard as AdvoxBasicProductCard } from 'AdvoxBasicComponent/ProductCard/ProductCard.component';
import AddToCart from 'Component/AddToCart';
import CustomPrice from 'Component/CustomPrice';
import Field from 'Component/Field';
import Icon from 'Component/Icon';
import Loader from 'Component/Loader';
import ProductCompareButton from 'Component/ProductCompareButton';
import ProductLabel from 'Component/ProductLabel';
import { PRODUCT_LABEL_TYPE } from 'Component/ProductLabel/ProductLabel.config';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';
import { B2B_THEMES } from 'Util/Theme';

import './ProductCard.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductCard/Component/ProductCard */
export class ProductCard extends AdvoxBasicProductCard {
    renderDiscountLabel() {
        const {
            product: { price_range, special_price = null },
        } = this.props;

        const hasSpecialPrice = !!special_price;
        const specialPriceIsTheMostFavorable = special_price <= price_range?.minimum_price?.final_price_excl_tax?.value;
        const discount = price_range?.minimum_price?.discount?.percent_off || 0;

        // Show if the special price and is the most favorable
        if (discount > 0 && hasSpecialPrice && specialPriceIsTheMostFavorable) {
            return <ProductLabel type={PRODUCT_LABEL_TYPE.SALE} text={`-${discount.toFixed()}%`} radius="left" />;
        }
    }

    renderQuantityInput() {
        const {
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            inStock,
            product: { type_id },
            arePricesHidden,
            bulkPackaging,
            onBlurQuantity,
        } = this.props;

        if (type_id === GROUPED || arePricesHidden) {
            return null;
        }

        if (!inStock) {
            return null;
        }

        return (
            <Field
                id="item_qty"
                name="item_qty"
                type="number"
                value={quantity}
                max={maxQuantity}
                min={minQuantity}
                mix={{ block: 'ProductActions', elem: 'Qty' }}
                onChange={setQuantity}
                bulkPackaging={bulkPackaging}
                onBlur={onBlurQuantity}
                noMinMax
                updateValueOnBlur
            />
        );
    }

    renderProductSKU() {
        const {
            product: { sku },
        } = this.props;

        if (!sku) {
            return null;
        }

        return (
            <p block="ProductCard" elem="Sku">
                {`${__('Product sku: ')} ${sku}`}
            </p>
        );
    }

    renderAddToShoppingList() {
        const { arePricesHidden, openShoppingListModal } = this.props;

        if (arePricesHidden) {
            return null;
        }

        return (
            <button block="ProductCard" elem="ShoppingList" onClick={openShoppingListModal}>
                <Icon name="shopping_list" fill="#000" />
                {__('Add to shopping list')}
            </button>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                        </div>
                        {this.renderRelatedProductsByAttribute()}
                        <div block="ProductCard" elem="Content">
                            {this.renderReviews()}
                            {this.renderProductSKU()}
                            {this.renderAdditionalProductDetails()}
                            {this.renderMainDetails()}
                            {this.renderProductPrice()}
                            <Check themes={B2B_THEMES}>{this.renderAddToShoppingList()}</Check>
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="Actions">
                    <div block="ProductCard" elem="AddToCart">
                        {this.renderQuantityInput()}
                        {this.renderAddToCart()}
                    </div>
                </div>
                {this.renderCustomPrice()}
            </>
        );
    }

    shouldComponentUpdate(nextProps) {
        const { product, device, productOrVariant, parameters, layout, quantity, arePricesHidden } = this.props;

        const {
            product: nextProduct,
            device: nextDevice,
            productOrVariant: nextProductOrVariant,
            parameters: nextParameters,
            layout: prevLayout,
            quantity: prevQuantity,
            arePricesHidden: prevarePricesHidden,
        } = nextProps;

        return (
            product !== nextProduct ||
            device !== nextDevice ||
            productOrVariant !== nextProductOrVariant ||
            parameters !== nextParameters ||
            layout !== prevLayout ||
            quantity !== prevQuantity ||
            arePricesHidden !== prevarePricesHidden
        );
    }

    renderProductPrice() {
        const {
            arePricesHidden,
            product: { price_range, price_tiers = [], type_id },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
        } = this.props;

        if (arePricesHidden) {
            return null;
        }

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
            case CONFIGURABLE:
                if (isConfigurableProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                break;
            case BUNDLE:
                if (isBundleProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                break;
            default:
                break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                    price={price_range}
                    price_tiers={price_tiers}
                    mix={{ block: 'ProductCard', elem: 'Price' }}
                    label={this.renderProductTypePriceBadge()}
                />
            </div>
        );
    }

    renderAddToCart() {
        const {
            product,
            product: { type_id, options = [], configurable_options: confOptions = {} },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
            quantity,
            arePricesHidden,
        } = this.props;

        if (arePricesHidden) {
            return null;
        }

        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions,
        };

        const redirectOnConfig =
            type_id === CONFIGURABLE &&
            Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button block="Button AddToCart" mods={{ layout, small: true }} onClick={showSelectOptionsNotification}>
                    {__('To cart')}
                </button>
            );
        }

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                productOptionsData={productOptionsData}
                disabled={!inStock}
                layout={layout}
            />
        );
    }

    renderProductCompareButton() {
        const {
            product: { id },
            hideCompareButton,
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return (
            <ProductCompareButton
                productId={id}
                mix={{
                    block: 'ProductCompareButton',
                    mods: { isGrey: true },
                }}
            />
        );
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                {this.renderProductCompareButton()}
            </div>
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={{ layout }}>
                    <div block="ProductCard" elem="MainInfo">
                        {this.renderProductActions()}
                        {this.renderReviews()}
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderProductSKU()}
                        <Check themes={B2B_THEMES}>{this.renderAddToShoppingList()}</Check>
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        {this.renderProductPrice()}
                        {this.renderConfigurableOptions()}
                    </div>
                    <div block="ProductCard" elem="Actions">
                        <div block="ProductCard" elem="AddToCart">
                            {this.renderQuantityInput()}
                            {this.renderAddToCart()}
                        </div>
                        {this.renderCustomPrice()}
                    </div>
                    <div block="ProductCard" elem="Labels">
                        {this.renderDiscountLabel()}
                    </div>
                    {children && (
                        <div block="ProductCard" elem="AdditionalContent">
                            {children}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
        };

        if (layout === LIST_LAYOUT) {
            return (
                <div block="ProductCard" mods={mods} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </div>
            );
        }

        return (
            <div block="ProductCard" mods={mods} mix={mix}>
                {this.renderProductActions()}
                {this.renderDiscountLabel()}
                {this.renderAdvoxLabels()}
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </div>
        );
    }

    renderCustomPrice() {
        const { product: { price_range } = {}, inStock, arePricesHidden } = this.props;

        if (!inStock || arePricesHidden || !price_range) {
            return null;
        }

        const {
            minimum_price: { custom_price_excl_tax: minCustomPrice } = {},
            maximum_price: { custom_price_incl_tax: maxCustomPrice } = {},
        } = price_range;

        if (!minCustomPrice || !minCustomPrice.value || !maxCustomPrice || !maxCustomPrice.value) {
            return null;
        }

        return <CustomPrice priceExcludingTax={minCustomPrice} priceIncludingTax={maxCustomPrice} />;
    }
}

export default ProductCard;
