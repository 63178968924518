import {
    ExpandableContent as AdvoxBasicExpandableContent,
} from 'AdvoxBasicComponent/ExpandableContent/ExpandableContent.component';
import Icon from 'Component/Icon';

import './ExpandableContent.extend.style';

/** @namespace AdvoxStrigob2b/Component/ExpandableContent/Component/ExpandableContent */
export class ExpandableContent extends AdvoxBasicExpandableContent {
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            isExpandableOnDesktop,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet && !isExpandableOnDesktop) {
            return null;
        }

        if (isArrow) {
            return <Icon name={isContentExpanded ? 'full_small_arrow_up' : 'full_small_arrow_down'} />
        }

        return this.renderTogglePlusMinus();
    }
};

export default ExpandableContent;
