import { CustomPrice as AdvoxStrigoB2BCustomPrice } from 'AdvoxStrigoB2BComponent/CustomPrice/CustomPrice.component';

import './CustomPrice.mergo.style';

/** @namespace AdvoxMergob2b/Component/CustomPrice/Component/CustomPrice */
export class CustomPrice extends AdvoxStrigoB2BCustomPrice {
    // TODO implement logic
}

export default CustomPrice;
