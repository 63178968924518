import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { Router as AdvoxBasicRouter } from 'AdvoxBasicComponent/Router/Router.component';
import { DEPT_PERMISSIONS } from 'Component/Debt/Dept.config';

import { BEFORE_ITEMS_TYPE, DEBT, PPS_FORM, SWITCH_ITEMS_TYPE } from './Router.config';

export * from 'AdvoxBasicComponent/Router/Router.component';

export const Debt = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "debt" */ 'Component/Debt'));
export const PPS = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pps" */ 'Route/PPS'));

/** @namespace AdvoxStrigob2b/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace AdvoxStrigob2b/Component/Router/Component/Router */
export class Router extends AdvoxBasicRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <Debt />,
            position: 25,
            name: DEBT,
            permissions: DEPT_PERMISSIONS,
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: <Route path={withStoreRegex('/pps')} render={(props) => <PPS {...props} />} />,
            position: 87,
            name: PPS_FORM,
        },
    ];

    getSortedItems(type) {
        return this[type]
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .filter((entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');

                    return false;
                }

                return true;
            });
    }
}

export default Router;
