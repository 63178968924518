import { CartDispatcher as AdvoxBasicCartDispatcher } from 'AdvoxBasicStore/Cart/Cart.dispatcher';
import CartQuery from 'Query/Cart.query';
import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { prepareMutation } from 'Util/Query';
import { executePost, fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

/** @namespace AdvoxStrigob2b/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends AdvoxBasicCartDispatcher {
    async addOrderItemsToCart(dispatch, orderNr) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const id = await fetchMutation(OrderQuery.getReorderItemsMutation(orderNr));

            const { cartData = {} } = await fetchQuery(CartQuery.getCartQuery(id));

            dispatch(showNotification('success', __('Products added to cart.')));
            return this._updateCartData(cartData, dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    async removeAllProductsFromCart(dispatch) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return null;
            }

            const result = await fetchMutation(CartQuery.getRemoveAllCartItemsMutation(guestQuoteId));

            if (result) {
                const { cartData = {} } = await fetchQuery(CartQuery.getCartQuery(!isSignedIn() && guestQuoteId));
                this._updateCartData(cartData, dispatch);
            }

            return null;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }

    async optimizeCart(dispatch) {
        try {
            const { is_optimized } = await executePost(prepareMutation(CartQuery.getOptimizeCartMutation()));

            if (is_optimized) {
                dispatch(showNotification('info', __('Cart has been updated')));
            }

            await this.updateInitialCartData(dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error), error));
        }
    }
}

export default new CartDispatcher();
