import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

import { STATE_LOGGED_IN, STATE_SIGN_IN } from './MyAccountOverlay.config';

export { mapDispatchToProps };

/** @namespace AdvoxBasic/Component/MyAccountOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isTablet: state.ConfigReducer.device.isTablet,
});

/** @namespace AdvoxBasic/Component/MyAccountOverlay/Container/MyAccountOverlayContainer */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    static propTypes = {
        ...super.propTypes,
        isTablet: PropTypes.bool.isRequired,
    };

    containerProps() {
        const { isTablet, onMyAccountButtonClick, name, variant, isSwipeable, isOpen } = this.props;

        return {
            ...super.containerProps(),
            isTablet,
            onMyAccountButtonClick,
            name,
            variant,
            isSwipeable,
            isOpen,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { isPasswordForgotSend, showNotification, isOverlayVisible, isMobile, isTablet } = props;

        const { isPasswordForgotSend: currentIsPasswordForgotSend, state: myAccountState } = state;

        const {
            location: { pathname, state: { isForgotPassword } = {} },
        } = history;

        const stateToBeUpdated = {};
        const customerIsSignedIn = isSignedIn();

        if (!isMobile && !isTablet) {
            if (!isOverlayVisible && !customerIsSignedIn) {
                if (pathname !== '/forgot-password' && !isForgotPassword) {
                    stateToBeUpdated.state = STATE_SIGN_IN;
                }
            } else if (!isOverlayVisible && customerIsSignedIn) {
                stateToBeUpdated.state = STATE_LOGGED_IN;
            }
        }

        if (myAccountState !== STATE_LOGGED_IN && customerIsSignedIn) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.state = STATE_LOGGED_IN;
        }

        if (myAccountState === STATE_LOGGED_IN && !customerIsSignedIn) {
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        if (isPasswordForgotSend !== currentIsPasswordForgotSend) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.isPasswordForgotSend = isPasswordForgotSend;
            // eslint-disable-next-line max-len
            showNotification(
                'success',
                __(
                    'If there is an account associated with the provided address you will receive an email with a link to reset your password.'
                )
            );
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        return Object.keys(stateToBeUpdated).length ? stateToBeUpdated : null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
