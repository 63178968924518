import {
    ShowMoreLessText as AdvoxBasicShowMoreLessText,
} from 'AdvoxBasicComponent/ShowMoreLessText/ShowMoreLessText.component';

import './ShowMoreLessText.extend.style';

/** @namespace AdvoxStrigob2b/Component/ShowMoreLessText/Component/ShowMoreLessText */
export class ShowMoreLessText extends AdvoxBasicShowMoreLessText {
    // TODO implement logic
};

export default ShowMoreLessText;
