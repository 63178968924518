import { MyAccountQuery as AdvoxBasicMyAccountQuery } from 'AdvoxBasicQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace AdvoxStrigob2b/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends AdvoxBasicMyAccountQuery {
    options = {
        disableDebt: false,
    };

    getUserPermissions() {
        return new Field('getUserPermissions').addField('list');
    }

    getUserRoles() {
        return new Field('getUserRoles').addFieldList(this._getUserRolesFields());
    }

    _getUserRolesFields() {
        return ['id', 'name'];
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            this._getAddressesField(),
        ];
    }

    getContractorForLoggedInCustomer() {
        return new Field('getContractorForLoggedInCustomer').addFieldList(
            this._getContractorForLoggedInCustomerFields()
        );
    }

    getCurrentUser() {
        return new Field('getCurrentUser').addFieldList(this._getCurrentUserFields());
    }

    getContractorBlocked(email) {
        return new Field('getBlockInfo').addArgument('email', 'String!', email).addField('is_blocked');
    }

    _getContractorForLoggedInCustomerFields() {
        const fields = [
            'id',
            'type',
            'customer_group_id',
            'is_active',
            'vat_id',
            'company',
            'email',
            'phone',
            'postcode',
            'city',
            'street',
            'country',
            'loyalty_program',
            'can_place_direct_order',
            this._getMerchantLimitField(),
        ];

        if (!this.options.disableDebt) {
            fields.push(this._getContractorDebtField());
        }

        return fields;
    }

    _getMerchantLimitField() {
        return new Field('merchant_limit').addFieldList(this._getMerchantLimitFields());
    }

    _getMerchantLimitFields() {
        return ['amount', 'remaining'];
    }

    _getContractorDebtField() {
        return new Field('debt').addFieldList(this._getContractorDebtFields());
    }

    _getContractorDebtFields() {
        return ['debt_amount'];
    }

    _getCurrentUserFields() {
        return ['id'];
    }

    getCreateContractorMutation(options) {
        const { customer, contractor } = options;

        return new Field('createContractor')
            .addArgument('customerInput', 'ContractorCustomerInput!', { ...customer })
            .addArgument('contractorInput', 'ContractorInput!', { ...contractor })
            .addField(this._getContractorField());
    }

    _getContractorField() {
        return new Field('customer').addFieldList(this._getContractorFields());
    }

    _getContractorFields() {
        return ['email'];
    }

    getDataFromNipNumber(nipNumber) {
        return new Field('getVatIdData')
            .addArgument('vat_id', 'String!', nipNumber)
            .addFieldList(this._getDataFromNipNumberFields());
    }

    _getDataFromNipNumberFields() {
        return ['name', 'city', 'post_code', 'region_id', 'street'];
    }
}

export default new MyAccountQuery();
