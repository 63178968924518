import { connect } from 'react-redux';

import { getDiscountFromLowestPriceTiers } from 'AdvoxBasicUtil/Price';
import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX } from 'Component/ProductPrice/ProductPrice.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { formatPrice, getLowestPriceTiersPrice } from 'Util/Price';

export { mapDispatchToProps, mapStateToProps };
/** @namespace AdvoxStrigob2b/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static defaultProps = {
        ...SourceProductPriceContainer.defaultProps,
        isProductPage: false,
    };

    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    final_price: { value: minimalPriceValue, currency: priceCurrency } = {},
                    final_price_excl_tax: { value: minimalPriceExclTaxValue } = {},
                } = {},
            } = {},
            price_tiers,
            displayTaxInPrice = '',
            isProductPage,
        } = this.props;

        if (price_tiers.length && !price_tiers.every(({ quantity }) => quantity === 1) && !isProductPage) {
            return getLowestPriceTiersPrice(price_tiers, priceCurrency);
        }

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return formatPrice(minimalPriceExclTaxValue, priceCurrency);
        }

        return formatPrice(minimalPriceValue, priceCurrency);
    }

    getDiscountValue() {
        const {
            price: { minimum_price: { discount: { percent_off } } = {} } = {},
            price_tiers,
            isProductPage,
        } = this.props;

        if (price_tiers.length && !price_tiers.every(({ quantity }) => quantity === 1) && !isProductPage) {
            const priceTierPercentOff = getDiscountFromLowestPriceTiers(price_tiers);

            return priceTierPercentOff;
        }

        return percent_off;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
