import { getFormattedDate, getInitialState, orderList, ORDERS } from 'SourceStore/Order/Order.reducer';
import { GET_ORDER_LIST } from 'Store/Order/Order.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { SET_ORDER_LIST_ERROR } from './Order.action';

export { ORDERS, getFormattedDate, orderList, getInitialState };

/** @namespace AdvoxStrigob2b/Store/Order/Reducer/formatOrders */
export const formatOrders = (orders) =>
    orders.reduce(
        (acc, { created_at, status, ...restOrder }) => [
            ...acc,
            {
                base_order_info: {
                    status_label: status,
                    created_at: getFormattedDate(created_at),
                    ...restOrder,
                },
            },
        ],
        []
    );

/** @namespace AdvoxStrigob2b/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (state = getInitialState(), action) => {
    const { type, orderList, status, errorMsg } = action;

    switch (type) {
        case GET_ORDER_LIST:
            const { items = [], page_info = {} } = orderList;
            const formattedOrders = formatOrders(items);

            BrowserDatabase.setItem(formattedOrders, ORDERS, ONE_MONTH_IN_SECONDS);

            return {
                ...state,
                isLoading: status,
                orderList: formattedOrders,
                orderPageInfo: page_info,
            };
        case SET_ORDER_LIST_ERROR:
            return {
                ...state,
                orderListError: errorMsg,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default OrderReducer;
