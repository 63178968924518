import domToReact from 'html-react-parser/lib/dom-to-react';

import {
    AdvoxCatalogWidget,
    DailyPromoProduct,
    Html as AdvoxBasicHtml,
    WidgetFactory,
} from 'AdvoxBasicComponent/Html/Html.component';
import AdvoxSlider from 'Component/AdvoxSlider';
import ShowMoreLessText from 'Component/ShowMoreLessText';

export { DailyPromoProduct, AdvoxCatalogWidget, WidgetFactory };

/** @namespace AdvoxStrigob2b/Component/Html/Component/Html */
export class Html extends AdvoxBasicHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: ['data-show-more'] },
            replace: this.showMore,
        },
    ];

    showMore({ attribs, children }) {
        const isShowMore = attribs['data-show-more'];
        const lessText = attribs['data-show-more-text-less'];
        const moreText = attribs['data-show-more-text-more'];

        if (isShowMore) {
            return (
                <div block="SeoText" elem="ShowMore">
                    <ShowMoreLessText lines={4} lessText={lessText} moreText={moreText}>
                        {domToReact(children, this.parserOptions)}
                    </ShowMoreLessText>
                </div>
            );
        }

        return true;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    renderSlider({ attribs, children }) {
        const attributes = this.attributesToProps(attribs);

        const settings = {
            slidesToShow: attributes['data-slidestoshow'] || 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: attributes['data-slidestoshowtablet'] || 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: attributes['data-slidestoshowmobile'] || 1,
                        dots: true,
                        arrows: true,
                    },
                },
            ],
        };

        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <AdvoxSlider {...attributes} settings={settings}>
                {domToReact(children)}
            </AdvoxSlider>
        );
    }
}

export default Html;
