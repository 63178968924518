import { ProductConfigurableAttributes as AdvoxBasicProductConfigurableAttributes } from 'AdvoxBasicComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributes */
export class ProductConfigurableAttributes extends AdvoxBasicProductConfigurableAttributes {
    // TODO implement logic
}

export default ProductConfigurableAttributes;
