import { GET_CONTRACTOR } from './Contractor.actions';

/** @namespace AdvoxStrigob2b/Store/Contractor/Reducer/getInitialState */
export const getInitialState = () => ({
    contractor: {},
});

/** @namespace AdvoxStrigob2b/Store/Contractor/Reducer/ContractorReducer */
export const ContractorReducer = (state = getInitialState(), action) => {
    const { type, contractor } = action;

    switch (type) {
        case GET_CONTRACTOR:
            return {
                contractor,
            };

        default:
            return state;
    }
};

export default ContractorReducer;
