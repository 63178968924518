import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icon from 'Component/Icon';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { isMobile } from 'Util/Mobile';

import './RmaList.style';

/** @namespace AdvoxBasic/Component/RmaList/Component/RmaList */
export class RmaList extends PureComponent {
    static propTypes = {
        customerRma: PropTypes.arrayOf(
            PropTypes.shape({
                increment_id: PropTypes.string,
                created_at: PropTypes.string,
                order_increment_id: PropTypes.string,
                status_id: PropTypes.string,
            })
        ),
    };

    getNoTimeDate(rawDate) {
        const date = new Date(rawDate.replace(/\s/, 'T'));
        const RADIX = 10;

        const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

        const day = addLeadingZero(date.getDate());
        const month = addLeadingZero(date.getMonth() + 1);

        return `${date.getFullYear()}-${month}-${day}`;
    }

    renderRMARow(rma) {
        if (!rma) {
            return null;
        }

        const { increment_id, created_at, order_increment_id, status_id } = rma;
        const { getRmaStatusInfo } = this.props;

        return (
            <tr key={increment_id}>
                <td>
                    <span block="RmaList" elem="Number">{`#${increment_id}`}</span>
                </td>
                <td>{this.getNoTimeDate(created_at)}</td>
                <td>
                    <span block="RmaList" elem="Number">
                        {`#${order_increment_id}`}
                    </span>
                </td>
                <td>
                    <span block="RmaList" elem="Status" mods={{ type: status_id }}>
                        {getRmaStatusInfo(status_id)}
                    </span>
                </td>
                <td>
                    <Link to={`/my-account/return/${increment_id}`}>
                        {__('View Return')} <Icon name="small_arrow_right" />
                    </Link>
                </td>
            </tr>
        );
    }

    renderRMAHeadingRow() {
        return (
            <tr>
                <th>{__('RMA #')}</th>
                <th>{__('Date')}</th>
                <th>{__('Order #')}</th>
                <th>{__('Status')}</th>
                <th>{__('')}</th>
            </tr>
        );
    }

    renderRMARows() {
        const { customerRma, isLoading } = this.props;

        if (!isLoading && !customerRma.length) {
            return this.renderNoRMAs();
        }

        return customerRma && customerRma.map((rma) => this.renderRMARow(rma));
    }

    renderNoRMAs() {
        return (
            <tr block="RmaList" elem="NoRMAs">
                {/* eslint-disable-next-line no-magic-numbers */}
                <td colSpan={isMobile.any() ? 3 : 4}>{__('No returns.')}</td>
            </tr>
        );
    }

    renderTable() {
        return (
            <table block="RmaList" elem="Table">
                <thead>{this.renderRMAHeadingRow()}</thead>
                <tbody>{this.renderRMARows()}</tbody>
            </table>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return <Loader isLoading={isLoading} />;
        }

        return <div block="RmaList">{this.renderTable()}</div>;
    }
}

export default RmaList;
