import { ATTRIBUTES_CODE } from 'Util/Product/Types';

export * from 'AdvoxBasicUtil/Product/Product';
/** @namespace AdvoxStrigob2b/Util/Product/getAttributeByCode */
export const getAttributeByCode = (attributes, code) => {
    const key = attributes && Object.keys(attributes).find((key) => attributes[key].attribute_code === code);

    return key ? attributes[key] : null;
};

/** @namespace AdvoxStrigob2b/Util/Product/roundQuantity */
export const roundQuantity = (value, bulkPackaging) => {
    const roundValue = Math.ceil(value / bulkPackaging);

    return roundValue * bulkPackaging;
};

/** @namespace AdvoxStrigob2b/Util/Product/getBulkPackagingValue */
export const getBulkPackagingValue = (product) => {
    const bulkPackagingAttribute =
        product[ATTRIBUTES_CODE.BULK_PACKAGING] ||
        product?.attributes?.[ATTRIBUTES_CODE.BULK_PACKAGING]?.attribute_value;

    return bulkPackagingAttribute ? parseInt(bulkPackagingAttribute, 10) : null;
};
