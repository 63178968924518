import PropTypes from 'prop-types';

import { FieldContainer as AdvoxBasicFieldContainer } from 'AdvoxBasicComponent/Field/Field.container';
import { FILE_TYPE, NUMBER_TYPE } from 'Component/Field/Field.config';

import { OperationType } from './Field.config';

export * from 'AdvoxBasicComponent/Field/Field.container';

/** @namespace AdvoxStrigob2b/Component/Field/Container/FieldContainer */
export class FieldContainer extends AdvoxBasicFieldContainer {
    static propTypes = {
        ...AdvoxBasicFieldContainer.propTypes,
        multiple: PropTypes.bool,
        accept: PropTypes.string,
        hiddenFilename: PropTypes.bool,
        handleRemoveAttechment: PropTypes.func,
        bulkPackaging: PropTypes.number,
        updateValueOnBlur: PropTypes.bool,
    };

    static defaultProps = {
        ...AdvoxBasicFieldContainer.defaultProps,
        multiple: false,
        accept: '',
        hiddenFilename: false,
        updateValueOnBlur: false,
        handleRemoveAttechment: null,
        bulkPackaging: 1,
    };

    containerFunctions = {
        ...this.containerFunctions,
        onRemoveAttechment: this.onRemoveAttechment.bind(this),
        handleCalculateValue: this.handleCalculateValue.bind(this),
    };

    containerProps() {
        const { multiple, accept, hiddenFilename, bulkPackaging } = this.props;

        return {
            ...super.containerProps(),
            multiple,
            accept,
            hiddenFilename,
            bulkPackaging,
        };
    }

    handleChange(value, shouldUpdate = true, fileValue = false) {
        const { isControlled, onChange, type, min, max, multiple, noMinMax } = this.props;

        switch (type) {
            case NUMBER_TYPE:
                const isValueNaN = Number.isNaN(parseInt(value, 10));
                if (!noMinMax && (min > value || value > max || isValueNaN)) {
                    break;
                }
                if (onChange && shouldUpdate) {
                    // eslint-disable-next-line fp/no-let
                    let newValue = value;
                    if (value > max) {
                        newValue = max;
                    } else if (value < min) {
                        newValue = min;
                    }

                    onChange(newValue);
                }

                if (!isControlled) {
                    if (value < min) this.setState({ value: null });
                    else this.setState({ value });
                }
                break;
            case FILE_TYPE:
                if (value) {
                    const result = onChange && onChange(fileValue);

                    this.setState({
                        value: result ? value : '',
                        filename: result ? value.substr(value.lastIndexOf('\\') + 1) : '',
                    });

                    if (multiple) {
                        // eslint-disable-next-line fp/no-let
                        let filenames = '';
                        Array.from(result).forEach((file) => {
                            filenames += `//${file.name}`;
                        });

                        this.setState({
                            value: result ? value : '',
                            filename: result ? filenames : '',
                        });
                    }
                }

                break;
            default:
                if (onChange) {
                    onChange(value);
                }
                if (!isControlled) {
                    this.setState({ value });
                }
        }
    }

    onChange(event, value) {
        const { type, multiple } = this.props;
        this.setState({ eventId: event?.target?.name });

        if (typeof value === 'string' || typeof value === 'number') {
            return this.handleChange(value);
        }

        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(event);
        }

        if (event.currentTarget.value.length <= 0) {
            this.setState({
                validationStatus: null,
            });
        }

        this.updateValidationStatus();

        if (type === FILE_TYPE) {
            if (multiple) {
                return this.handleChange(event.target.value, false, event.target.files);
            }

            return this.handleChange(event.target.value, false, event.target.files[0]);
        }

        return this.handleChange(event.target.value);
    }

    onRemoveAttechment() {
        const { handleRemoveAttechment } = this.props;

        handleRemoveAttechment();
        this.setState({
            value: '',
            filename: '',
        });
    }

    handleCalculateValue(operationType) {
        const { value } = this.state;
        const { bulkPackaging } = this.props;

        switch (operationType) {
            case OperationType.ADD:
                return +value + bulkPackaging;
            case OperationType.SUBTRACT:
                const newValue = +value - bulkPackaging;

                return newValue < bulkPackaging ? bulkPackaging : newValue;
            default:
                break;
        }
    }

    onBlur(event) {
        const { onBlur, value, updateValueOnBlur } = this.props;

        if (onBlur) {
            if (updateValueOnBlur) this.setState({ value });

            onBlur(event);
        }
    }
}

export default FieldContainer;
