import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import { CHECKBOX_TYPE } from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import ShowMoreLessContent from 'Component/ShowMoreLessContent';

import './Consents.styles.scss';

/** @namespace AdvoxBasic/Component/Consents/Component/Consents */
export class Consents extends PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
    };

    static defaultProps = {
        data: [],
    };

    renderRequire(isRequired) {
        const { validateConsents } = this.props;

        if (isRequired && validateConsents) {
            return (
                <p block="Field" elem="Message">
                    {__('This field is required!')}
                </p>
            );
        }
    }

    renderConsent(data) {
        const { updateSelectedConsentIds, showMoreLess, currentPlace, contentLength, requireInfo } = this.props;
        const { consent_id, is_checkbox, is_required: isRequired, description } = data;

        if (!is_checkbox) {
            return <Html key={consent_id} content={description} />;
        }

        const requiredTemplate = (
            <span mix={{ block: 'Consents', elem: 'Label', mods: { isRequired } }}>({__('required')})</span>
        );

        const consentId = !currentPlace ? consent_id : `${currentPlace}${consent_id}`;

        return (
            <div block="Consents" elem="FieldWrapper" key={consentId}>
                <Field
                    mix={{ block: 'Consents', elem: 'Field', mods: { isRequired } }}
                    id={consentId}
                    name={consentId}
                    value={consentId}
                    type={CHECKBOX_TYPE}
                    required={isRequired}
                    validation={['notEmpty']}
                    label={
                        showMoreLess ? (
                            <>
                                <ShowMoreLessContent contentLength={contentLength}>
                                    <Html content={description} />
                                </ShowMoreLessContent>
                                {isRequired && requireInfo && requiredTemplate}
                            </>
                        ) : (
                            <>
                                {parser(description)} {isRequired && requireInfo && requiredTemplate}
                            </>
                        )
                    }
                    onChange={updateSelectedConsentIds}
                />
                {this.renderRequire(isRequired)}
            </div>
        );
    }

    render() {
        const { data } = this.props;

        if (data === null) {
            return <Loader isLoading />;
        }

        if (!data || !data.length) {
            return null;
        }

        return <div block="Consents">{data.map((item) => this.renderConsent(item))}</div>;
    }
}

export default Consents;
