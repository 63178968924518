import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    RmaCreateContainer as AdvoxBasicRmaCreateContainer,
} from 'AdvoxBasicComponent/RmaCreate/RmaCreate.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace AdvoxStrigob2b/Component/RmaCreate/Container/RmaCreateContainer */
export class RmaCreateContainer extends AdvoxBasicRmaCreateContainer {
    rmaOrderHandler(fields) {
        const { order_id } = fields || {};

        // Decode base64 order_id
        this.requestOrderDetails(atob(order_id));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaCreateContainer);
