import { MyAccountSignIn as AdvoxBasicMyAccountSignIn } from 'AdvoxBasicComponent/MyAccountSignIn/MyAccountSignIn.component';
import Field from 'Component/Field';
import Form from 'Component/Form';

/** @namespace AdvoxStrigob2b/Component/MyAccountSignIn/Component/MyAccountSignIn */
export class MyAccountSignIn extends AdvoxBasicMyAccountSignIn {
    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            isCheckout,
        } = this.props;

        return (
            <Form
                key="sign-in"
                onSubmit={onSignInAttempt}
                onSubmitSuccess={onSignInSuccess}
                onSubmitError={onFormError}
            >
                <Field
                    type="email"
                    label={__('Email')}
                    id="email"
                    name="email"
                    placeholder={__('Your email address')}
                    value={emailValue}
                    autocomplete={isCheckout ? 'off' : 'email'}
                    validation={['notEmpty', 'email']}
                    onChange={handleEmailInput}
                />
                <Field
                    type="password"
                    label={__('Password')}
                    id="password"
                    name="password"
                    placeholder={__('Enter your password')}
                    autocomplete="current-password"
                    validation={['notEmpty', 'password']}
                />
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{__('Sign in')}</button>
                </div>
                <button
                    type="button"
                    block="Button"
                    mods={{ likeLink: true }}
                    mix={{ block: 'MyAccountOverlay', elem: 'ForgotPassword' }}
                    onClick={handleForgotPassword}
                >
                    {__('Remind password')}
                </button>
            </Form>
        );
    }
}

export default MyAccountSignIn;
