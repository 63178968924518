import { FieldForm as AdvoxBasicFieldForm } from 'AdvoxBasicComponent/FieldForm/FieldForm.component';
import Field from 'Component/Field';

/** @namespace AdvoxStrigob2b/Component/FieldForm/Component/FieldForm */
export class FieldForm extends AdvoxBasicFieldForm {
    renderField = (fieldEntry) => {
        const {
            key = null,
            isSubmitted,
            id = null,
            label = null,
            name = null,
            onChange = null,
            placeholder = null,
            type = null,
            validateSeparately,
            validation = [],
            value = null,
            selectOptions = [],
            checked = null,
            ariaLabel = null,
            inputMask = '',
            multiple = false,
            disabled = false,
            numberWithoutButtons = false,
            noMinMax = false,
            accept = null,
            handleRemoveAttechment = null, 
        } = this.getDefaultValues(fieldEntry);

        return (
            <Field
                id={id}
                key={key}
                label={label}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                validateSeparately={validateSeparately}
                validation={validation}
                value={value}
                isSubmitted={isSubmitted}
                selectOptions={selectOptions}
                checked={checked}
                ariaLabel={ariaLabel}
                inputMask={inputMask}
                multiple={multiple}
                disabled={disabled}
                numberWithoutButtons={numberWithoutButtons}
                noMinMax={noMinMax}
                accept={accept}
                handleRemoveAttechment={handleRemoveAttechment}
            />
        );
    };
}

export default FieldForm;
