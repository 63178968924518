import Icon from 'Component/Icon';
import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

/** @namespace AdvoxStrigob2b/Component/ChevronIcon/Component/ChevronIcon */
export class ChevronIcon extends SourceChevronIcon {
    render() {
        const { direction } = this.props;

        return <Icon width="8" height="8" block="ChevronIcon" mods={{ direction }} name="full_small_arrow_right" />;
    }
}

export default ChevronIcon;
