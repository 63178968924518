import { Brands as AdvoxStrigoB2BBrands } from 'AdvoxStrigoB2BComponent/Brands/Brands.component';

import './Brands.mergo.style';

/** @namespace AdvoxMergob2b/Component/Brands/Component/Brands */
export class Brands extends AdvoxStrigoB2BBrands {
    // TODO implement logic
}

export default Brands;
