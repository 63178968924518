import { Field } from 'Util/Query';

/** @namespace AdvoxBasic/Query/AdvoxConsent/Query/AdvoxConsentQuery */
export class AdvoxConsentQuery {
    getAdvoxConsentsQuery(page) {
        return new Field('getConsents')
            .addArgument('page', 'PageType!', page)
            .addFieldList(['consent_id', 'description', 'is_checkbox', 'is_required']);
    }

    getAdvoxConsentsQueryMutation(guest_cart_id, consentIds) {
        const mutation = new Field('setConsentsOnCheckout');
        this._addAskForProductMutationArguments(mutation, guest_cart_id, consentIds);
        mutation.addField(this._getCartField());

        return mutation;
    }

    _addAskForProductMutationArguments(mutation, guest_cart_id, consentIds) {
        if (guest_cart_id) {
            mutation.addArgument('guestCartId', 'String', guest_cart_id);
        }

        return mutation.addArgument('consentIds', '[String!]!', consentIds);
    }

    _getCartField() {
        return new Field('cart').addField(this._getCartFields());
    }

    _getCartFields() {
        return new Field('selected_checkout_consents').addFieldList(this._getSelectedCheckoutConsentsFields());
    }

    _getSelectedCheckoutConsentsFields() {
        return ['consent_id'];
    }
}
export default new AdvoxConsentQuery();
