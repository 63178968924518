import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MY_ACCOUNT_URL, RETURNS_URL } from 'Route/MyAccount/MyAccount.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { ordersType } from 'Type/Account';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { getIndexedProducts } from 'Util/Product';
import DataContainer from 'Util/Request/DataContainer';
import { appendWithStoreCode } from 'Util/Url';

import RmaCreate from './RmaCreate.component';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

export const RmaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Rma/Rma.dispatcher'
);

/** @namespace AdvoxBasic/Component/RmaCreate/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    orderList: state.OrderReducer.orderList,
    order: state.OrderReducer.order,
    isLoading: state.OrderReducer.isLoading,
    device: state.ConfigReducer.device,
    currentStoreId: state.ConfigReducer.id,
    customerEmail: state.MyAccountReducer.customer.email,
    customerRma: state.RmaReducer.rmaData,
});

/** @namespace AdvoxBasic/Component/RmaCreate/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getOrderList: () => OrderDispatcher.then(({ default: dispatcher }) => dispatcher.requestOrders(dispatch)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrderById: (orderId) =>
        OrderDispatcher.then(({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)),
    getRmaData: (isSignleRma) =>
        RmaDispatcher.then(({ default: dispatcher }) => dispatcher.getRmaData(dispatch, isSignleRma)),
    createRma: (options, scrollToTop) =>
        RmaDispatcher.then(({ default: dispatcher }) => dispatcher.createRma(dispatch, options, scrollToTop)),
});

/** @namespace AdvoxBasic/Component/RmaCreate/Container/RmaCreateContainer */
export class RmaCreateContainer extends DataContainer {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        getOrderList: PropTypes.func.isRequired,
        orderList: ordersType.isRequired,
        getOrderById: PropTypes.func.isRequired,
    };

    state = {
        isLoading: false,
        ordersToReturn: [],
        rmaReasons: [],
        rmaSolutions: [],
        firstOrderChosen: false,
    };

    containerFunctions = {
        onOrderPickFormSubmit: this.rmaOrderHandler.bind(this),
        rmaCreateHandler: this.rmaCreateHandler.bind(this),
        removeRmaDraft: this.removeRmaDraft.bind(this),
        setItemToReturn: this.setItemToReturn.bind(this),
        setReturnQuantity: this.setReturnQuantity.bind(this),
        setRmaReason: this.setRmaReason.bind(this),
        setRmaSolution: this.setRmaSolution.bind(this),
        onOrderIdFieldChange: this.onOrderIdFieldChange.bind(this),
        onRmaBackButtonClick: this.onRmaBackButtonClick.bind(this),
    };

    componentDidMount() {
        const { getOrderList, getRmaData } = this.props;

        this.getRmaExtraInfo();
        getOrderList();
        getRmaData(true);
    }

    containerProps() {
        const { orderList, customerEmail, device } = this.props;
        const { isLoading, ordersToReturn, rmaReasons, rmaSolutions, firstOrderChosen } = this.state;

        return {
            orderList,
            isLoading,
            ordersToReturn,
            rmaReasons,
            rmaSolutions,
            customerEmail,
            firstOrderChosen,
            device,
        };
    }

    prepareRmaExtraInfoForSelect(options) {
        if (options) {
            // get only active options and prepare them to work with select
            return options
                .filter((option) => option.is_active === true)
                .map(({ name }) => ({
                    id: name,
                    label: __(name),
                    value: name,
                }));
        }
    }

    getRmaExtraInfo() {
        this.setState({
            isLoading: true,
        });

        // hardcoded cause modules graphql does not support it
        const rmaDictionary = {
            reasons: [
                {
                    is_active: true,
                    name: 'Out of Service',
                },
                {
                    is_active: true,
                    name: "Don't like",
                },
                {
                    is_active: true,
                    name: 'Wrong color',
                },
                {
                    is_active: true,
                    name: 'Wrong size',
                },
                {
                    is_active: true,
                    name: 'Other',
                },
            ],
            solutions: [
                {
                    is_active: true,
                    name: 'Exchange',
                },
                {
                    is_active: true,
                    name: 'Refund',
                },
                {
                    is_active: true,
                    name: 'Store Credit',
                },
            ],
        };

        const activeReasons = this.prepareRmaExtraInfoForSelect(rmaDictionary.reasons);
        const activeSolutions = this.prepareRmaExtraInfoForSelect(rmaDictionary.solutions);

        this.setState({ rmaReasons: activeReasons, rmaSolutions: activeSolutions, isLoading: false });
    }

    setRmaReason(orderId, itemId, value) {
        this.getUpdatedOrdersToReturn(orderId, itemId, value, 'reasonToReturn');
    }

    setRmaSolution(orderId, itemId, value) {
        this.getUpdatedOrdersToReturn(orderId, itemId, value, 'solutionToReturn');
    }

    setReturnQuantity(orderId, itemId, value) {
        this.getUpdatedOrdersToReturn(orderId, itemId, value, 'qtyToReturn');
    }

    getUpdatedOrdersToReturn(orderId, itemId, value, field) {
        const { ordersToReturn: stateOrdersToReturn } = this.state;
        const copiedOrders = stateOrdersToReturn;
        const updatedOrderIndex = copiedOrders.findIndex((order) => order.base_order_info.id === orderId);
        const updatedItemIndex = copiedOrders[updatedOrderIndex].order_products.findIndex((item) => item.id === itemId);

        copiedOrders[updatedOrderIndex].order_products[updatedItemIndex][field] = value;

        this.setState({
            ordersToReturn: Array.from(copiedOrders),
        });
    }

    setItemToReturn(orderId, itemId, value) {
        if (!value) {
            this.setRmaReason(orderId, itemId, '');
            this.setRmaSolution(orderId, itemId, '');
        }

        this.getUpdatedOrdersToReturn(orderId, itemId, value, 'isItemToReturn');
    }

    async requestOrderDetails(id) {
        const { getOrderById, customerRma } = this.props;

        this.setState({
            isLoading: true,
        });

        if (!isSignedIn()) {
            this.setState({
                isLoading: false,
            });

            return;
        }

        const rawOrder = await getOrderById(id);

        if (!rawOrder) {
            this.setState({
                isLoading: false,
            });

            return;
        }

        const { order_products = [], base_order_info: { increment_id } = {} } = rawOrder;

        const customerRmaByOrder = customerRma?.filter((order) => order.order_increment_id === increment_id);
        const returnedProductsIdByOrder = customerRmaByOrder.length
            ? customerRmaByOrder
                .map((item) => item.request_item)
                .flat()
                .map((item) => item.product_id)
            : [];

        const indexedProducts = getIndexedProducts(order_products);
        indexedProducts.forEach((product) => {
            product.isItemReturned = returnedProductsIdByOrder.includes(product.id);
            product.isItemToReturn = false;
            product.qtyToReturn = 1;
            product.maxQtyToReturn = product.qty;
            product.reasonToReturn = '';
            product.solutionToReturn = '';
        });
        const order = { ...rawOrder, order_products: indexedProducts };

        this.setState({
            isLoading: false,
            ordersToReturn: [{ ...order }],
        });
    }

    removeRmaDraft(id) {
        const newOrdersToReturn = this.state.ordersToReturn.filter((order) => order.base_order_info.id !== id);

        this.setState({
            ordersToReturn: newOrdersToReturn,
        });
    }

    onOrderIdFieldChange(val) {
        if (val !== '') {
            this.setState({ firstOrderChosen: true });
        }
    }

    rmaOrderHandler(fields) {
        const { order_id } = fields || {};

        this.requestOrderDetails(order_id);
    }

    prepareRmaProductsToOptions(item) {
        const { id, qtyToReturn, reasonToReturn, solutionToReturn } = item;

        return {
            product_id: id,
            qty_rma: qtyToReturn,
            ...(reasonToReturn && { reason: reasonToReturn }),
            ...(solutionToReturn && { solution: solutionToReturn }),
        };
    }

    prepareRmaOrdersToOptions() {
        const { ordersToReturn } = this.state;
        const { currentStoreId } = this.props;

        const { base_order_info } = ordersToReturn[0];

        const currentOrdersToReturn = ordersToReturn[0].order_products
            .filter((item) => item.isItemToReturn === true)
            .map((item) => this.prepareRmaProductsToOptions(item));

        return {
            order_increment_id: base_order_info.increment_id,
            store_id: currentStoreId,
            request_item: currentOrdersToReturn,
        };
    }

    rmaCreateHandler() {
        const options = this.prepareRmaOrdersToOptions();
        const { request_item } = options;
        const { showNotification, createRma } = this.props;

        if (!request_item.length) {
            showNotification('info', __('No product has been selected'));

            return;
        }

        createRma(options, this.scrollTop);
    }

    onRmaBackButtonClick() {
        history.push(appendWithStoreCode(`${MY_ACCOUNT_URL}/${RETURNS_URL}`));

        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    render() {
        return <RmaCreate {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaCreateContainer);
