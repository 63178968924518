import { MyAccountTabListItem as SourceMyAccountTabListItem } from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import './MyAccountTabListItem.override.style';

/** @namespace AdvoxBasic/Component/MyAccountTabListItem/Component/MyAccountTabListItem */
export class MyAccountTabListItem extends SourceMyAccountTabListItem {
    // TODO implement logic
}

export default MyAccountTabListItem;
