import { PureComponent } from 'react';

import ShowMoreLessContent from 'Component/ShowMoreLessContent/ShowMoreLessContent.component';

/** @namespace AdvoxBasic/Component/ShowMoreLessContent/Container/ShowMoreLessContentContainer */
export class ShowMoreLessContentContainer extends PureComponent {
    state = {
        isExpanded: false,
    };

    static defaultProps = {
        contentLength: 120,
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this),
    };

    containerProps() {
        const { isExpanded } = this.state;
        const { children: { props: { content = '' } = {} } = {}, contentLength } = this.props;

        return { content, isExpanded, contentLength };
    }

    handleClick(e) {
        e.preventDefault();

        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    }

    render() {
        return <ShowMoreLessContent {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default ShowMoreLessContentContainer;
