import Field from 'Component/Field';
import Form from 'Component/Form';
import Icon from 'Component/Icon';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

/** @namespace AdvoxBasic/Component/MyAccountSignIn/Component/MyAccountSignIn */
export class MyAccountSignIn extends SourceMyAccountSignIn {
    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            isCheckout,
        } = this.props;

        return (
            <Form
                key="sign-in"
                onSubmit={onSignInAttempt}
                onSubmitSuccess={onSignInSuccess}
                onSubmitError={onFormError}
            >
                <Field
                    type="email"
                    label={__('Email')}
                    id="email"
                    name="email"
                    placeholder={__('Your email address')}
                    value={emailValue}
                    autoComplete={isCheckout ? 'off' : 'email'}
                    validation={['notEmpty', 'email']}
                    onChange={handleEmailInput}
                />
                <Field
                    type="password"
                    label={__('Password')}
                    id="password"
                    name="password"
                    placeholder={__('Enter your password')}
                    autoComplete="current-password"
                    validation={['notEmpty', 'password']}
                />
                <button
                    type="button"
                    block="Button"
                    mods={{ likeLink: true }}
                    mix={{ block: 'MyAccountOverlay', elem: 'ForgotPassword' }}
                    onClick={handleForgotPassword}
                >
                    {__('Forgot password?')}
                </button>
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">
                        {__('Sign In')}
                        <Icon name="small_arrow_right" fill="#fff" height="10" />
                    </button>
                </div>
            </Form>
        );
    }
}

export default MyAccountSignIn;
