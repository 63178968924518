import { PureComponent } from 'react';

import RmaList from 'Component/RmaList';

import './MyAccountReturns.style';

/** @namespace AdvoxBasic/Component/MyAccountReturns/Component/MyAccountReturns */
export class MyAccountReturns extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderActions() {
        const { goToCreateRMAView } = this.props;

        return (
            <button
                block="Button"
                mix={{ block: 'MyAccountReturns', elem: 'Button' }}
                mods={{ secondary: true }}
                onClick={goToCreateRMAView}
            >
                {__('Request new return')}
            </button>
        );
    }

    renderRMAList() {
        const { customerRma, isLoading } = this.props;
        return <RmaList customerRma={customerRma} isLoading={isLoading} />;
    }

    render() {
        return (
            <div block="MyAccountReturns">
                {this.renderActions()}
                {this.renderRMAList()}
            </div>
        );
    }
}

export default MyAccountReturns;
