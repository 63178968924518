import { CartQuery as AdvoxBasicCartQuery } from 'AdvoxBasicQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace AdvoxStrigob2b/Query/Cart/Query/CartQuery */
export class CartQuery extends AdvoxBasicCartQuery {
    getOptimizeCartMutation() {
        return new Field('optimizeCartBeforeOrder').addFieldList(['is_optimized']);
    }

    getRemoveAllCartItemsMutation(quoteId) {
        const mutation = new Field('clearCartFromItems').addFieldList([]);

        if (!isSignedIn()) {
            mutation.addArgument('cart_id', 'String', quoteId);
        }

        return mutation;
    }

    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            this._getCustomizableOptionsFields(),
            this._getDownloadableLinksField(),
            this._getBundleOptionsField(),
            this._getProductField(),
            'is_custom_price_item',
        ];
    }
}

export default new CartQuery();
