import PropTypes from 'prop-types';
import { Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import MyAccountReturns from 'Component/MyAccountReturns';
import MyAccountTabList from 'Component/MyAccountTabList';
import RmaCreate from 'Component/RmaCreate';
import RmaView from 'Component/RmaView';
import {
    MyAccount as SourceMyAccount,
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription,
} from 'SourceRoute/MyAccount/MyAccount.component';
import { CREATE_RETURN, RETURNS, VIEW_RETURN } from 'Type/Account';
import { isSignedIn } from 'Util/Auth';

import './MyAccount.override.style';

export {
    MyAccountAddressBook,
    MyAccountDashboard,
    MyAccountDownloadable,
    MyAccountMyOrders,
    MyAccountMyWishlist,
    MyAccountNewsletterSubscription,
};

/** @namespace AdvoxBasic/Route/MyAccount/Component/MyAccount */
export class MyAccount extends SourceMyAccount {
    static propTypes = {
        ...SourceMyAccount.propTypes,
        subHeading: PropTypes.string,
    };

    renderMap = {
        ...this.renderMap,
        [RETURNS]: MyAccountReturns,
        [CREATE_RETURN]: RmaCreate,
        [VIEW_RETURN]: RmaView,
    };

    shouldComponentUpdate(nextProps) {
        const { activeTab, subHeading } = this.props;
        const { activeTab: nextActiveTab, subHeading: nextSubHeading } = nextProps;

        return activeTab !== nextActiveTab || subHeading !== nextSubHeading;
    }

    renderContent() {
        const { tabMap, changeActiveTab, onSignOut, isEditingActive } = this.props;

        // eslint-disable-next-line fp/no-let
        let { activeTab } = this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        const TabContent = this.renderMap[activeTab];

        // eslint-disable-next-line fp/no-let
        let { name } = tabMap[activeTab];

        if (activeTab === VIEW_RETURN) {
            name = '';
        }

        if (activeTab === CREATE_RETURN || activeTab === VIEW_RETURN) {
            activeTab = RETURNS;
        }

        return (
            <ContentWrapper label={__('My Account page')} wrapperMix={{ block: 'MyAccount', elem: 'Wrapper' }}>
                <MyAccountTabList
                    tabMap={tabMap}
                    activeTab={activeTab}
                    changeActiveTab={changeActiveTab}
                    onSignOut={onSignOut}
                />
                <div block="MyAccount" elem="TabContent">
                    <h2 block="MyAccount" elem="Heading">
                        {name}
                        {this.renderSubHeading()}
                    </h2>
                    <Suspense fallback={<Loader />}>
                        <TabContent isEditingActive={isEditingActive} />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }
}

export default MyAccount;
