import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    CONSENTS_CHECKOUT,
    CONSENTS_CONTACT,
    CONSENTS_NEWSLETTER,
    CONSENTS_REGISTER,
} from 'Component/Consents/Consents.config';
import { CHECKBOX_TYPE } from 'Component/Field/Field.config';
import AdvoxConsent from 'Query/AdvoxConsent.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executeGet, getErrorMessage } from 'Util/Request';
import getStore from 'Util/Store';

import Consents from './Consents.component';

/** @namespace AdvoxBasic/Component/Consents/Container/ConsentsContainer */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]),
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
        showMoreLess: PropTypes.bool,
        currentPlace: PropTypes.string,
        validateConsents: PropTypes.bool,
        contentLength: PropTypes.number,
        requireInfo: PropTypes.bool,
    };

    static defaultProps = {
        data: null,
        showMoreLess: false,
        validateConsents: false,
        requireInfo: true,
    };

    static getConsentsByType(type) {
        if (!type) throw new Error('Consents type is required');

        const query = AdvoxConsent.getAdvoxConsentsQuery(type);

        return executeGet(prepareQuery(query))
            .then(
                /** @namespace AdvoxBasic/Component/Consents/Container/executeGet/then */
                ({ getConsents }) => getConsents
            )
            .catch(
                /** @namespace AdvoxBasic/Component/Consents/Container/executeGet/then/catch */
                (error) => {
                    getStore().dispatch(showNotification('error', getErrorMessage(error), error));
                }
            );
    }

    static getAcceptedConsents({ fields = {}, consents = [] } = {}) {
        const checkbox = fields
            .filter((field) => field.type === CHECKBOX_TYPE && field.value === true)
            .map((consent) => consent.name);
        const text = consents.filter((consent) => !consent.is_checkbox).map((consent) => consent.consent_id);

        return [...checkbox, ...text];
    }

    state = {
        data: [],
        selectedConsentIds: [],
        requiredContentIds: [],
        areAllRequiredContentsAccepted: false,
    };

    containerFunctions = {
        updateSelectedConsentIds: this.updateSelectedConsentIds.bind(this),
    };

    componentDidMount() {
        this.getContentsData();
    }

    containerProps() {
        const { data } = this.state;
        const { showMoreLess, currentPlace, validateConsents, contentLength, requireInfo } = this.props;

        return {
            data,
            showMoreLess,
            currentPlace,
            validateConsents,
            contentLength,
            requireInfo,
        };
    }

    async getContentsData() {
        const { data, type } = this.props;

        if (data) {
            this.setState((prevState) => ({
                ...prevState,
                data,
            }));
        } else if (!data && type) {
            await ConsentsContainer.getConsentsByType(type).then(
                /** @namespace AdvoxBasic/Component/Consents/Container/getConsentsByType/then */
                (response) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        data: response,
                    }));

                    this.getRequiredContents();
                }
            );
        }
    }

    getRequiredContents() {
        const { data } = this.state;
        const requiredContentIds = data.filter(({ is_required }) => is_required).map(({ consent_id }) => consent_id);

        this.setState({
            requiredContentIds,
            areAllRequiredContentsAccepted: requiredContentIds.length <= 0,
        });
    }

    checkTermsAndConditions = () => {
        const { requiredContentIds, selectedConsentIds } = this.state;
        const requiredConsentsSelected = requiredContentIds.every((elem) => selectedConsentIds.indexOf(elem) > -1);

        this.setState({
            areAllRequiredContentsAccepted: requiredConsentsSelected,
        });
    };

    updateSelectedConsentIds(consentId, isChecked) {
        const { selectedConsentIds } = this.state;
        const { currentPlace } = this.props;

        const currentConsentId = !currentPlace ? consentId : consentId.split(currentPlace).pop();

        const contents = isChecked
            ? selectedConsentIds.concat(currentConsentId)
            : selectedConsentIds.filter((val) => val !== currentConsentId);

        this.setState(
            {
                selectedConsentIds: contents,
            },
            () => this.checkTermsAndConditions()
        );
    }

    render() {
        return <Consents {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default ConsentsContainer;
