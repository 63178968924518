import { Brands as AdvoxBasicBrands } from 'AdvoxBasicComponent/Brands/Brands.component';

import './Brands.extend.style';

/** @namespace AdvoxStrigob2b/Component/Brands/Component/Brands */
export class Brands extends AdvoxBasicBrands {
    // TODO implement logic
}

export default Brands;
