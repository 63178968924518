import { connect } from 'react-redux';

import { MY_ACCOUNT_URL, RETURNS_URL } from 'Route/MyAccount/MyAccount.config';
import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import DataContainer from 'Util/Request/DataContainer';
import { appendWithStoreCode } from 'Util/Url';

import RmaView from './RmaView.component';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

export const RmaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Rma/Rma.dispatcher'
);

/** @namespace AdvoxBasic/Component/RmaView/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    orderList: state.OrderReducer.orderList,
    order: state.OrderReducer.order,
    isLoading: state.OrderReducer.isLoading,
    device: state.ConfigReducer.device,
    currentStoreId: state.ConfigReducer.id,
    customerAddresses: state.MyAccountReducer.customer.addresses,
    customerRma: state.RmaReducer.rmaData,
    rmaDataLoading: state.RmaReducer.rmaDataLoading,
});

/** @namespace AdvoxBasic/Component/RmaView/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getOrderList: () => OrderDispatcher.then(({ default: dispatcher }) => dispatcher.requestOrders(dispatch)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrderById: (orderId) =>
        OrderDispatcher.then(({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)),
    getRmaData: (isSignleRma) =>
        RmaDispatcher.then(({ default: dispatcher }) => dispatcher.getRmaData(dispatch, isSignleRma)),
});

/** @namespace AdvoxBasic/Component/RmaView/Container/RmaViewContainer */
export class RmaViewContainer extends DataContainer {
    state = {
        isLoading: false,
        currentRma: {},
        currentRmaId: null,
    };

    containerFunctions = {
        onRmaBackButtonClick: this.onRmaBackButtonClick.bind(this),
    };

    containerProps = () => {
        const { currentRma, isLoading } = this.state;
        const { device, customerAddresses, orderList } = this.props;

        return {
            currentRma,
            device,
            customerAddresses,
            isLoading,
            orderList,
        };
    };

    componentDidMount() {
        const { getOrderList, getRmaData } = this.props;
        getOrderList();
        getRmaData(true);

        const rmaId = this.getRMAId();

        this.setState({
            isLoading: true,
            currentRmaId: rmaId,
        });
    }

    componentDidUpdate(prevProps) {
        const { rmaDataLoading } = this.props;

        if (prevProps.rmaDataLoading !== rmaDataLoading) {
            const { currentRmaId } = this.state;

            this.getRMAInfo(currentRmaId);
        }
    }

    getRMAId() {
        const splittedPath = history.location.pathname.split('/');

        return splittedPath[splittedPath.length - 1];
    }

    getRMAInfo(id) {
        const { customerRma } = this.props;

        if (customerRma.length) {
            const filteredRma = customerRma?.filter((item) => item.increment_id === id);

            this.setState({ currentRma: filteredRma[0], isLoading: false });
        }
    }

    onRmaBackButtonClick() {
        history.push(appendWithStoreCode(`${MY_ACCOUNT_URL}/${RETURNS_URL}`));

        this.scrollTop();
    }

    scrollTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    render() {
        return <RmaView {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RmaViewContainer);
