import { formConfig as sourceFormConfig, MIN_PASSWORD_LENGTH } from 'SourceComponent/Form/Form.config';
import getStore from 'Util/Store';

export * from 'SourceComponent/Form/Form.config';

export const MAX_PASSWORD_LENGTH = 64;
export const MIN_PASSWORD_REQUIRED_CHARACTER_CLASSES = 3;

/** @namespace AdvoxStrigob2b/Component/Form/Config/validateIsCheckboxChecked */
export const validateIsCheckboxChecked = ({ checked }) => checked;

export const NIP_NUMBER_LENGTH = 10;

/** @namespace AdvoxStrigob2b/Component/Form/Config/validateMask */
export const validateMask = (element) => {
    const { value } = element || {};
    const maskRegExp = element?.dataset?.maskRegexp;

    if (maskRegExp) {
        const regExpObj = new RegExp(maskRegExp);

        return regExpObj.test(value);
    }

    return true;
};

/** @namespace AdvoxStrigob2b/Component/Form/Config/getNumberOfCharacterClasses */
export const getNumberOfCharacterClasses = (value) =>
    Number(/\d+/.test(value)) +
    Number(/[a-z]+/.test(value)) +
    Number(/[A-Z]+/.test(value)) +
    Number(/[^a-zA-Z0-9]+/.test(value));

/** @namespace AdvoxStrigob2b/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => {
    const state = getStore().getState();
    const {
        minimum_password_length: minimunPasswordLength = MIN_PASSWORD_LENGTH,
        required_character_classes_number: minimunPasswordCharacter = MIN_PASSWORD_REQUIRED_CHARACTER_CLASSES,
    } = state.ConfigReducer;

    const range = { min: minimunPasswordLength, max: MAX_PASSWORD_LENGTH };

    if (value.length < range.min || value.length > range.max) {
        return false;
    }

    const counter = getNumberOfCharacterClasses(value);

    if (counter < Number(minimunPasswordCharacter)) {
        return false;
    }

    return true;
};

/** @namespace AdvoxStrigob2b/Component/Form/Config/messagePassword */
export const messagePassword = ({ value }) => {
    const state = getStore().getState();
    const {
        minimum_password_length: minimunPasswordLength = MIN_PASSWORD_LENGTH,
        required_character_classes_number: minimunPasswordCharacter = MIN_PASSWORD_REQUIRED_CHARACTER_CLASSES,
    } = state.ConfigReducer;

    const range = { min: minimunPasswordLength, max: MAX_PASSWORD_LENGTH };

    if (value.length < range.min) {
        return {
            message: 'Minimum %s characters!',
            value: range.min,
        };
    }

    if (value.length > range.max) {
        return {
            message: 'Maximum %s characters!',
            value: range.max,
        };
    }

    const counter = getNumberOfCharacterClasses(value);

    if (counter < Number(minimunPasswordCharacter)) {
        return {
            message:
                'Minimum of different classes of characters in password is %s. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.',
            value: minimunPasswordCharacter,
        };
    }
};

/** @namespace AdvoxStrigob2b/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^\d{9}$/);

/** @namespace AdvoxStrigob2b/Component/Form/Config/validatePostcode */
export const validatePostcode = ({ value }) => value.length > 0 && value.match(/^([0-9]{2})(-[0-9]{3})?$/);

/** @namespace AdvoxStrigob2b/Component/Form/Config/validateNipNumber */
export const validateNipNumber = ({ value }) => value.length === NIP_NUMBER_LENGTH;

/** @namespace AdvoxStrigob2b/Component/Form/Config/formConfig */
export const formConfig = () => ({
    ...sourceFormConfig(),
    password: {
        validate: validatePassword,
        message: __('The password does not meet the conditions'),
    },
    isChecked: {
        validate: validateIsCheckboxChecked,
        message: __('This field is required!'),
    },
    // general mask validator
    mask: {
        validate: validateMask,
        message: __('Field format is invalid!'),
    },
    // another validator to get proper error message for postcode
    postcodeMask: {
        validate: validateMask,
        message: __('Postcode is invalid!'),
    },
    postcode: {
        validate: validatePostcode,
        message: __('Postcode is invalid!'),
    },
    nip_number: {
        validate: validateNipNumber,
        message: __('Nip number should be 10 characters long'),
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
});

export default formConfig();
