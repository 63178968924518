import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';

import { ADVOX_CONSENT_REGISTER } from 'Component/AdvoxConsent/AdvoxConsent.config';
import { NIP_NUMBER_LENGTH } from 'Component/Form/Form.config';
import Loader from 'Component/Loader';
import AdvoxConsentQuery from 'Query/AdvoxConsent.query';
import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { toBase64 } from 'Util/File';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { appendWithStoreCode } from 'Util/Url';

export const MyAccountCreateContractorAccount = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
        './MyAccountCreateContractorAccount.component'
    )
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
});

/** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    createAccount: (options) =>
        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/MyAccountCreateContractorAccountContainer */
export class MyAccountCreateContractorAccountContainer extends PureComponent {
    static propTypes = {
        createAccount: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
    };

    containerFunctions = {
        handleCreateContractor: this.handleCreateContractor.bind(this),
        setNewsletterChecked: this.setNewsletterChecked.bind(this),
        setConsentChecked: this.setConsentChecked.bind(this),
        isConsentChecked: this.isConsentChecked.bind(this),
        setAttachments: this.setAttachments.bind(this),
        onNipFieldChange: this.onNipFieldChange.bind(this),
    };

    state = {
        isNewsletterChecked: false,
        consentsChecked: {},
        advoxConsentsData: [],
        isLoading: true,
        attachments: [],
        nipNumberData: {},
    };

    componentDidMount() {
        this.getAdvoxConsent();
    }

    setNewsletterChecked(isChecked) {
        this.setState({
            isNewsletterChecked: isChecked,
        });
    }

    async getAdvoxConsent() {
        this.setState({ isLoading: true });

        const rememberedConsent = BrowserDatabase.getItem(ADVOX_CONSENT_REGISTER);

        if (rememberedConsent) {
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 0);

            const consentsObj = {};
            rememberedConsent.forEach(({ consent_id }) => {
                consentsObj[consent_id] = false;
            });

            return this.setState({ advoxConsentsData: rememberedConsent, consentsChecked: { ...consentsObj } });
        }

        await executeGet(prepareQuery(AdvoxConsentQuery.getAdvoxConsentsQuery('register')), true)
            .then(
                /** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/executeGet/then */
                ({ getConsents }) => {
                    const consentsObj = {};
                    getConsents.forEach(({ consent_id }) => {
                        consentsObj[consent_id] = false;
                    });

                    this.setState({
                        advoxConsentsData: getConsents,
                        consentsChecked: { ...consentsObj },
                        isLoading: false,
                    });
                    BrowserDatabase.setItem(getConsents, ADVOX_CONSENT_REGISTER, ONE_MONTH_IN_SECONDS);
                }
            )
            .catch(
                /** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/executeGet/then/catch */
                () => {
                    this.setState({ isLoading: false });
                }
            );
    }

    setConsentChecked(id, value) {
        const { consentsChecked } = this.state;

        this.setState({
            consentsChecked: {
                ...consentsChecked,
                [id]: value,
            },
        });
    }

    setAttachments(filesValue) {
        if (Array.isArray(filesValue)) {
            this.setState({
                attachments: filesValue,
            });
        } else
            this.setState({
                attachments: Array.from(filesValue),
            });
    }

    isConsentChecked(id) {
        const { consentsChecked } = this.state;

        return consentsChecked[id];
    }

    containerProps() {
        const { isNewsletterChecked, advoxConsentsData, consentsChecked, attachments, nipNumberData, isLoading } =
            this.state;

        return {
            isNewsletterChecked,
            advoxConsentsData,
            consentsChecked,
            attachments,
            nipNumberData,
            isLoading,
        };
    }

    async handleCreateContractor(fields) {
        const { createAccount } = this.props;
        const { attachments, advoxConsentsData, consentsChecked } = this.state;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            city,
            remarks = '',
            company,
            country_id,
            telephone,
            postcode,
            vat_id,
            region_id,
        } = fields;

        const preparedAttachments = [];

        if (Array.from(attachments)?.length > 0) {
            await Promise.all(
                Array.from(attachments, async (file) => {
                    preparedAttachments.push({
                        type: file.type,
                        name: file.name,
                        base64_encoded_data: await toBase64(file),
                    });
                })
            );
        }

        const street = Object.keys(fields)
            .filter((key) => key.includes('street'))
            .map((street) => fields[street])
            .join(' ');

        const consentIds = [];

        if (advoxConsentsData && advoxConsentsData.length > 0) {
            advoxConsentsData.forEach((consent) => {
                const { consent_id } = consent || {};

                if (consentsChecked[consent_id]) {
                    consentIds.push(consent_id);
                }
            });
        }

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                password,
                is_subscribed,
                consentIds,
            },
            contractor: {
                city,
                comment: remarks,
                company,
                country: country_id,
                phone: telephone,
                postcode,
                street,
                vat_id,
                attachments: preparedAttachments,
                ...(region_id ? { region: this.getRegionName(country_id, region_id) } : {}),
            },
        };

        await createAccount(customerData);
        history.push(appendWithStoreCode('/'));
    }

    onNipFieldChange(nipNumber) {
        if (nipNumber.length === NIP_NUMBER_LENGTH) {
            this.getNipData(nipNumber);
        }
    }

    getRegionName(countryId, regionId) {
        const { countries } = this.props;

        const region = countries
            .find(({ id }) => id === countryId)
            ?.available_regions.find(({ id }) => id.toString() === regionId);

        return region?.name;
    }

    async getNipData(nipNumber) {
        const { showNotification } = this.props;
        this.setState({
            isLoading: true,
        });

        return fetchQuery(MyAccountQuery.getDataFromNipNumber(nipNumber)).then(
            /** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/fetchQuery/then */
            ({ getVatIdData }) => {
                this.setState({
                    nipNumberData: getVatIdData,
                    isLoading: false,
                });
            },
            /** @namespace AdvoxStrigob2b/Component/MyAccountCreateContractorAccount/Container/fetchQuery/then */
            (error) => {
                showNotification('error', error[0]?.message);
                this.setState({
                    nipNumberData: {},
                    isLoading: false,
                });
            }
        );
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Suspense fallback={<Loader />}>
                {isLoading && <Loader />}
                <MyAccountCreateContractorAccount {...this.containerProps()} {...this.containerFunctions} />
            </Suspense>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateContractorAccountContainer);
