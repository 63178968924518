import { withRouter } from 'react-router-dom';

import { ProductListPage as SourceProductListPage } from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductListPage/Component/ProductListPage */
export class ProductListPage extends SourceProductListPage {
    // TODO implement logic
}

export default withRouter(ProductListPage);
