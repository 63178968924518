import { PRICE_LABEL_MAP, TIER_PRICES } from '@scandipwa/scandipwa/src/util/Price/Price.config';

import { isSignedIn } from 'Util/Auth';

export * from 'AdvoxBasicUtil/Price/Price.config';
export * from 'AdvoxBasicUtil/Price/Price';

/** @namespace AdvoxStrigob2b/Util/Price/arePricesHidden */
export const arePricesHidden = (state) => {
    const { ConfigReducer: { pricevisibility_pricevisibility_enabled } = {} } = state;

    const hidePrices = !isSignedIn() && !!pricevisibility_pricevisibility_enabled;

    return hidePrices;
};

/** @namespace AdvoxStrigob2b/Util/Price/calculatePriceWithQty */
export const calculatePriceWithQty = (value, qty) => (value * 100 * qty) / 100;

/** @namespace AdvoxStrigob2b/Util/Price/getSummaryProductsPrice */
export const getSummaryProductsPrice = (products) =>
    (products ?? []).reduce(
        (prev, curr) => {
            const { qty, product } = curr;
            const { price_range: { minimum_price: { final_price = {}, final_price_excl_tax = {} } = {} } = {} } =
                product ?? {};

            return {
                final_price: {
                    value: (prev.final_price.value * 100 + (final_price.value ?? 0) * 100 * qty) / 100,
                    currency: prev.final_price.currency ?? final_price.currency,
                },
                final_price_excl_tax: {
                    value:
                        (prev.final_price_excl_tax.value * 100 + (final_price_excl_tax.value ?? 0) * 100 * qty) / 100,
                    currency: prev.final_price_excl_tax.currency ?? final_price_excl_tax.currency,
                },
            };
        },
        {
            final_price: {
                value: 0,
                currency: undefined,
            },
            final_price_excl_tax: {
                value: 0,
                currency: undefined,
            },
        }
    );

/** @namespace AdvoxStrigob2b/Util/Price/getPriceLabel */
export const getPriceLabel = (type_id, price_tiers) => {
    const typeId = price_tiers.length && !price_tiers.every(({ quantity }) => quantity === 1) ? TIER_PRICES : type_id;

    const label = PRICE_LABEL_MAP[typeId];
    if (!label) {
        return null;
    }

    return label;
};
