import { ProductLabel as AdvoxBasicProductLabel } from 'AdvoxBasicComponent/ProductLabel/ProductLabel.component';

import './ProductLabel.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductLabel/Component/ProductLabel */
export class ProductLabel extends AdvoxBasicProductLabel {
    // TODO implement logic
}

export default ProductLabel;
