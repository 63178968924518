import { ConfigQuery as AdvoxBasicConfigQuery } from 'AdvoxBasicQuery/Config.query';

/** @namespace AdvoxStrigob2b/Query/Config/Query/ConfigQuery */
export class ConfigQuery extends AdvoxBasicConfigQuery {
    _getStoreConfigFields() {
        return [...super._getStoreConfigFields(), 'pricevisibility_pricevisibility_enabled', 'loyalty_program_url'];
    }
}

export default new ConfigQuery();
