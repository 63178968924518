import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import './CustomPrice.style';

/** @namespace AdvoxStrigob2b/Component/CustomPrice/Component/CustomPrice */
export class CustomPrice extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        isSingleProduct: PropTypes.bool,
    };

    static defaultProps = {
        label: __('Your price'),
        isSingleProduct: false,
    };

    renderLabel() {
        const { label } = this.props;

        return (
            <div block="CustomPrice" elem="Label">
                {label}
            </div>
        );
    }

    renderPrices() {
        const { priceExcludingTax, priceIncludingTax } = this.props;

        return (
            <div block="CustomPrice" elem="Prices">
                <div block="CustomPrice" elem="PriceItem" mods={{ withoutTax: true }}>
                    <span block="CustomPrice" elem="PriceLabel">
                        {__('excl. tax')}
                    </span>
                    <span block="CustomPrice" elem="PriceValue">
                        {formatPrice(priceExcludingTax.value, priceExcludingTax.currency)}
                    </span>
                </div>
                <div block="CustomPrice" elem="PriceItem">
                    <span block="CustomPrice" elem="PriceLabel">
                        {__('incl. tax')}
                    </span>
                    <span block="CustomPrice" elem="PriceValue">
                        {formatPrice(priceIncludingTax.value, priceIncludingTax.currency)}
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { isSingleProduct } = this.props;

        return (
            <div block="CustomPrice" mods={{ isSingleProduct }}>
                {this.renderLabel()}
                {this.renderPrices()}
            </div>
        );
    }
}

export default CustomPrice;
