import { AccordionComponent as AdvoxBasicAccordionComponent } from 'AdvoxBasicComponent/Accordion/Accordion.component';

import './Accordion.extend.style';

/** @namespace AdvoxStrigob2b/Component/Accordion/Component/AccordionComponent */
export class AccordionComponent extends AdvoxBasicAccordionComponent {
    // TODO implement logic
}

export default AccordionComponent;
