import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import { getCurrency } from 'Util/Currency';
import { formatCurrency } from 'Util/Price';

import './RmaView.style';

/** @namespace AdvoxBasic/Component/RmaView/Component/RmaView */
export class RmaView extends PureComponent {
    rmaStatuses = [
        {
            name: 'Pending Approval',
            title: __('Pending Approval'),
        },
        {
            name: 'Approved',
            title: __('Approved'),
        },
        {
            name: 'Package Sent',
            title: __('Package Sent'),
        },
        {
            name: 'Closed',
            title: __('Closed'),
        },
    ];

    getRmaStatusInfo(id) {
        const statuses = {
            1: __('Pending'),
            2: __('Processing'),
            3: __('Rejected'),
            4: __('Completed'),
            5: __('Canceled'),
        };

        return statuses[id] ? statuses[id] : null;
    }

    renderRmaHeading() {
        const { currentRma } = this.props;

        if (!currentRma) {
            return null;
        }

        const {
            currentRma: { order_increment_id, status_id },
        } = this.props;

        return (
            <h2 block="RmaView" elem="Heading">
                {`RMA #${order_increment_id} `} <span>{`(${this.getRmaStatusInfo(status_id)})`}</span>
            </h2>
        );
    }

    // Steps are not displayed
    // TODO: The steps will be displayed if agreed upon.
    // renderRmaStatusBar() {
    //     return (
    //         <div block="RmaView" elem="StatusSteps">
    //             {this.rmaStatuses.map((item) => this.renderStatusStep(item))}
    //         </div>
    //     );
    // }

    // renderRmaStatusStepTitle(title) {
    //     const {
    //         device: { isMobile },
    //     } = this.props;
    //
    //     if (isMobile) {
    //         return null;
    //     }
    //
    //     return (
    //         <span block="RmaView" elem="StepTitle">
    //             {title}
    //         </span>
    //     );
    // }

    // renderStatusStep(item) {
    //     const { name, title } = item || {};
    //     const {
    //         currentRma: { status_id },
    //     } = this.props;
    //     const currentStatus = this.getRmaStatusInfo(status_id)?.value;
    //
    //     return (
    //         <div block="RmaView" elem="Step" mods={{ active: currentStatus === name }} key={name}>
    //             <button type="button">
    //                 <span block="RmaView" elem="StepCount" />
    //                 {this.renderRmaStatusStepTitle(title)}
    //             </button>
    //         </div>
    //     );
    // }

    getNoTimeDate(rawDate) {
        if (rawDate) {
            const date = new Date(rawDate.replace(/\s/, 'T'));
            const RADIX = 10;

            const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

            const day = addLeadingZero(date.getDate());
            const month = addLeadingZero(date.getMonth() + 1);

            return `${date.getFullYear()}-${month}-${day}`;
        }
    }

    renderRequestInfoBlock() {
        const {
            orderList,
            currentRma: { increment_id, created_at, status_id, order_increment_id },
        } = this.props;

        const currency = getCurrency();
        const currentStatus = this.getRmaStatusInfo(status_id);

        const currentOrder =
            orderList?.filter(({ base_order_info }) => base_order_info.increment_id === order_increment_id)[0] || {};

        const { base_order_info: { grand_total, created_at: order_created_at } = {} } = currentOrder;

        return (
            <div block="RmaView" elem="InfoBlock">
                <h3>{__('Return data')}</h3>
                <h4>
                    <span>RMA:</span>
                    <span>{`#${increment_id}`}</span>
                    <span block="RmaView" elem="InfoBlockStatus" mods={{ type: status_id }}>
                        {currentStatus}
                    </span>
                </h4>
                <h4>
                    <span>{__('Order:')}</span>
                    <span block="RmaView" elem="InfoBlockOrders">
                        <div>
                            <span>{`#${order_increment_id}`}</span>
                            <span>{this.getNoTimeDate(order_created_at)}</span>
                            {grand_total && <span>{`${grand_total} ${formatCurrency(currency)}`}</span>}
                        </div>
                    </span>
                </h4>
                <h4>
                    <span>{__('Date requested:')}</span>
                    <span>{this.getNoTimeDate(created_at)}</span>
                </h4>
            </div>
        );
    }

    renderReturnAddressBlock() {
        const { customerAddresses } = this.props;
        const defaultShippingAddress = customerAddresses?.filter((address) => address.default_shipping === true);

        if (!defaultShippingAddress.length) {
            return null;
        }
        const { firstname, lastname, street, postcode, city, telephone } = defaultShippingAddress[0];

        return (
            <div block="RmaView" elem="ReturnAddress">
                <h3>{__('Address')}</h3>
                <p>{`${firstname} ${lastname}`}</p>
                <p>{street}</p>
                <p>{`${postcode} ${city}`}</p>
                <p>{telephone}</p>
            </div>
        );
    }

    renderRmaInfo() {
        return (
            <div block="RmaView" elem="RmaInfo">
                {this.renderRequestInfoBlock()}
                {this.renderReturnAddressBlock()}
            </div>
        );
    }

    renderRMARow(rmaItem, orderNr) {
        if (!rmaItem) {
            return null;
        }

        const { name, sku, qty_rma, reason, solution } = rmaItem;

        return (
            <tr key={sku} block="RmaView" elem="TableRow">
                <td block="RmaView" elem="ProductName">
                    <span>{name}</span>
                </td>
                <td>
                    <span>{sku}</span>
                </td>
                <td>
                    <span>{orderNr}</span>
                </td>
                <td>
                    <span>{qty_rma}</span>
                </td>
                <td>{reason && <span>{reason}</span>}</td>
                <td block="RmaView" elem="SolutionBadge">
                    {solution && <span>{solution}</span>}
                </td>
            </tr>
        );
    }

    renderRMAHeadingRow() {
        return (
            <tr>
                <th>{__('Product name')}</th>
                <th>{__('SKU')}</th>
                <th>{__('Order')}</th>
                <th>{__('Qty')}</th>
                <th>{__('Reason')}</th>
                <th>{__('Solution')}</th>
            </tr>
        );
    }

    renderRMARows() {
        const {
            currentRma: { request_item, order_increment_id },
        } = this.props;

        return request_item?.map((rmaItem) => this.renderRMARow(rmaItem, order_increment_id));
    }

    renderRmaItemsTable() {
        const {
            currentRma: { order_increment_id, request_item },
        } = this.props;

        if (!request_item) {
            return null;
        }

        return (
            <>
                <h4 block="RmaView" elem="TableHeading">
                    {order_increment_id && __(`RMA Items (order ${order_increment_id})`)}
                </h4>
                <table block="RmaView" elem="Table">
                    <thead>{this.renderRMAHeadingRow()}</thead>
                    <tbody>{this.renderRMARows()}</tbody>
                </table>
            </>
        );
    }

    renderRmaBack() {
        const {
            onRmaBackButtonClick,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <button
                block="Button"
                mods={{ secondary: true }}
                onClick={onRmaBackButtonClick}
                mix={{ block: 'RmaView', elem: 'BackButton' }}
            >
                {__('Go back')}
            </button>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div block="RmaView">
                <Loader isLoading={isLoading} />
                {this.renderRmaHeading()}
                {this.renderRmaInfo()}
                {this.renderRmaItemsTable()}
                {this.renderRmaBack()}
            </div>
        );
    }
}

export default RmaView;
