import PropTypes from 'prop-types';

import { addressesType } from 'SourceType/Account';

export * from 'SourceType/Account';

export const customerType = PropTypes.shape({
    addressesType,
    created_at: PropTypes.string,
    default_billing: PropTypes.string,
    default_shipping: PropTypes.string,
    dob: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    group_id: PropTypes.number,
    id: PropTypes.number,
    is_subscribed: PropTypes.bool,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    taxvat: PropTypes.string,
});

export const CREATE_RETURN = 'create-return';
export const VIEW_RETURN = 'return';
export const RETURNS = 'returns';
