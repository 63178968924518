import { RESET_PRODUCT_LIST } from '@advox/storefront/src/plugin/ProductList.plugin.js';
import Check from 'permissions';

import {
    CartDispatcher,
    CUSTOMER,
    MyAccountDispatcher as AdvoxBasicMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS,
    ProductCompareDispatcher,
} from 'AdvoxBasicStore/MyAccount/MyAccount.dispatcher';
import { CONTRACTOR_BLOCKED_POPUP } from 'Component/ContractorBlockedPopup/ContractorBlockedPopup.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';
import { B2B_THEMES } from 'Util/Theme';

export * from 'AdvoxBasicStore/MyAccount/MyAccount.dispatcher';

export const ContractorDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Contractor/Contractor.dispatcher'
);

/** @namespace AdvoxStrigob2b/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends AdvoxBasicMyAccountDispatcher {
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        try {
            const result = await fetchMutation(mutation);
            const {
                generateCustomerToken: { token },
            } = result;

            setAuthorizationToken(token);
        } catch (error) {
            if (Check.theme(B2B_THEMES)) {
                try {
                    const { email } = options;

                    const isContractorBlocked = await executePost(
                        prepareQuery(MyAccountQuery.getContractorBlocked(email))
                    );

                    const {
                        getBlockInfo: { is_blocked },
                    } = isContractorBlocked;

                    if (is_blocked) {
                        dispatch(showPopup(CONTRACTOR_BLOCKED_POPUP));

                        return false;
                    }
                } catch (error) {
                    dispatch(showNotification('error', getErrorMessage(error)));
                }
            }

            dispatch(showNotification('error', getErrorMessage(error)));

            return false;
        }

        dispatch({ type: RESET_PRODUCT_LIST });
        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    createAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getCreateContractorMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace AdvoxStrigob2b/Store/MyAccount/Dispatcher/fetchMutation/then */
            () => {
                dispatch(updateIsLoading(false));
                dispatch(
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('A new account has been created.')
                    )
                );
            },

            /** @namespace AdvoxStrigob2b/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                Promise.reject();

                return false;
            }
        );
    }

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace AdvoxStrigob2b/Store/MyAccount/Dispatcher/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);

                if (Check.theme(B2B_THEMES)) {
                    ContractorDispatcher.then(({ default: dispatcher }) => dispatcher.getContractor(dispatch));
                }
            },
            /** @namespace AdvoxStrigob2b/Store/MyAccount/Dispatcher/executePost/then */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }

    logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();
        } else {
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        if (!window.dataCache) {
            window.dataCache = {};
        } else {
            window.dataCache = {};
        }

        deleteGuestQuoteId();
        deleteAuthorizationToken();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        CartDispatcher.then(({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        });

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
        dispatch({ type: RESET_PRODUCT_LIST });
    }
}

export default new MyAccountDispatcher();
