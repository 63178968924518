import { connect } from 'react-redux';

import { LOGIN } from 'AdvoxBasicComponent/Header/Header.config';
import {
    mapDispatchToProps as advoxBasicMapDispatchToProps,
    mapStateToProps as advoxBasicMapStateToProps,
    ProductCardContainer as AdvoxBasicProductCardContainer,
} from 'AdvoxBasicComponent/ProductCard/ProductCard.container';
import { DEFAULT_MAX_PRODUCTS } from 'Component/ProductActions/ProductActions.config';
import { SHOPPING_LIST_POPUP_ID } from 'Component/ShoppingListPopup/ShoppingListPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { arePricesHidden } from 'Util/Price';
import { getBulkPackagingValue, roundQuantity } from 'Util/Product/Product';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AdvoxStrigob2b/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...advoxBasicMapDispatchToProps(dispatch),
    showOverlay: (id, payload) => dispatch(showPopup(id, payload)),
});

/** @namespace AdvoxStrigob2b/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...advoxBasicMapStateToProps(state),
    arePricesHidden: arePricesHidden(state),
});

/** @namespace AdvoxStrigob2b/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends AdvoxBasicProductCardContainer {
    state = {
        parameters: {},
        configurableVariantIndex: -1,
        quantity: 1,
        groupedProductQuantity: {},
        bulkPackaging: 1,
    };

    componentDidMount() {
        const { product = {} } = this.props;
        const bulkPackaging = getBulkPackagingValue(product);

        if (bulkPackaging > 1) {
            this.setQuantity(bulkPackaging);
            this.setState({ bulkPackaging });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        setQuantity: this.setQuantity.bind(this),
        openShoppingListModal: this.openShoppingListModal.bind(this),
        onBlurQuantity: this.onBlurQuantity.bind(this),
    };

    static getMinQuantity(props) {
        const { product: { stock_item: { min_sale_qty } = {}, variants } = {}, configurableVariantIndex } = props;

        if (!min_sale_qty) {
            return 1;
        }
        if ((!configurableVariantIndex && !variants) || configurableVariantIndex === -1) {
            return min_sale_qty;
        }

        const { stock_item: { min_sale_qty: minVariantQty } = {} } = variants[configurableVariantIndex] || {};

        return minVariantQty || min_sale_qty;
    }

    static getMaxQuantity(props) {
        const { product: { stock_item: { max_sale_qty } = {}, product_qty_in_sources, variants } = {}, configurableVariantIndex } = props;
        const qtyInSources = product_qty_in_sources?.[0]?.quantity || max_sale_qty;

        if (!max_sale_qty) {
            return DEFAULT_MAX_PRODUCTS >= qtyInSources ? qtyInSources : DEFAULT_MAX_PRODUCTS;
        }
        if (configurableVariantIndex === -1 || !Object.keys(variants).length) {
            return max_sale_qty >= qtyInSources ? qtyInSources : max_sale_qty;
        }

        const {
            stock_item: { max_sale_qty: maxVariantSaleQty } = {},
            product_qty_in_sources: variantProductQtyInSources
        } = variants[configurableVariantIndex] || {};
        const variantQtyInSources = variantProductQtyInSources?.[0]?.quantity || max_sale_qty;
        const maxVariantQty = maxVariantSaleQty || max_sale_qty;
    
        return maxVariantQty >= variantQtyInSources ? variantQtyInSources : maxVariantQty;
    }

    static getDerivedStateFromProps(props, state) {
        const { quantity } = state;
        const minQty = ProductCardContainer.getMinQuantity(props);
        const maxQty = ProductCardContainer.getMaxQuantity(props);

        if (quantity < minQty) {
            return { quantity: minQty };
        }
        if (quantity > maxQty) {
            return { quantity: maxQty };
        }

        return null;
    }

    setQuantity(value) {
        this.setState({ quantity: value });
    }

    containerProps() {
        const { quantity, bulkPackaging } = this.state;
        const { arePricesHidden } = this.props;

        return {
            ...super.containerProps(),
            quantity,
            minQuantity: ProductCardContainer.getMinQuantity(this.props),
            maxQuantity: ProductCardContainer.getMaxQuantity(this.props),
            arePricesHidden,
            bulkPackaging,
        };
    }

    openShoppingListModal(e) {
        e.preventDefault();

        const {
            product: { id: product_id },
            showOverlay,
        } = this.props;

        const { quantity } = this.state;

        if (!isSignedIn()) {
            return history.replace(appendWithStoreCode(`${LOGIN}`));
        }

        return showOverlay(SHOPPING_LIST_POPUP_ID, { title: __('Add to shopping list'), product_id, quantity });
    }

    onBlurQuantity({ target }) {
        const { value } = target;
        const { bulkPackaging } = this.state;

        const quantity = roundQuantity(value, bulkPackaging);

        this.setQuantity(parseInt(quantity, 10));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
