import { SearchItem as AdvoxBasicSearchItem } from 'AdvoxBasicComponent/SearchItem/SearchItem.component';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';

/** @namespace AdvoxStrigob2b/Component/SearchItem/Component/SearchItem */
export class SearchItem extends AdvoxBasicSearchItem {
    renderContent() {
        const {
            product: { name, price_range, price_tiers },
            arePricesHidden,
        } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                {this.renderCustomAttribute()}
                <h4 block="SearchItem" elem="Title" mods={{ isLoaded: !!name }}>
                    <TextPlaceholder content={name} length="long" />
                </h4>
                {!arePricesHidden && (
                    <ProductPrice
                        price={price_range}
                        price_tiers={price_tiers}
                        mix={{ block: 'SearchItem', elem: 'Price' }}
                    />
                )}
            </figcaption>
        );
    }
}

export default SearchItem;
