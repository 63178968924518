import getStore from '@scandipwa/scandipwa/src/util/Store';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Permissions/Component/Check/Component/Check */
export class Check extends PureComponent {
    static propTypes = {
        authorities: PropTypes.arrayOf(PropTypes.string),
        permissions: PropTypes.arrayOf(PropTypes.string),
        themes: PropTypes.arrayOf(PropTypes.string),
        fallback: PropTypes.node,
    };

    static defaultProps = {
        authorities: [],
        fallback: null,
    };

    static permissions(permissions, authorities = getStore().getState().MyAccountReducer?.authorities ?? []) {
        if (isEmpty(permissions) || indexOf(authorities, 'all') !== -1) {
            return true;
        }

        for (let i = 0; i < authorities.length; i += 1) {
            if (indexOf(permissions, authorities[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    static theme(themes, currentTheme = window.theme) {
        if (isEmpty(currentTheme) || isEmpty(themes)) {
            return true;
        }

        for (let i = 0; i < themes.length; i += 1) {
            if (isEqual(currentTheme, themes[i])) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { permissions, themes, fallback, children, authorities } = this.props;

        if (!Check.permissions(permissions, authorities) || !Check.theme(themes, window.theme)) {
            if (fallback) {
                return fallback;
            }

            return null;
        }

        return children;
    }
}

export default Check;
