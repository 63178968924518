import Check from 'permissions';
import { lazy, Suspense } from 'react';

import { MyAccount as AdvoxBasicMyAccount, MyAccountAddressBook } from 'AdvoxBasicRoute/MyAccount/MyAccount.component';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import MyAccountTabList from 'Component/MyAccountTabList';
import NoMatch from 'Route/NoMatch';
import {
    ASSIGNED_TO_ME_ORDER_REQUESTS,
    COMPANY_STRUCTURE,
    CONTRACTORS_LIST,
    CREATE_CONTRACTOR,
    CREATE_SHOPPING_LIST,
    EDIT_CONTRACTOR,
    EDIT_SHOPPING_LIST,
    NEWSLETTER_SUBSCRIPTION,
    PLACED_ORDER_REQUESTS,
    PURCHASING_GROUP,
    SHOPPING_LISTS,
    WHOLESALERS,
} from 'Type/Account';
import { isSignedIn } from 'Util/Auth';

import './MyAccount.override.style';

export { MyAccountAddressBook };

export const MyAccountCompanyStructure = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountCompanyStructure'
    )
);
export const MyAccountMyWholesalers = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountMyWholesalers'
    )
);

export const MyAccountShoppingLists = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountShoppingLists'
    )
);

export const MyAccountContractorsList = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountContractorsList'
    )
);

export const MyAccountCreateContractor = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountCreateContractor'
    )
);

export const MyAccountEditContractor = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountEditContractor'
    )
);

export const MyAccountNewsletterSubscription = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-newsletter" */
        'Component/MyAccountNewsletterSubscription'
    )
);

export const MyAccountOrderRequestsAssigned = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-order-requests-assigned" */
        'Component/MyAccountOrderRequestsAssigned'
    )
);
export const MyAccountOrderRequestsPlaced = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-order-requests-placed" */
        'Component/MyAccountOrderRequestsPlaced'
    )
);

export const MyAccountEditShoppingList = lazy(() => import('Component/MyAccountEditShoppingList'));

export const MyAccountCreateShoppingList = lazy(() => import('Component/MyAccountCreateShoppingList'));

export const MyAccountPurchasingGroup = lazy(() => import('Component/MyAccountPurchasingGroup'));

/** @namespace AdvoxStrigob2b/Route/MyAccount/Component/MyAccount */
export class MyAccount extends AdvoxBasicMyAccount {
    renderMap = {
        ...this.renderMap,
        [COMPANY_STRUCTURE]: MyAccountCompanyStructure,
        [SHOPPING_LISTS]: MyAccountShoppingLists,
        [CONTRACTORS_LIST]: MyAccountContractorsList,
        [NEWSLETTER_SUBSCRIPTION]: MyAccountNewsletterSubscription,
        [CREATE_CONTRACTOR]: MyAccountCreateContractor,
        [EDIT_CONTRACTOR]: MyAccountEditContractor,
        [WHOLESALERS]: MyAccountMyWholesalers,
        [PLACED_ORDER_REQUESTS]: MyAccountOrderRequestsPlaced,
        [ASSIGNED_TO_ME_ORDER_REQUESTS]: MyAccountOrderRequestsAssigned,
        [EDIT_SHOPPING_LIST]: MyAccountEditShoppingList,
        [CREATE_SHOPPING_LIST]: MyAccountCreateShoppingList,
        [PURCHASING_GROUP]: MyAccountPurchasingGroup,
    };

    renderContent() {
        const { tabMap, changeActiveTab, onSignOut, isEditingActive } = this.props;
        // eslint-disable-next-line fp/no-let
        let { activeTab } = this.props;
        const currentTab = activeTab;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        const TabContent = this.renderMap[activeTab];

        const { name = '', permissions = [], themes = [], label } = tabMap[activeTab];

        if (activeTab === CREATE_CONTRACTOR || activeTab === EDIT_CONTRACTOR) {
            activeTab = CONTRACTORS_LIST;
        }

        if (activeTab === EDIT_SHOPPING_LIST || activeTab === CREATE_SHOPPING_LIST) {
            activeTab = SHOPPING_LISTS;
        }

        return (
            <Check permissions={permissions} themes={themes} fallback={<NoMatch />}>
                <ContentWrapper label={__('My Account page')} wrapperMix={{ block: 'MyAccount', elem: 'Wrapper' }}>
                    <MyAccountTabList
                        tabMap={tabMap}
                        activeTab={activeTab}
                        changeActiveTab={changeActiveTab}
                        onSignOut={onSignOut}
                    />
                    <div block="MyAccount" elem="TabContent" mods={{ tab: currentTab }}>
                        <h2 block="MyAccount" elem="Heading">
                            {label ?? name}
                            {this.renderSubHeading()}
                        </h2>
                        <Suspense fallback={<Loader />}>
                            <TabContent isEditingActive={isEditingActive} />
                        </Suspense>
                    </div>
                </ContentWrapper>
            </Check>
        );
    }
}

export default MyAccount;
