import { ProductLabel as AdvoxStrigoB2BProductLabel } from 'AdvoxStrigoB2BComponent/ProductLabel/ProductLabel.component';

import './ProductLabel.mergo.style';

/** @namespace AdvoxMergob2b/Component/ProductLabel/Component/ProductLabel */
export class ProductLabel extends AdvoxStrigoB2BProductLabel {
    // TODO implement logic
}

export default ProductLabel;
