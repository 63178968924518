import { iconNamePropTypes as defaultIconNamePropTypes } from 'AdvoxBasicComponent/Icon/Icon.config';

export * from 'AdvoxBasicComponent/Icon/Icon.config';
export const strigoIconNamePropTypes = [
    'attention',
    'twitter',
    'youtube',
    'full_small_arrow_left',
    'full_small_arrow_right',
    'full_small_arrow_up',
    'full_small_arrow_down',
    'star_circle',
    'shopping_list',
    'active',
    'inactive',
    'suitcase',
    'edit',
    'reorder',
    'calendar',
    'attachment',
    'help',
    'bulb',
    'order_request',
    'download_secondary',
    'print',
    'trade_credit',
    'wishlist',
    'dashboard',
    'logout',
    'back',
    'wholesalers',
    'orders',
    'building',
    'plus',
    'envelop',
    'loyalty_program',
    'padlock',
];

export const iconNamePropTypes = defaultIconNamePropTypes.concat(strigoIconNamePropTypes);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ERROR */
export const ERROR = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <g id="ico_">
            <path
                id="Shape"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0006 2H13.0006V5H11.0006V2ZM4.00059 14V20H 3.00059V22H21.0006V20H20.0006V14C20.0006 9.58172 16.4189 6 12.0006 6C7.58232 6 4.00059 9.58172 4.00059 14ZM18.0006 14V20H6.00059V14C6.00059 10.6863 8.68688 8 12.0006 8C15.3143 8 18.0006 10.6863 18.0006 14ZM21.1926 6.222L19.7786 4.808L17.6576 6.929L19.0726 8.343L21.1926 6.222ZM2.80859 6.222L4.22259 4.808L6.34359 6.928L4.93059 8.344L2.80859 6.222ZM12.0006 9C9.23917 9 7.00059 11.2386 7.00059 14H9.00059C9.00059 12.3431 10.3437 11 12.0006 11V9Z"
                fill={fill}
            />
        </g>
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/INFO */
export const INFO = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <g id="ico_">
            <path
                id="Shape"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM13 7V9H11V7H13ZM13 17V11H11V17H13Z"
                fill={fill}
            />
        </g>
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/SUCCESS */
export const SUCCESS = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <g id="ico_">
            <path
                id="Shape"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM6.76 11.757L11.003 16L18.074 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757Z"
                fill={fill}
            />
        </g>
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ATTENTION */
export const ATTENTION = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM13 15V17H11V15H13ZM13 13V7H11V13H13Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/TWITTER */
export const TWITTER = ({ fill, ...rest }) => (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M21.1603 2.65607C20.3969 2.99376 19.5873 3.21554 18.7583 3.31407C19.6321 2.7915 20.286 1.96908 20.5983 1.00007C19.7783 1.48807 18.8793 1.83007 17.9423 2.01507C16.6482 0.630387 14.5846 0.289156 12.9136 1.18352C11.2426 2.07788 10.382 3.98421 10.8163 5.82907C7.45337 5.66051 4.32009 4.07206 2.19634 1.45907C1.82463 2.09752 1.6293 2.82329 1.63034 3.56207C1.63034 5.01207 2.36834 6.29307 3.49034 7.04307C2.82635 7.02216 2.17698 6.84285 1.59634 6.52007V6.57207C1.59676 8.5631 2.99986 10.2782 4.95134 10.6731C4.33496 10.8401 3.68865 10.8647 3.06134 10.7451C3.60847 12.4493 5.1788 13.6168 6.96834 13.6501C5.21629 15.0261 2.98908 15.6492 0.777344 15.3821C2.68904 16.6115 4.91444 17.2642 7.18734 17.2621C14.8803 17.2621 19.0873 10.8891 19.0873 5.36207C19.0873 5.18207 19.0823 5.00007 19.0743 4.82207C19.8932 4.23023 20.5999 3.49709 21.1613 2.65707L21.1603 2.65607Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/YOUTUBE */
export const YOUTUBE = ({ fill, ...rest }) => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 8C20 8 20 4.28 19.543 2.498C19.292 1.516 18.55 0.742 17.605 0.476C15.896 0 10 0 10 0C10 0 4.107 0 2.395 0.476C1.454 0.738 0.711 1.513 0.457 2.498C0 4.28 0 8 0 8C0 8 0 11.72 0.457 13.502C0.708 14.484 1.45 15.258 2.395 15.524C4.107 16 10 16 10 16C10 16 15.896 16 17.605 15.524C18.546 15.262 19.289 14.487 19.543 13.502C20 11.72 20 8 20 8ZM14 8L8 11.5V4.5L14 8Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FACEBOOK */
export const FACEBOOK = ({ fill, ...rest }) => (
    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M7 11.5H9.5L10.5 7.5H7V5.5C7 4.47 7 3.5 9 3.5H10.5V0.14C10.174 0.097 8.943 0 7.643 0C4.928 0 3 1.657 3 4.7V7.5H0V11.5H3V20H7V11.5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/INSTAGRAM */
export const INSTAGRAM = ({ fill, ...rest }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.122 0.06C13.056 0.01 12.717 0 10 0C7.283 0 6.944 0.013 5.878 0.06C4.812 0.11 4.088 0.277 3.45 0.525C2.78243 0.775729 2.17767 1.16923 1.678 1.678C1.1688 2.17732 0.775236 2.78218 0.525 3.45C0.277 4.088 0.11 4.812 0.06 5.878C0.01 6.944 0 7.283 0 10C0 12.717 0.013 13.056 0.06 14.122C0.11 15.187 0.277 15.913 0.525 16.55C0.775931 17.2175 1.16941 17.8222 1.678 18.322C2.17753 18.8309 2.78233 19.2245 3.45 19.475C4.088 19.722 4.813 19.89 5.878 19.94C6.944 19.99 7.283 20 10 20C12.717 20 13.056 19.987 14.122 19.94C15.187 19.89 15.913 19.722 16.55 19.475C17.2173 19.2238 17.822 18.8303 18.322 18.322C18.8311 17.8226 19.2247 17.2178 19.475 16.55C19.722 15.912 19.89 15.187 19.94 14.122C19.99 13.056 20 12.717 20 10C20 7.283 19.987 6.944 19.94 5.878C19.89 4.813 19.722 4.087 19.475 3.45C19.224 2.78259 18.8305 2.1779 18.322 1.678C17.766 1.123 17.21 0.779 16.55 0.525C15.912 0.277 15.187 0.11 14.122 0.06ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10ZM16.5 4.75C16.5 4.05964 15.9404 3.5 15.25 3.5C14.5596 3.5 14 4.05964 14 4.75C14 5.44036 14.5596 6 15.25 6C15.9404 6 16.5 5.44036 16.5 4.75ZM13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13C11.6569 13 13 11.6569 13 10Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/CART */
export const CART = ({ fill, ...rest }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.756836 2.17199L3.99984 5.41399V17C3.99984 17.5523 4.44755 18 4.99984 18H16.9998V16H5.99984V14H18.2558C18.6976 14 19.087 13.7102 19.2138 13.287L21.6138 5.28699C21.7045 4.98423 21.6468 4.65641 21.4581 4.40288C21.2694 4.14936 20.9719 3.99997 20.6558 3.99999H5.41384L2.17184 0.756989L0.756836 2.17199ZM5.99984 12V5.99999H19.3118L17.5118 12H5.99984ZM5.49984 22C4.67141 22 3.99984 21.3284 3.99984 20.5C3.99984 19.6716 4.67141 19 5.49984 19C6.32826 19 6.99984 19.6716 6.99984 20.5C6.99984 21.3284 6.32826 22 5.49984 22ZM15.9998 20.5C15.9998 21.3284 16.6714 22 17.4998 22C18.3283 22 18.9998 21.3284 18.9998 20.5C18.9998 19.6716 18.3283 19 17.4998 19C16.6714 19 15.9998 19.6716 15.9998 20.5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FULL_SMALL_ARROW_RIGHT */
export const FULL_SMALL_ARROW_RIGHT = ({ fill, ...rest }) => (
    <svg width="4" height="8" viewBox="0 0 4 8" fill={fill} {...rest}>
        <path transform="rotate(180 2 4)" d="M0 4L4 8V0L0 4Z" fill={fill} />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/DELIVERY */
export const DELIVERY = ({ fill, ...rest }) => (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" {...rest}>
        <path
            fill={fill}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.965 13.5C7.71923 15.2248 6.24225 16.5063 4.5 16.5063C2.75775 16.5063 1.28077 15.2248 1.035 13.5H0V1.5C0 0.947715 0.447715 0.5 1 0.5H15C15.5523 0.5 16 0.947715 16 1.5V3.5H19L22 7.556V13.5H19.965C19.7192 15.2248 18.2423 16.5063 16.5 16.5063C14.7577 16.5063 13.2808 15.2248 13.035 13.5H7.965ZM14 2.5H2V10.55C2.80082 9.73246 3.95073 9.35806 5.07938 9.54739C6.20802 9.73673 7.17281 10.4659 7.663 11.5H13.337C13.505 11.147 13.73 10.826 14 10.55V2.5ZM20 8.5H16V5.5H17.992L20 8.215V8.5ZM16.5 14.5C17.3287 14.5 18.0005 13.8282 18.0005 12.9995C18.0005 12.1708 17.3287 11.499 16.5 11.499C15.6713 11.499 14.9995 12.1708 14.9995 12.9995C14.9995 13.8282 15.6713 14.5 16.5 14.5ZM5.25 11.701C5.7141 11.9689 6 12.4641 6 13C6 13.8284 5.32843 14.5 4.5 14.5C3.67157 14.5 3 13.8284 3 13C3 12.4641 3.2859 11.9689 3.75 11.701C4.2141 11.433 4.7859 11.433 5.25 11.701Z"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/OK_HAND */
export const OK_HAND = ({ fill, ...rest }) => (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6 7.49995H20C21.1046 7.49995 22 8.39538 22 9.49995V11.604C22.0003 11.8653 21.9493 12.1242 21.85 12.366L18.755 19.881C18.6006 20.2557 18.2353 20.5001 17.83 20.5H1C0.447715 20.5 0 20.0522 0 19.5V9.49995C0 8.94767 0.447715 8.49995 1 8.49995H4.482C4.80688 8.50003 5.11155 8.34229 5.299 8.07695L10.752 0.349951C10.8944 0.148084 11.163 0.0805255 11.384 0.190951L13.198 1.09795C14.2498 1.62369 14.7931 2.81139 14.503 3.95095L13.6 7.49995ZM6 10.088V18.5H17.16L20 11.604V9.49995H13.6C12.9827 9.49986 12.4001 9.21476 12.0213 8.72744C11.6424 8.24011 11.5098 7.60516 11.662 7.00695L12.565 3.45895C12.6232 3.23091 12.5145 2.99313 12.304 2.88795L11.643 2.55795L6.933 9.22995C6.683 9.58395 6.363 9.87395 6 10.088ZM2 10.5H4V18.5H2V10.5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/RETURNS */
export const RETURNS = ({ fill, ...rest }) => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0.500019C7.59899 0.49667 5.27756 1.36068 3.463 2.93302L4.46 4.72802C6.77291 2.50782 10.1879 1.88289 13.1372 3.14014C16.0864 4.39739 18.0003 7.29395 18 10.5H15L18.19 16.24C19.33 14.616 20 12.636 20 10.5C20 4.97702 15.523 0.500019 10 0.500019ZM10 20.5C12.401 20.5034 14.7224 19.6394 16.537 18.067L15.54 16.272C13.2271 18.4922 9.81209 19.1171 6.86282 17.8599C3.91355 16.6026 1.99974 13.7061 2 10.5H5L1.81 4.76002C0.67 6.38402 0 8.36402 0 10.5C0 16.023 4.477 20.5 10 20.5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/STAR_CIRCLE */
export const STAR_CIRCLE = ({ fill, ...rest }) => (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 3.54902V0.499023L2 0.500023V3.50002L3.364 4.63702C4.49482 4.04408 5.72935 3.67467 7 3.54902ZM14 0.500023V3.50002L12.637 4.63802C11.5059 4.04461 10.2711 3.67486 9 3.54902V0.500023H14ZM8 5.50002C12.4183 5.50002 16 9.08175 16 13.5C16 17.9183 12.4183 21.5 8 21.5C3.58172 21.5 0 17.9183 0 13.5C0 9.08175 3.58172 5.50002 8 5.50002ZM8 7.50002C4.68629 7.50002 2 10.1863 2 13.5C2 16.8137 4.68629 19.5 8 19.5C11.3137 19.5 14 16.8137 14 13.5C14 10.1863 11.3137 7.50002 8 7.50002ZM9.323 11.68L8 9.00002L6.677 11.679L3.72 12.109L5.86 14.195L5.355 17.14L8 15.75L10.645 17.141L10.14 14.195L12.28 12.11L9.323 11.68Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/PRICE_TAG */
export const PRICE_TAG = ({ fill, ...rest }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4001 0.600098L20.2991 2.0151L21.7131 11.9151L12.5211 21.1071C12.1306 21.4975 11.4976 21.4975 11.1071 21.1071L1.20709 11.2071C0.816711 10.8166 0.816711 10.1836 1.20709 9.7931L10.4001 0.600098ZM11.1071 2.7221L3.32809 10.5001L11.8141 18.9851L19.5921 11.2071L18.5321 3.7821L11.1071 2.7221ZM12.7096 7.15342C12.5245 7.84383 12.7217 8.58057 13.2271 9.0861C13.7324 9.59163 14.4691 9.78916 15.1596 9.60427C15.8501 9.41939 16.3895 8.88019 16.5746 8.18977C16.7597 7.49936 16.5624 6.76263 16.0571 6.2571C15.5517 5.75157 14.8151 5.55404 14.1246 5.73892C13.4341 5.9238 12.8947 6.46301 12.7096 7.15342Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/MAIL */
export const MAIL = ({ fill = '#000', ...rest }) => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 0H19C19.5523 0 20 0.447715 20 1V17C20 17.5523 19.5523 18 19 18H1C0.447715 18 0 17.5523 0 17V1C0 0.447715 0.447715 0 1 0ZM18 4.238L10.072 11.338L2 4.216V16H18V4.238ZM10.061 8.662L2.511 2H17.502L10.061 8.662Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/DOWNLOAD */
export const DOWNLOAD = ({ fill, ...rest }) => (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.999 0.5C0.447 0.5 0 0.944 0 1.492V19.508C0.0043584 20.0544 0.446555 20.4962 0.993 20.5H17.007C17.2722 20.4981 17.5258 20.391 17.7121 20.2022C17.8983 20.0133 18.0019 19.7582 18 19.493V5.5L13 0.5H0.999ZM12 2.5H2V18.5H16V6.5H12V2.5ZM10 10.5H13L9 14.5L5 10.5H8V6.5H10V10.5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/COMPARE */
export const COMPARE = ({ fill, ...rest }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 3H5V0L0 4L5 8V5H19V11H21V4C21 3.44772 20.5523 3 20 3ZM2 19H17V22L22 18L17 14V17H3V11H1V18C1 18.5523 1.44772 19 2 19Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/HEART */
export const HEART = ({ fill, ...rest }) => (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.243 1.75698C15.98 -0.51002 12.35 -0.58002 10.001 1.52898C7.64501 -0.58302 4.02201 -0.50702 1.75701 1.75698C-0.503994 4.01898 -0.582994 7.63698 1.52101 9.99298L9.99901 18.485L18.479 9.99298C20.583 7.63698 20.505 4.02498 18.243 1.75698ZM11.337 3.017C12.907 1.607 15.327 1.668 16.827 3.17C18.319 4.665 18.394 7.047 17.02 8.624L9.99996 15.654L2.97996 8.623C1.60696 7.047 1.68196 4.662 3.17196 3.172C4.67596 1.668 7.09096 1.606 8.66596 3.018L10.002 4.215L11.337 3.017Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FULL_SMALL_ARROW_LEFT */
export const FULL_SMALL_ARROW_LEFT = ({ fill, ...rest }) => (
    <svg width="4" height="8" viewBox="0 0 4 8" fill={fill} {...rest}>
        <path d="M0 4L4 8V0L0 4Z" fill={fill} />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FULL_SMALL_ARROW_UP */
export const FULL_SMALL_ARROW_UP = ({ fill, ...rest }) => (
    <svg width="8" height="4" viewBox="0 0 8 4" fill={fill} {...rest}>
        <path d="M4 0L0 4L8 4L4 0Z" fill={fill} />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FULL_SMALL_ARROW_DOWN */
export const FULL_SMALL_ARROW_DOWN = ({ fill, ...rest }) => (
    <svg width="8" height="4" viewBox="0 0 8 4" fill={fill} {...rest}>
        <path d="M4 4L0 0L8 0L4 4Z" fill={fill} />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/FILTERS */
export const FILTERS = ({ fill, ...rest }) => (
    <svg width="20" height="22" viewBox="0 0 20 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.17 3.00001C4.5934 1.80005 5.72753 0.997559 7 0.997559C8.27247 0.997559 9.4066 1.80005 9.83 3.00001H20V5.00001H9.83C9.4066 6.19997 8.27247 7.00246 7 7.00246C5.72753 7.00246 4.5934 6.19997 4.17 5.00001H0V3.00001H4.17ZM13 7.99756C11.7275 7.99756 10.5934 8.80005 10.17 10H0V12H10.17C10.5934 13.2 11.7275 14.0025 13 14.0025C14.2725 14.0025 15.4066 13.2 15.83 12H20V10H15.83C15.4066 8.80005 14.2725 7.99756 13 7.99756ZM7 14.9976C5.72753 14.9976 4.5934 15.8 4.17 17H0V19H4.17C4.5934 20.2 5.72753 21.0025 7 21.0025C8.27247 21.0025 9.4066 20.2 9.83 19H20V17H9.83C9.4066 15.8 8.27247 14.9976 7 14.9976ZM8 4.00001C8 4.55229 7.55228 5.00001 7 5.00001C6.44772 5.00001 6 4.55229 6 4.00001C6 3.44772 6.44772 3.00001 7 3.00001C7.55228 3.00001 8 3.44772 8 4.00001ZM13 12C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12ZM8 18C8 18.5523 7.55228 19 7 19C6.44772 19 6 18.5523 6 18C6 17.4477 6.44772 17 7 17C7.55228 17 8 17.4477 8 18Z"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/TRADE_CREDIT */
export const TRADE_CREDIT = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 2C12.6569 2 14 3.34315 14 5C14 6.65685 12.6569 8 11 8C9.34315 8 8 6.65685 8 5C8 3.34315 9.34315 2 11 2ZM18 5C19.6569 5 21 6.34315 21 8C21 9.65685 19.6569 11 18 11C16.3431 11 15 9.65685 15 8C15 6.34315 16.3431 5 18 5ZM5 9C5.55228 9 6 9.44772 6 10C7.57114 9.99769 9.09698 10.5263 10.33 11.5H12.5C13.833 11.5 15.03 12.08 15.854 13H19C20.9293 12.9995 22.6868 14.109 23.516 15.851C21.151 18.972 17.322 21 13 21C10.21 21 7.85 20.397 5.94 19.342C5.79668 19.7375 5.4207 20.0007 5 20H2C1.44772 20 1 19.5523 1 19V10C1 9.44772 1.44772 9 2 9H5ZM6.001 12L6 17.022L6.045 17.054C7.84 18.314 10.178 19 13 19C16.004 19 18.799 17.844 20.835 15.87L20.968 15.737L20.848 15.637C20.3758 15.2672 19.8034 15.0477 19.205 15.007L19 15H16.889C16.961 15.322 17 15.656 17 16V17H8V15L14.79 14.999L14.756 14.921C14.3658 14.1056 13.566 13.5643 12.664 13.505L12.5 13.5H9.57C8.63124 12.5398 7.34482 11.999 6.002 12H6.001ZM3 11H4V18H3V11ZM17 8C17 7.44772 17.4477 7 18 7C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9C17.4477 9 17 8.55228 17 8ZM10 5C10 4.44772 10.4477 4 11 4C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/USER */
export const USER = ({ fill, ...rest }) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM16 7C16 9.21 14.21 11 12 11C9.79 11 8 9.21 8 7C8 4.79 9.79 3 12 3C14.21 3 16 4.79 16 7Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/WISHLIST */
export const WISHLIST = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.99982H9V9.99982H3V3.99982ZM21 3.99982H11V5.99982H21V3.99982ZM17 7.99982H11V9.99982H17V7.99982ZM11 13.9998H21V15.9998H11V13.9998ZM17 17.9998H11V19.9998H17V17.9998ZM5 7.99982V5.99982H7V7.99982H5ZM3 13.9998H9V19.9998H3V13.9998ZM5 17.9998V15.9998H7V17.9998H5Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/REORDER */
export const REORDER = ({ ...rest }) => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M13.185 3.05308C10.107 -0.0255864 5.14762 -0.0642531 2.01429 2.92308L0.594954 1.41775V6.70975H5.58429L4.20695 5.24908C6.08695 3.44508 9.07162 3.46508 10.9223 5.31575C13.8163 8.20975 12.0803 13.5097 7.26162 13.5097V16.7097C9.73362 16.7097 11.671 15.8804 13.185 14.3664C16.309 11.2424 16.309 6.17708 13.185 3.05308Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/CALENDAR */
export const CALENDAR = () => (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.81636 10.0352C9.1531 10.0352 9.42861 10.3164 9.42861 10.6602V14.4102C9.42861 14.7539 9.1531 15.0352 8.81636 15.0352H5.14289C4.80616 15.0352 4.53065 14.7539 4.53065 14.4102V10.6602C4.53065 10.3164 4.80616 10.0352 5.14289 10.0352H8.81636ZM5.75514 0.0351562C6.26407 0.0351562 6.6735 0.455078 6.6735 0.972656V2.53516H12.1837V0.972656C12.1837 0.455078 12.5931 0.0351562 13.1021 0.0351562C13.611 0.0351562 14.0204 0.455078 14.0204 0.972656V2.53516H15.5511C16.9018 2.53516 18 3.6543 18 5.03516V17.5352C18 18.9141 16.9018 20.0352 15.5511 20.0352H3.30616C1.95348 20.0352 0.857178 18.9141 0.857178 17.5352V5.03516C0.857178 3.6543 1.95348 2.53516 3.30616 2.53516H4.83677V0.972656C4.83677 0.455078 5.24621 0.0351562 5.75514 0.0351562ZM16.1633 7.53516H2.69391V17.5352C2.69391 17.8789 2.96789 18.1602 3.30616 18.1602H15.5511C15.8878 18.1602 16.1633 17.8789 16.1633 17.5352V7.53516Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ATTACHMENT */
export const ATTACHMENT = () => (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.68689 4.37673H8.58689V4.47673V13.0177C8.58689 14.931 7.129 16.4765 5.35056 16.4765C3.57211 16.4765 2.11422 14.931 2.11422 13.0177V4.47673C2.11422 3.34854 2.97357 2.44147 4.01602 2.44147C5.05847 2.44147 5.91782 3.34854 5.91782 4.47673V10.8825C5.91782 11.2256 5.65701 11.4942 5.35056 11.4942C5.0441 11.4942 4.78329 11.2256 4.78329 10.8825V5.18848V5.08848H4.68329H3.34875H3.24875V5.18848V10.8825C3.24875 12.1109 4.18368 13.1177 5.35056 13.1177C6.51743 13.1177 7.45236 12.1109 7.45236 10.8825V4.47673C7.45236 2.46253 5.91956 0.817969 4.01602 0.817969C2.11249 0.817969 0.579687 2.46253 0.579687 4.47673V13.0177C0.579687 15.8184 2.70969 18.1 5.35056 18.1C7.99142 18.1 10.1214 15.8184 10.1214 13.0177V4.47673V4.37673H10.0214H8.68689Z"
            fill="black"
            stroke="black"
            strokeWidth="0.2"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ENVELOP */
export const ENVELOP = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.1644 0.63916H2.16443C1.05974 0.63916 0.164429 1.53447 0.164429 2.63916V10.6392C0.164429 11.7438 1.05974 12.6392 2.16443 12.6392H14.1644C15.2691 12.6392 16.1644 11.7438 16.1644 10.6392V2.63916C16.1644 1.53447 15.2676 0.63916 14.1644 0.63916ZM2.16443 2.13916H14.1644C14.4401 2.13916 14.6644 2.36347 14.6644 2.63916V3.33166L9.45193 7.64729C8.72724 8.24979 7.59755 8.24947 6.87474 7.64777L1.66443 3.33291V2.63916C1.66443 2.36416 1.8888 2.13916 2.16443 2.13916ZM14.1644 11.1392H2.16443C1.88874 11.1392 1.66443 10.9148 1.66443 10.6392V5.28604L5.91755 8.82979C6.5488 9.35166 7.34568 9.63916 8.16443 9.63916C8.98318 9.63916 9.78162 9.35203 10.4135 8.82728L14.6644 5.28604V10.6392C14.6644 10.9142 14.4394 11.1392 14.1644 11.1392Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ACTIVE */
export const ACTIVE = ({ ...rest }) => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 20.8242C4.977 20.8242 0.5 16.3472 0.5 10.8242C0.5 5.30122 4.977 0.824219 10.5 0.824219C16.023 0.824219 20.5 5.30122 20.5 10.8242C20.5 16.3472 16.023 20.8242 10.5 20.8242ZM10.5 18.8242C14.9183 18.8242 18.5 15.2425 18.5 10.8242C18.5 6.40594 14.9183 2.82422 10.5 2.82422C6.08172 2.82422 2.5 6.40594 2.5 10.8242C2.5 15.2425 6.08172 18.8242 10.5 18.8242ZM5.26001 10.5812L9.50301 14.8242L16.574 7.75323L15.159 6.33923L9.50301 11.9962L6.67401 9.16723L5.26001 10.5812Z"
            fill="#45A715"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/INACTIVE */
export const INACTIVE = ({ ...rest }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M11.125 10.332C11.125 10.8516 10.707 11.2695 10.1875 11.2695C9.66797 11.2695 9.25 10.8516 9.25 10.332V0.957031C9.25 0.439453 9.66797 0.0195312 10.1875 0.0195312C10.707 0.0195312 11.125 0.439453 11.125 0.957031V10.332ZM2.375 10.2539C2.375 14.3945 5.69922 17.7539 9.875 17.7539C14.0156 17.7539 17.375 14.3945 17.375 10.2539C17.375 7.77734 16.1758 5.57812 14.3203 4.21094C13.9023 3.90469 13.8125 3.31758 14.1211 2.90078C14.4258 2.48398 15.0156 2.39531 15.4297 2.70234C17.7461 4.40625 19.25 7.15625 19.25 10.2539C19.25 15.4336 15.0508 19.6289 9.875 19.6289C4.69922 19.6289 0.5 15.4336 0.5 10.2539C0.5 7.15625 2.00469 4.40625 4.32031 2.70234C4.73438 2.39531 5.32422 2.48398 5.62891 2.90078C5.9375 3.31758 5.84766 3.90469 5.42969 4.21094C3.57578 5.57812 2.33984 7.77734 2.33984 10.2539H2.375Z"
            fill="#DD2D2D"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/LOGOUT */
export const LOGOUT = ({ ...rest }) => (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M6.16437 14.0474C6.16437 14.463 5.82999 14.7974 5.41437 14.7974H3.16437C1.50749 14.7974 0.164368 13.4542 0.164368 11.7974V3.79736C0.164368 2.14049 1.50749 0.797363 3.16437 0.797363H5.41437C5.82999 0.797363 6.16437 1.13299 6.16437 1.54736C6.16437 1.96143 5.82999 2.29736 5.41437 2.29736H3.16437C2.33937 2.29736 1.66437 2.97236 1.66437 3.79736V11.7974C1.66437 12.6224 2.33937 13.2974 3.16437 13.2974H5.41437C5.82999 13.2974 6.16437 13.6317 6.16437 14.0474ZM15.9612 7.28486L11.9894 3.03486C11.7052 2.73211 11.2306 2.71861 10.9297 3.00264C10.6279 3.28583 10.6134 3.76046 10.8975 4.06233L13.6769 7.04736H5.88624C5.49874 7.04736 5.16437 7.38486 5.16437 7.79736C5.16437 8.20986 5.50031 8.54736 5.88624 8.54736H13.6487L10.8387 11.5336C10.5546 11.8354 10.5692 12.3099 10.871 12.5933C11.0456 12.7286 11.23 12.7974 11.3862 12.7974C11.5855 12.7974 11.7847 12.7183 11.9322 12.5611L15.9041 8.31105C16.2331 8.02236 16.2331 7.57236 15.9612 7.28486Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/CONTRACTORS */
export const CONTRACTORS = ({ ...rest }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M2.66437 3.33643H4.66437V2.33643C4.66437 1.23174 5.55968 0.336426 6.66437 0.336426H10.6644C11.7675 0.336426 12.6644 1.23174 12.6644 2.33643V3.33643H14.6644C15.7691 3.33643 16.6644 4.2333 16.6644 5.33643V13.3364C16.6644 14.4411 15.7691 15.3364 14.6644 15.3364H2.66437C1.55968 15.3364 0.664368 14.4411 0.664368 13.3364V5.33643C0.664368 4.2333 1.56124 3.33643 2.66437 3.33643ZM11.1644 2.33643C11.1644 2.06024 10.9405 1.83643 10.6644 1.83643H6.66437C6.38824 1.83643 6.16437 2.06024 6.16437 2.33643V3.33643H11.1644V2.33643ZM2.16437 13.3364C2.16437 13.6114 2.38934 13.8364 2.66437 13.8364H14.6644C14.9394 13.8364 15.1644 13.6114 15.1644 13.3364V5.33643C15.1644 5.06139 14.9394 4.83643 14.6644 4.83643H2.66437C2.38934 4.83643 2.16437 5.06139 2.16437 5.33643V13.3364Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/BUILDING */
export const BUILDING = ({ ...rest }) => (
    <svg viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="14" height="19">
        <path
            d="M3.6406 3.99268C3.6406 3.68189 3.883 3.43018 4.18227 3.43018H5.80727C6.10519 3.43018 6.34894 3.68189 6.34894 3.99268V5.68018C6.34894 5.98955 6.10519 6.24268 5.80727 6.24268H4.18227C3.883 6.24268 3.6406 5.98955 3.6406 5.68018V3.99268ZM10.1406 3.43018C10.4385 3.43018 10.6823 3.68189 10.6823 3.99268V5.68018C10.6823 5.98955 10.4385 6.24268 10.1406 6.24268H8.5156C8.21769 6.24268 7.97394 5.98955 7.97394 5.68018V3.99268C7.97394 3.68189 8.21769 3.43018 8.5156 3.43018H10.1406ZM3.6406 8.49268C3.6406 8.1833 3.883 7.93018 4.18227 7.93018H5.80727C6.10519 7.93018 6.34894 8.1833 6.34894 8.49268V10.1802C6.34894 10.4896 6.10519 10.7427 5.80727 10.7427H4.18227C3.883 10.7427 3.6406 10.4896 3.6406 10.1802V8.49268ZM10.1406 7.93018C10.4385 7.93018 10.6823 8.1833 10.6823 8.49268V10.1802C10.6823 10.4896 10.4385 10.7427 10.1406 10.7427H8.5156C8.21769 10.7427 7.97394 10.4896 7.97394 10.1802V8.49268C7.97394 8.1833 8.21769 7.93018 8.5156 7.93018H10.1406ZM0.661438 2.58643C0.661438 1.34365 1.63136 0.336426 2.8281 0.336426H11.4948C12.6898 0.336426 13.6614 1.34365 13.6614 2.58643V16.0864C13.6614 17.3274 12.6898 18.3364 11.4948 18.3364H2.8281C1.63136 18.3364 0.661438 17.3274 0.661438 16.0864V2.58643ZM2.28644 2.58643V16.0864C2.28644 16.3958 2.52883 16.6489 2.8281 16.6489H5.53644V14.3989C5.53644 13.4673 6.2643 12.7114 7.16144 12.7114C8.05857 12.7114 8.78644 13.4673 8.78644 14.3989V16.6489H11.4948C11.7927 16.6489 12.0364 16.3958 12.0364 16.0864V2.58643C12.0364 2.27564 11.7927 2.02393 11.4948 2.02393H2.8281C2.52883 2.02393 2.28644 2.27564 2.28644 2.58643Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/BACK */
export const BACK = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.12718 0.892805C1.40746 0.778555 1.72968 0.841055 1.94468 1.0559L3.22906 2.34027C4.51249 1.0784 6.26374 0.311523 8.13843 0.311523C12.0269 0.33968 15.1644 3.47687 15.1644 7.33625C15.1644 11.1956 12.0269 14.3331 8.16749 14.3331C6.53281 14.3331 4.93937 13.7603 3.68312 12.7153C3.36468 12.4497 3.32187 11.9772 3.58743 11.6588C3.85402 11.3394 4.32556 11.2975 4.64399 11.5631C5.62999 12.3831 6.87999 12.8363 8.16437 12.8363C11.1966 12.8363 13.6644 10.3684 13.6644 7.33625C13.6644 4.30406 11.1956 1.83624 8.16437 1.83624C6.69312 1.83624 5.32281 2.43906 4.31437 3.42656L5.94374 5.05593C6.15859 5.27078 6.22205 5.59312 6.10684 5.87343C5.99249 6.155 5.71749 6.33625 5.41437 6.33625H1.41437C1.00031 6.33625 0.664368 6.00187 0.664368 5.58625V1.58624C0.664368 1.28249 0.846868 1.00906 1.12718 0.892805Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/DASHBOARD */
export const DASHBOARD = ({ ...rest }) => (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M14.1644 0.586426C15.2676 0.586426 16.1644 1.48174 16.1644 2.58643V12.5864C16.1644 13.6896 15.2676 14.5864 14.1644 14.5864H2.16443C1.05974 14.5864 0.164429 13.6896 0.164429 12.5864V2.58643C0.164429 1.48174 1.05974 0.586426 2.16443 0.586426H14.1644ZM14.1644 2.08643H2.16443C1.88818 2.08643 1.66443 2.31018 1.66443 2.58643V4.58643H14.6644V2.58643C14.6644 2.31018 14.4394 2.08643 14.1644 2.08643ZM2.16443 13.0864H4.66443V6.08643H1.66443V12.5864C1.66443 12.8614 1.88818 13.0864 2.16443 13.0864ZM6.16443 13.0864H14.1644C14.4394 13.0864 14.6644 12.8614 14.6644 12.5864V6.08643H6.16443V13.0864Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/EDIT */
export const EDIT = ({ ...rest }) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M11.8238 0.866511C12.7019 -0.0121444 14.1269 -0.0121444 15.0051 0.866511L15.3832 1.24526C16.2613 2.12401 16.2613 3.5487 15.3832 4.42682L9.22068 10.5924C8.9488 10.8612 8.61443 11.0581 8.24568 11.1643L5.12068 12.0581C4.85818 12.1331 4.57693 12.0581 4.38318 11.8393C4.19255 11.6737 4.11755 11.3924 4.19255 11.1299L5.0863 8.00494C5.19255 7.63619 5.38943 7.30182 5.65818 7.02994L11.8238 0.866511ZM13.9176 1.92714C13.6519 1.63432 13.1769 1.63432 12.8832 1.92714L11.9738 2.8362L13.4144 4.27682L14.3238 3.33932C14.6176 3.0737 14.6176 2.5987 14.3238 2.30588L13.9176 1.92714ZM6.53005 8.41744L6.00505 10.2456L7.83318 9.72056C7.95818 9.68619 8.06755 9.62057 8.15818 9.52994L12.3551 5.33619L10.9144 3.89557L6.72068 8.09244C6.63005 8.18307 6.56443 8.29244 6.53005 8.41744ZM6.41443 2.0862C6.83005 2.0862 7.16443 2.42213 7.16443 2.8362C7.16443 3.25182 6.83005 3.5862 6.41443 3.5862H2.91443C2.22412 3.5862 1.66443 4.14557 1.66443 4.83619V13.3362C1.66443 14.0268 2.22412 14.5862 2.91443 14.5862H11.4144C12.1051 14.5862 12.6644 14.0268 12.6644 13.3362V9.83619C12.6644 9.42057 12.9988 9.08619 13.4144 9.08619C13.8301 9.08619 14.1644 9.42057 14.1644 9.83619V13.3362C14.1644 14.8549 12.9332 16.0862 11.4144 16.0862H2.91443C1.39568 16.0862 0.164429 14.8549 0.164429 13.3362V4.83619C0.164429 3.31745 1.39568 2.0862 2.91443 2.0862H6.41443Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ORDERS */
export const ORDERS = ({ ...rest }) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M0.164368 15.7114C0.164368 16.1271 0.500305 16.4614 0.914368 16.4614C1.32843 16.4614 1.63624 16.1255 1.63624 15.7114V12.7152C1.26124 12.4177 0.179993 11.5333 0.164368 11.5208V15.7114ZM2.66468 3.46174C3.49281 3.46174 4.16499 2.78955 4.16499 1.96143C4.16499 1.1333 3.49249 0.461426 2.66468 0.461426C1.83687 0.461426 1.16437 1.13361 1.16437 1.96174C1.16437 2.78986 1.83656 3.46174 2.66468 3.46174ZM16.1362 12.5083C16.0249 12.1092 15.6128 11.8745 15.2116 11.9883L10.7397 13.2408C10.4203 12.8292 9.94718 12.5499 9.40343 12.4846L7.63624 6.01455C7.52999 5.61455 7.12374 5.37393 6.71749 5.48643C6.31749 5.5958 6.07999 6.0083 6.18937 6.4083L6.61187 7.95705H5.10812L4.13624 5.53518C3.86281 4.88268 3.22999 4.45705 2.51906 4.45705H1.94093C0.94968 4.4583 0.164368 5.24268 0.164368 6.2083V9.4083C0.164368 9.64658 0.258118 10.3302 0.82843 10.7833L3.21874 12.6702C3.39452 12.8108 3.51952 13.0061 3.57031 13.2289L4.18343 15.8774C4.2654 16.2367 4.63249 16.5414 5.08187 16.4399C5.48437 16.3461 5.73812 15.9436 5.64437 15.5414L4.97656 12.6352C4.88671 12.2483 4.66796 11.9008 4.35531 11.6546L3.16406 10.7171V7.17393L3.85937 8.8458C4.01437 9.2208 4.37374 9.46143 4.77999 9.46143H7.02187L7.95374 12.8771C7.47687 13.2427 7.13624 13.8146 7.13624 14.4614C7.13624 15.5661 8.03187 16.4614 9.10812 16.4614C10.1356 16.4614 10.9719 15.6839 11.085 14.6867L15.56 13.4336C16.0144 13.3208 16.2487 12.9083 16.1362 12.5083ZM9.13624 14.9614C8.86062 14.9614 8.66437 14.7371 8.66437 14.4614C8.66437 14.1857 8.88868 13.9614 9.13624 13.9614C9.41193 13.9614 9.63624 14.1857 9.63624 14.4614C9.63624 14.7371 9.43937 14.9614 9.13624 14.9614Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/SUITCASE */
export const SUITCASE = ({ ...rest }) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M2.66437 3.16016H4.66437V2.16016C4.66437 1.05547 5.55968 0.160156 6.66437 0.160156H10.6644C11.7675 0.160156 12.6644 1.05547 12.6644 2.16016V3.16016H14.6644C15.7691 3.16016 16.6644 4.05703 16.6644 5.16016V13.1602C16.6644 14.2648 15.7691 15.1602 14.6644 15.1602H2.66437C1.55968 15.1602 0.664368 14.2648 0.664368 13.1602V5.16016C0.664368 4.05703 1.56124 3.16016 2.66437 3.16016ZM11.1644 2.16016C11.1644 1.88397 10.9405 1.66016 10.6644 1.66016H6.66437C6.38824 1.66016 6.16437 1.88397 6.16437 2.16016V3.16016H11.1644V2.16016ZM2.16437 13.1602C2.16437 13.4351 2.38934 13.6602 2.66437 13.6602H14.6644C14.9394 13.6602 15.1644 13.4351 15.1644 13.1602V5.16016C15.1644 4.88513 14.9394 4.66016 14.6644 4.66016H2.66437C2.38934 4.66016 2.16437 4.88513 2.16437 5.16016V13.1602Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/PLUS */
export const PLUS = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.53436 6.82422V0.824219H8.53436V6.82422H14.5344V8.82422H8.53436V14.8242H6.53436V8.82422H0.534363V6.82422H6.53436Z"
            fill="white"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/WHOLESALERS */
export const WHOLESALERS = () => (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.66144 8.28907H15.6614C16.2114 8.28907 16.6614 7.83907 16.6614 7.3172V1.28906C16.6614 0.739063 16.2114 0.289062 15.6614 0.289062H12.6614V4.28907L10.6614 3.28907L8.66144 4.28907V0.289062L5.66144 0.317188C5.11144 0.317188 4.66144 0.767188 4.66144 1.31719V7.3172C4.66144 7.8672 5.11144 8.28907 5.66144 8.28907ZM20.1614 12.3172C20.4349 12.3172 20.6614 12.0906 20.6614 11.8172V10.8172C20.6614 10.5438 20.4349 10.3172 20.1614 10.3172H1.16144C0.888 10.3172 0.661438 10.5453 0.661438 10.8172V11.8172C0.661438 12.0922 0.888 12.3172 1.16144 12.3172H2.66144V14.3172H1.16144C0.888 14.3172 0.661438 14.5453 0.661438 14.8172V15.8172C0.661438 16.0922 0.888 16.3172 1.16144 16.3172H20.1614C20.4349 16.3172 20.6614 16.0906 20.6614 15.8172V14.8172C20.6614 14.5438 20.4349 14.3172 20.1614 14.3172H18.6614V12.3172H20.1614ZM9.66144 14.3172H4.66144V12.3172H9.66144V14.3172ZM16.6614 14.3172H11.6614V12.3172H16.6614V14.3172Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/SHOPPING_LIST */
export const SHOPPING_LIST = ({ ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <g id="ico_">
            <path
                id="Shape"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 4H9V10H3V4ZM21 4H11V6H21V4ZM17 8H11V10H17V8ZM11 14H21V16H11V14ZM17 18H11V20H17V18ZM5 8V6H7V8H5ZM3 14H9V20H3V14ZM5 18V16H7V18H5Z"
                fill="black"
            />
        </g>
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/HELP */
export const HELP = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM13 15V17H11V15H13ZM13 14V13.355C14.6436 12.8651 15.6904 11.2566 15.4726 9.55541C15.2549 7.8542 13.8368 6.56119 12.1228 6.50102C10.4087 6.44085 8.90349 7.63124 8.567 9.313L10.529 9.706C10.6839 8.93117 11.4133 8.40935 12.1966 8.51295C12.9799 8.61655 13.5486 9.31006 13.4967 10.0985C13.4448 10.887 12.7902 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13Z"
            fill={fill}
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/BULB */
export const BULB = ({ ...rest }) => (
    <svg width="57" height="61" viewBox="0 0 57 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.4996 0.636719H31.4996V9.63672H25.4996V0.636719ZM4.49844 36.6373V54.6373H1.49844V60.6373H55.4984V54.6373H52.4984V36.6373C52.4984 23.3825 41.7533 12.6373 28.4984 12.6373C15.2436 12.6373 4.49844 23.3825 4.49844 36.6373ZM46.4984 36.6367V54.6367H10.4984V36.6367C10.4984 26.6956 18.5573 18.6367 28.4984 18.6367C38.4396 18.6367 46.4984 26.6956 46.4984 36.6367ZM56.0734 13.3022L51.8314 9.06016L45.4684 15.4232L49.7134 19.6652L56.0734 13.3022ZM0.921875 13.3022L5.16388 9.06016L11.5269 15.4202L7.28788 19.6682L0.921875 13.3022ZM28.499 21.6373C20.2148 21.6373 13.499 28.353 13.499 36.6373H19.499C19.499 31.6667 23.5285 27.6373 28.499 27.6373V21.6373Z"
            fill="#FF5959"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/ORDER_REQUEST */
export const ORDER_REQUEST = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none" {...rest}>
        <path
            d="M13.1305 0.780777C13.8192 1.04005 14.1691 1.81028 13.9081 2.5017L13.1444 4.54645L8.63732 5.10895L8.46237 5.08388C8.59011 4.83605 8.63732 4.55759 8.63732 4.25964C8.63732 3.27583 7.86531 2.47775 6.86004 2.47775C5.90476 2.47775 5.08277 3.27583 5.08277 4.25964C5.08277 4.40165 5.12442 4.51304 5.15497 4.66897L3.33604 4.44064C3.33326 4.38216 3.30549 4.3209 3.30549 4.25964C3.30549 2.29146 4.9217 0.695676 6.86004 0.695676C8.81782 0.695676 10.3896 2.24273 10.4146 4.17053L11.4143 1.56301C11.6726 0.871778 12.439 0.521584 13.1305 0.780777ZM1.86506 5.1897L8.63732 6.01673L6.3463 9.88736C6.1408 10.2577 5.72703 10.4192 5.34103 10.3106L0.80536 9.0102C0.270233 8.85705 0.00583519 8.25279 0.254682 7.75434L1.41241 5.43196C1.49683 5.26488 1.67817 5.16742 1.86506 5.1897ZM15.7714 12.1262C15.7714 12.7388 15.3549 13.2735 14.7606 13.4238L9.09275 14.844C8.80949 14.9164 8.51513 14.9164 8.23188 14.844L2.56403 13.4238C1.96948 13.2735 1.52821 12.7388 1.52821 12.1262V10.1519L2.86117 10.5334V12.1262L7.97084 13.4071V9.35828C7.97084 9.01299 8.29297 8.68997 8.63732 8.68997C9.03165 8.68997 9.32879 9.01299 9.32879 9.35828V13.4071L14.4385 12.1262V10.5334L15.7714 10.1519V12.1262ZM16.5184 9.0102L11.9836 10.3106C11.5976 10.4192 11.1838 10.2577 10.9783 9.88736L8.63732 6.01673L15.4604 5.1897C15.6465 5.16742 15.827 5.26488 15.913 5.43196L17.0711 7.75434C17.3182 8.25279 17.0544 8.85705 16.5184 9.0102Z"
            fill="black"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/DOWNLOAD_SECONDARY */
export const DOWNLOAD_SECONDARY = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none" {...rest}>
        <path
            d="M16.939 18.6177H1.52758C0.919833 18.6177 0.426758 19.1112 0.426758 19.7194C0.426758 20.3653 0.919833 20.8606 1.52758 20.8606H16.939C17.5468 20.8606 18.0399 20.3671 18.0399 19.7589C18.0399 19.1498 17.5491 18.6177 16.939 18.6177ZM14.3017 8.37092L10.3341 12.5907V1.41742C10.3341 0.805545 9.84334 0.312012 9.23331 0.312012C8.62327 0.312012 8.13249 0.805545 8.13249 1.41375V12.5871L4.16358 8.37092C3.94708 8.137 3.65491 8.02233 3.36227 8.02233C3.09138 8.02233 2.82058 8.12131 2.60821 8.32212C2.16532 8.73809 2.14403 9.43532 2.56092 9.8784L8.43196 16.121C8.84761 16.5656 9.61901 16.5656 10.0346 16.121L15.9056 9.8784C16.3227 9.43518 16.3012 8.73814 15.8583 8.32212C15.4162 7.90307 14.7191 7.88473 14.3017 8.37092Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/PRINT */
export const PRINT = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none" {...rest}>
        <path
            d="M4.16437 1.54983H12.3688L13.4924 2.64569V5.16611H15.0423V2.64569C15.0423 2.23466 14.879 1.84043 14.5883 1.54983L13.4924 0.453972C13.2018 0.163281 12.8079 0 12.3979 0H4.16437C3.33746 0 2.64391 0.69355 2.64359 1.54919L2.64366 4.20586C2.64359 4.20586 2.64372 4.20586 2.64366 4.20586L2.64359 5.16611H4.19375L4.16437 1.54983ZM14.7839 6.19933H2.9019C1.62006 6.19933 0.577148 7.24224 0.577148 8.52408V12.1404C0.577148 12.5682 0.924246 12.9153 1.35206 12.9153H3.1602V15.4983C3.1602 16.0689 3.62289 16.5315 4.19342 16.5315H13.4924C14.063 16.5315 14.5256 16.0689 14.5256 15.4983V12.9153H16.3338C16.7616 12.9153 17.1087 12.5682 17.1087 12.1404V8.52408C17.1087 7.24224 16.0658 6.19933 14.7839 6.19933ZM12.9758 14.9817H4.71004V11.882H12.9758V14.9817ZM15.5589 11.3654H14.5256C14.5256 10.7949 14.063 10.3322 13.4924 10.3322H4.19342C3.62289 10.3322 3.1602 10.7949 3.1602 11.3654H2.12698V8.52408C2.12698 8.09691 2.47472 7.74916 2.9019 7.74916H14.7839C15.2111 7.74916 15.5589 8.09691 15.5589 8.52408V11.3654Z"
            fill="#004B93"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/LOYALTY_PROGRAM */
export const LOYALTY_PROGRAM = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" fill="none" {...rest}>
        <path
            d="M4.6 4.59982L6.4 2.79982M6.4 2.79982L4.6 0.999817M6.4 2.79982H4.6C2.61177 2.79982 1 4.41159 1 6.39982M15.4 15.3998L13.6 17.1998M13.6 17.1998L15.4 18.9998M13.6 17.1998H15.4C17.3882 17.1998 19 15.588 19 13.5998M8.37012 5.04982C8.96958 2.72076 11.0838 0.999817 13.6 0.999817C16.5823 0.999817 19 3.41748 19 6.39982C19 8.91598 17.2791 11.0302 14.9501 11.6297M11.8 13.5998C11.8 16.5822 9.38234 18.9998 6.4 18.9998C3.41766 18.9998 1 16.5822 1 13.5998C1 10.6175 3.41766 8.19982 6.4 8.19982C9.38234 8.19982 11.8 10.6175 11.8 13.5998Z"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

/** @namespace AdvoxStrigob2b/Component/Icon/Config/PADLOCK */
export const PADLOCK = ({ ...rest }) => (
    <svg width="54" height="73" viewBox="0 0 54 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M45 30.1695V18.1695C45 8.23053 36.939 0.169525 27 0.169525C17.061 0.169525 9 8.23053 9 18.1695V30.1695H0V72.1695H54V30.1695H45ZM30 53.3385V60.1695H24V53.3385C22.215 52.2975 21 50.3865 21 48.1695C21 44.8575 23.688 42.1695 27 42.1695C30.312 42.1695 33 44.8575 33 48.1695C33 50.3835 31.788 52.2975 30 53.3385ZM15 30.1695V18.1695C15 11.5515 20.382 6.16953 27 6.16953C33.615 6.16953 39 11.5515 39 18.1695V30.1695H15Z"
            fill="#FF5959"
        />
    </svg>
);
