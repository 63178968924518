import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import SwipeableTemporaryDrawer from 'Component/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer.component';

/** @namespace AdvoxBasic/Component/SwipeableTemporaryDrawer/Container/SwipeableTemporaryDrawerContainer */
export class SwipeableTemporaryDrawerContainer extends PureComponent {
    static propTypes = {
        ...this.propTypes,
        isOpen: PropTypes.bool,
        variant: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
        handleToggleDrawer: PropTypes.func,
        children: PropTypes.node.isRequired,
        title: PropTypes.string,
        swipeAreaWidth: PropTypes.number,
        name: PropTypes.string,
        titleIconName: PropTypes.string,
    };

    static defaultProps = {
        ...this.defaultProps,
        isOpen: false,
        variant: 'left',
        handleToggleDrawer: () => {},
        children: <p>{__('Paste the item into the drawer.')}</p>,
        swipeAreaWidth: 0,
        name: '',
        titleIconName: '',
    };

    render() {
        return <SwipeableTemporaryDrawer {...this.state} {...this.props} />;
    }
}

export default SwipeableTemporaryDrawerContainer;
