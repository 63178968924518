import { RmaList as AdvoxBasicRmaList } from 'AdvoxBasicComponent/RmaList/RmaList.component';

import './RmaList.override.style';

/** @namespace AdvoxStrigob2b/Component/RmaList/Component/RmaList */
export class RmaList extends AdvoxBasicRmaList {
    // TODO implement logic
}

export default RmaList;
