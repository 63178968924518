import { ProductConfigurableAttributeDropdown as AdvoxBasicProductConfigurableAttributeDropdown } from 'AdvoxBasicComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import './ProductConfigurableAttributeDropdown.extend.style';

/** @namespace AdvoxStrigob2b/Component/ProductConfigurableAttributeDropdown/Component/ProductConfigurableAttributeDropdown */
export class ProductConfigurableAttributeDropdown extends AdvoxBasicProductConfigurableAttributeDropdown {
    // TODO implement logic
}

export default ProductConfigurableAttributeDropdown;
