import { withRouter } from 'react-router-dom';

import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import SwipeableTemporaryDrawer from 'Component/SwipeableTemporaryDrawer';
import { MyAccountOverlay as SourceMyAccountOverlay } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';

import './MyAccountOverlay.override.style';

/** @namespace AdvoxBasic/Component/MyAccountOverlay/Component/MyAccountOverlay */
export class MyAccountOverlay extends SourceMyAccountOverlay {
    renderMyAccount() {
        const { state, isSwipeable } = this.props;
        const { render, title } = this.renderMap[state];

        if (isSwipeable) {
            return <>{render()}</>;
        }

        return (
            <div block="MyAccountOverlay" elem="Action" mods={{ state }}>
                <p block="MyAccountOverlay" elem="Heading">
                    {title}
                </p>
                {render()}
            </div>
        );
    }

    render() {
        const {
            isLoading,
            onVisible,
            isSwipeable,
            state,
            onMyAccountButtonClick,
            name,
            variant,
            isCheckout,
            isMobile,
            isOpen,
        } = this.props;
        const { title } = this.renderMap[state];

        if (isSwipeable) {
            return (
                <SwipeableTemporaryDrawer
                    isOpen={isOpen}
                    handleToggleDrawer={onMyAccountButtonClick}
                    title={title}
                    variant={variant}
                    name={name}
                    titleIconName="user"
                >
                    {this.renderMyAccount()}
                </SwipeableTemporaryDrawer>
            );
        }

        return (
            <Overlay
                id={CUSTOMER_ACCOUNT_OVERLAY_KEY}
                mix={{ block: 'MyAccountOverlay' }}
                onVisible={onVisible}
                isStatic={!isCheckout && isMobile}
            >
                <Loader isLoading={isLoading} />
                {this.renderMyAccount()}
            </Overlay>
        );
    }
}

export default withRouter(MyAccountOverlay);
