import { SwipeableTemporaryDrawerComponent as AdvoxBasicSwipeableTemporaryDrawerComponent } from 'AdvoxBasicComponent/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer.component';

import './SwipeableTemporaryDrawer.override.style';

/** @namespace AdvoxStrigob2b/Component/SwipeableTemporaryDrawer/Component/SwipeableTemporaryDrawerComponent */
export class SwipeableTemporaryDrawerComponent extends AdvoxBasicSwipeableTemporaryDrawerComponent {
    // TODO implement logic
}

export default SwipeableTemporaryDrawerComponent;
