import ExpandableContent from 'Component/ExpandableContent';
import { MyAccountTabList as SourceMyAccountTabList } from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';
import { CREATE_RETURN, VIEW_RETURN } from 'Type/Account';

import './MyAccountTabList.override.style';

/** @namespace AdvoxBasic/Component/MyAccountTabList/Component/MyAccountTabList */
export class MyAccountTabList extends SourceMyAccountTabList {
    removeNestedRoutesFromTabList(item) {
        if (item) {
            return item[0] !== VIEW_RETURN && item[0] !== CREATE_RETURN;
        }

        return null;
    }

    render() {
        const { tabMap, activeTab } = this.props;
        const { isContentExpanded } = this.state;
        const { name } = tabMap[activeTab];

        const tabs = [
            ...Object.entries(tabMap).filter(this.removeNestedRoutesFromTabList).map(this.renderTabListItem),
            this.renderLogoutTab(),
        ];

        return (
            <ExpandableContent
                heading={name}
                isContentExpanded={isContentExpanded}
                onClick={this.toggleExpandableContent}
                mix={{ block: 'MyAccountTabList' }}
                mods={{ isWithoutBorder: true }}
            >
                <ul>{tabs}</ul>
            </ExpandableContent>
        );
    }
}

export default MyAccountTabList;
