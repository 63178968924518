import { withRouter } from 'react-router-dom';

import { MyAccountOverlay as AdvoxBasicMyAccountOverlay } from 'AdvoxBasicComponent/MyAccountOverlay/MyAccountOverlay.component';
import MyAccountCreateContractorAccount from 'Component/MyAccountCreateContractorAccount';
import { STATE_CREATE_ACCOUNT } from 'Component/MyAccountOverlay/MyAccountOverlay.config';

/** @namespace AdvoxStrigob2b/Component/MyAccountOverlay/Component/MyAccountOverlay */
export class MyAccountOverlay extends AdvoxBasicMyAccountOverlay {
    renderMap = {
        ...this.renderMap,
        [STATE_CREATE_ACCOUNT]: {
            render: () => this.renderCreateContractorAccount(),
            title: __('Create new account'),
        },
    };

    renderMyAccount() {
        const { state } = this.props;
        const { render, title } = this.renderMap[state];

        return (
            <div block="MyAccountOverlay" elem="Action" mods={{ state }}>
                <p block="MyAccountOverlay" elem="Heading">
                    {title}
                </p>
                {render()}
            </div>
        );
    }

    renderCreateContractorAccount() {
        const { state, handleSignIn, setSignInState, setLoadingState, onSignIn } = this.props;

        return (
            <MyAccountCreateContractorAccount
                state={state}
                handleSignIn={handleSignIn}
                setLoadingState={setLoadingState}
                setSignInState={setSignInState}
                onSignIn={onSignIn}
            />
        );
    }
}

export default withRouter(MyAccountOverlay);
