import { IconContainer as AdvoxStrigoB2BIconContainer } from 'AdvoxStrigoB2BComponent/Icon/Icon.container';

/** @namespace AdvoxMergob2b/Component/Icon/Container/IconContainer */
export class IconContainer extends AdvoxStrigoB2BIconContainer {
    static defaultProps = {
        fill: '#1b2a50',
        width: '25',
        height: '25',
        cursor: 'pointer',
    };
}

export default IconContainer;
