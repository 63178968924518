import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Field from 'Component/Field';
import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';
import Image from 'Component/Image';
import Loader from 'Component/Loader';

import './RmaCreate.style';

/** @namespace AdvoxBasic/Component/RmaCreate/Component/RmaCreate */
export class RmaCreate extends FieldForm {
    static propTypes = {
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
    };

    state = {};

    onFormSuccess = this.onFormSuccess.bind(this);

    get fieldMap() {
        const { orderList = [], ordersToReturn, onOrderIdFieldChange } = this.props;

        // disable getting same order more than once
        const filteredOrderList = orderList.filter(
            (order) => !ordersToReturn.find((item) => item.base_order_info.id === order.base_order_info.id)
        );

        return {
            order_id: {
                placeholder: __('-- Select an Order --'),
                type: 'select',
                selectOptions: filteredOrderList.map(({ base_order_info: { id, increment_id } = {} }) => ({
                    id,
                    label: increment_id,
                    value: id,
                })),
                value: '',
                onChange: onOrderIdFieldChange,
            },
        };
    }

    onFormSuccess(fields) {
        const { onOrderPickFormSubmit } = this.props;
        onOrderPickFormSubmit(fields);
    }

    renderOrderInfo(order) {
        const { removeRmaDraft, customerEmail } = this.props;
        const {
            base_order_info: { id, increment_id } = {},
            shipping_info: { shipping_address: { firstname, lastname, street, postcode, telephone, city } } = {},
        } = order || {};

        return (
            <div block="RmaCreate" elem="OrderInfo">
                <div block="RmaCreate" elem="OrderInfoHeading">
                    <h3>{`#${increment_id}`}</h3>
                    <button onClick={() => removeRmaDraft(id)}>{__('Remove')}</button>
                </div>
                <div block="RmaCreate" elem="OrderInfoBase">
                    <h4>
                        <span>{__('Customer')}:</span>
                        <span>{`${firstname} ${lastname}`}</span>
                    </h4>
                    <h4>
                        <span>{__('Email address')}:</span>
                        <span>{customerEmail}</span>
                    </h4>
                </div>
                <div block="RmaCreate" elem="OrderInfoAddress">
                    <h4>{__('Order Shipping Address:')}</h4>
                    <p>{`${firstname} ${lastname}`}</p>
                    <p>{street}</p>
                    <p>{`${postcode} ${city}`}</p>
                    <p>
                        T: <a href={`tel:${telephone}`}>{telephone}</a>
                    </p>
                </div>
            </div>
        );
    }

    renderOrderItems(order) {
        const { order_products = {}, base_order_info } = order;

        if (!order_products) {
            return null;
        }

        const orderId = base_order_info.id;

        return (
            <div block="RmaCreate" elem="OrderItems">
                <h3>{__('Items in this order')}</h3>
                <div>{order_products.map((product) => this.renderOrderItem(orderId, product))}</div>
            </div>
        );
    }

    renderOrderItemReturnFields(orderId, item) {
        const { setReturnQuantity, rmaReasons, rmaSolutions, setRmaReason, setRmaSolution } = this.props;
        const { id, qty, qtyToReturn, maxQtyToReturn } = item;
        return (
            <div block="RmaCreate" elem="OrderItemReturnFields">
                <div>
                    <label htmlFor="return_qty">{__('Quantity to return')}</label>
                    <Field
                        id="return_qty"
                        name="return_qty"
                        type="number"
                        value={qtyToReturn}
                        max={maxQtyToReturn}
                        min={1}
                        onChange={(value) => setReturnQuantity(orderId, id, value)}
                    />
                    <span>/ {qty}</span>
                </div>
                <div>
                    <label htmlFor="return_reason">{__('Reason')}</label>
                    <Field
                        id="return_reason"
                        name="return_reason"
                        type="select"
                        placeholder={__('-- Select a Reason --')}
                        selectOptions={rmaReasons}
                        onChange={(value) => setRmaReason(orderId, id, value)}
                    />
                </div>
                <div>
                    <label htmlFor="return_solution">{__('Solution')}</label>
                    <Field
                        id="return_solution"
                        name="return_solution"
                        type="select"
                        placeholder={__('-- Select a solution --')}
                        selectOptions={rmaSolutions}
                        onChange={(value) => setRmaSolution(orderId, id, value)}
                    />
                </div>
            </div>
        );
    }

    renderOrderItem(orderId, item) {
        const { setItemToReturn } = this.props;
        const { id, isItemToReturn, isItemReturned } = item;

        return (
            <div
                key={`order_item_row_${orderId}_${id}`}
                block="RmaCreate"
                elem="OrderItem"
                mods={{ disabled: isItemReturned }}
            >
                <div>
                    <Field
                        id={`order_item_${orderId}_${[id]}`}
                        name={`order_item_${orderId}_${[id]}`}
                        type="checkbox"
                        mix={{ block: 'RmaCreate', elem: 'OrderItemField' }}
                        checked={isItemToReturn}
                        onClick={() => setItemToReturn(orderId, id, !isItemToReturn)}
                        disabled={isItemReturned}
                    />
                    <label block="RmaCreate" elem="OrderItemLabel" htmlFor={`order_item_${orderId}_${[id]}`}>
                        <Image src={item.thumbnail.url} alt={item.name} ratio="custom" />
                        <p block="RmaCreate" elem="OrderItemName">
                            {item.name}
                            {isItemReturned && (
                                <span block="RmaCreate" elem="OrderItemStatus">
                                    {__('The product has been returned')}
                                </span>
                            )}
                        </p>
                    </label>
                </div>
                {isItemToReturn && this.renderOrderItemReturnFields(orderId, item)}
            </div>
        );
    }

    renderActions() {
        const { isLoading, firstOrderChosen } = this.props;

        return (
            <>
                <Loader isLoading={isLoading} />
                <button
                    type="submit"
                    block="Button"
                    disabled={isLoading || !firstOrderChosen}
                    mods={{ secondary: true }}
                >
                    {isLoading ? __('Loading...') : __('Apply')}
                </button>
            </>
        );
    }

    renderRmaOrders() {
        const { ordersToReturn } = this.props;
        if (!ordersToReturn) {
            return null;
        }

        return ordersToReturn.map((order) => (
            <Fragment key={order?.base_order_info?.id}>
                {this.renderOrderInfo(order)}
                {this.renderOrderItems(order)}
            </Fragment>
        ));
    }

    renderRmaBack() {
        const {
            onRmaBackButtonClick,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <button
                block="Button"
                mods={{ secondary: true }}
                onClick={onRmaBackButtonClick}
                mix={{ block: 'RmaCreate', elem: 'BackButton' }}
            >
                {__('Go back')}
            </button>
        );
    }

    render() {
        const { rmaCreateHandler, ordersToReturn } = this.props;

        return (
            <div block="RmaCreate">
                <div block="RmaCreate" elem="FormContainer">
                    <div block="RmaCreate" elem="Info">
                        {__('Please, select an order')}
                    </div>
                    <Form
                        onSubmitSuccess={this.onFormSuccess}
                        mix={{ block: 'FieldForm' }}
                        ref={(ref) => {
                            this.form = ref;
                        }}
                    >
                        {this.renderFields()}
                        {this.renderActions()}
                    </Form>
                </div>
                {this.renderRmaOrders()}
                {ordersToReturn?.length !== 0 && (
                    <button
                        type="button"
                        block="Button"
                        mix={{ block: 'RmaCreate', elem: 'Button' }}
                        mods={{ isPrimary: true }}
                        onClick={() => rmaCreateHandler()}
                    >
                        {__('Submit a return')}
                    </button>
                )}
                {this.renderRmaBack()}
            </div>
        );
    }
}

export default RmaCreate;
