import { Field as AdvoxStrigoB2BField } from 'AdvoxStrigoB2BComponent/Field/Field.component';

import './Field.mergo.style';

/** @namespace AdvoxMergob2b/Component/Field/Component/Field */
export class Field extends AdvoxStrigoB2BField {
    // TODO implement logic
}

export default Field;
