export * from 'SourceType/Account';

export const CONTRACTORS_LIST = 'contractors';
export const COMPANY_STRUCTURE = 'company-structure';
export const PURCHASE_HISTORY = 'purchase-history';
export const SHOPPING_LISTS = 'shopping-lists';
export const CREATE_CONTRACTOR = 'create-contractor';
export const EDIT_CONTRACTOR = 'edit-contractor';
export const WHOLESALERS = 'my-wholesalers';
export const PLACED_ORDER_REQUESTS = 'placed-order-requests';
export const ASSIGNED_TO_ME_ORDER_REQUESTS = 'assigned-to-me-order-requests';
export const EDIT_SHOPPING_LIST = 'edit-shopping-list';
export const CREATE_SHOPPING_LIST = 'create-shopping-list';
export const PURCHASING_GROUP = 'purchasing-group';

export const permissions = {
    SHOW_STRUCTURE_ORDERS: ['show-structure-orders'],
    SHOW_USER_SHOPPING_LIST: ['show-user-shopping-lists'],
    SHOW_USER_SHOPPING_LIST_ITEMS: ['show-user-shopping-list-items'],
    SHOW_STRUCTURES: ['create-structure', 'edit-structure', 'delete-structure', 'show-substructures'],
    SHOW_USERS: ['create-user', 'edit-user', 'listing-users'],
};
