import PropTypes from 'prop-types';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace AdvoxBasic/Component/ExpandableContent/Component/ExpandableContent */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...super.propTypes,
        isExpandableOnDesktop: PropTypes.bool,
    };

    static defaultProps = {
        ...super.defaultProps,
        isExpandableOnDesktop: false,
    };

    static getDerivedStateFromProps(
        { isContentExpanded, isExpandableOnDesktop, device: { isMobile, isTablet } },
        { prevIsContentExpanded }
    ) {
        const isExpandedOnStart = (!isMobile && !isTablet && isExpandableOnDesktop) || isContentExpanded;
        if (isExpandedOnStart !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isExpandedOnStart,
                isContentExpanded: isExpandedOnStart,
            };
        }

        return null;
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const {
            isArrow,
            isExpandableOnDesktop,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet && !isExpandableOnDesktop) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={isContentExpanded ? TOP : BOTTOM} />;
        }

        return this.renderTogglePlusMinus();
    }

    renderContent() {
        const { children, mix, isExpandableOnDesktop } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded, isExpandableOnDesktop };

        return (
            <div
                block="ExpandableContent"
                elem="Content"
                mods={mods}
                mix={{ ...mix, elem: 'ExpandableContentContent', mods }}
            >
                {children}
            </div>
        );
    }
}

export default ExpandableContent;
